// Customizable Area Start
import React from "react";
import {
    Box,
    Typography,
    Button,
    TextField,
    Theme, DialogTitle,
    Dialog,
    DialogContent,
    IconButton,
    InputAdornment,
    FormControlLabel,
    Checkbox,
    Breadcrumbs,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { createTheme, withStyles, withTheme } from "@material-ui/core/styles";
import SettingsIcon from '@material-ui/icons/Settings';
import Header from "../../landingpage/src/Header.web";
import ProfileMainController, { Props } from "./ProfileMainController.web";
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { NavLink } from "react-router-dom";
import Dropzone from "react-dropzone";
import AvatarEditor from 'react-avatar-editor';
import Autocomplete from '@material-ui/lab/Autocomplete';
// Customizable Area End


// Customizable Area Start
export const theme = createTheme({});
// Customizable Area End

//Customizable Area Start

const StyledTextFeildLogin = styled(TextField)({
    '& input': {
        paddingTop: '6px',
        paddingBottom: '6px',
        height: '35px',
        width: '100%',
        backgroundColor: '#fef8f3',
    },
    '& .MuiFormLabel-root[data-shrink="false"]': {
        transform: 'translate(14px, 16px) scale(1)',
    },
    '& .MuiInputLabel-outLined.MuiInputLabel-shrink': {
        transform: "translate(12px, -6px) scale(0.70)"
    }

});

const StyledOutlinedButton = styled(Button)({
    "&.MuiButton-root": {
        borderRadius: "4px",
        border: "1px solid #292946",
        color: "#292946",
        textTransform: 'none',
        width: '180px',
        padding: '10px 0px'
    }
})

const StyledRemoveOutlinedButton = styled(Button)({
    "&.MuiButton-root": {
        borderRadius: "4px",
        border: "1px solid #ff0000cc",
        color: "#ff0000cc",
        textTransform: 'none',
        width: '180px',
        padding: '10px 0px'
    }
})

const StyledSettingButton = styled(Button)({
    "&.MuiButton-root": {
        borderRadius: "4px",
        color: "#fff",
        textTransform: 'none',
        width: '180px',
        padding: '10px 0px',
        background: 'rgb(5, 5, 43)'
    }
})

const GoBackToProfileButton = styled(Button)({
    "&.MuiButton-root": {
        borderRadius: "4px",
        border: "1px solid #292946",
        color: "#292946",
        width: '38%',
        textTransform: 'none'
    }
})

const CancelButton = styled(Button)({
    "&.MuiButton-root": {
        borderRadius: "4px",
        border: "1px solid #292946",
        color: "#292946",
        width: '150px',
        textTransform: 'capitalize'
    }
})


const SaveButton = styled(Button)({
    "&.MuiButton-root": {
        borderRadius: "4px",
        color: "#fff",
        background: "#292946",
        width: '150px',
        textTransform: 'capitalize'
    },
    "&.Mui-disabled": {
        background: "#2929467d",
    },
})

const AutocompleteStyle = styled(Autocomplete)({
    "& .MuiAutocomplete-inputRoot": {
        padding: '5px !important'
    }
})

export const EditDetailsDialog = (props: any) => {
    const { handleClose, handleOpen, personalDetails, handleChange, updateUserDetails, options } = props
    console.log(personalDetails,"personal details");
    return (
        <>
            <Dialog style={{ padding: '10px', width: '100%' }} data-testid="dialogOpenId" onClose={handleClose} open={handleOpen}>
                <DialogTitle style={{ color: 'black' }}>
                    Edit Personal Details
                </DialogTitle>
                <DialogContent>
                    <Box style={webStyle.personalDetailModalBox}>
                        <Box style={{flex: 1}}>
                            <Box sx={webStyle.labelBox}>
                                <label style={webStyle.labelNameStyle}>Name</label>
                                <StyledTextFeildLogin
                                    name="name"
                                    id="name"
                                    data-testid="nameInputId"
                                    value={personalDetails?.name}
                                    onChange={handleChange}
                                    variant="outlined"
                                    type="text"
                                />
                            </Box>
                            <Box sx={webStyle.labelBox}>
                                <label style={webStyle.labelNameStyle}>Location</label>
                                <AutocompleteStyle
                                    options={options || []}
                                    onChange={handleChange}
                                    defaultValue={personalDetails?.location}
                                    renderInput={(params: any) => (
                                        <MultiselectTextField {...params} name="location" placeholder="Select or Enter location" variant="outlined" />
                                    )}
                                />
                            </Box>
                        </Box>
                        <Box style={{flex: 1}}>
                            <Box sx={webStyle.labelBox}>
                                <label style={webStyle.labelNameStyle}>Company Name</label>
                                <StyledTextFeildLogin
                                    name="company_name"
                                    id="name"
                                    onChange={handleChange}
                                    value={personalDetails?.company_name}
                                    data-testid="nameInputId"
                                    variant="outlined"
                                    type="text"
                                />
                            </Box>
                            <Box sx={webStyle.labelBox}>
                                <label style={webStyle.labelNameStyle}>Job Title</label>
                                <StyledTextFeildLogin
                                    name="job_title"
                                    id="name"
                                    data-testid="nameInputId"
                                    onChange={handleChange}
                                    value={personalDetails?.job_title}
                                    variant="outlined"
                                    type="text"
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={webStyle.textButtonBoxStyle}>
                        <CancelButton variant='outlined' onClick={handleClose}>Cancel</CancelButton>
                        <SaveButton variant='contained' onClick={updateUserDetails}>Save</SaveButton>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export const ChangeEmailDialog = (props: any) => {
    const { handleClose, handleOpen, personalDetails, handleChange, updateUserEmail, isEmailValid } = props
    return (
        <>
            <Dialog style={{ padding: '10px' }} data-testid="dialogOpenId" onClose={handleClose} open={handleOpen}>
                <DialogTitle style={{ color: 'black' }}>
                    Change email
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <Box sx={webStyle.labelBox}>
                            <label style={webStyle.labelNameStyle}>New email</label>
                            <StyledTextFeildLogin
                                name="email"
                                id="name"
                                data-testid="nameInputId"
                                value={personalDetails?.email}
                                onChange={handleChange}
                                variant="outlined"
                                type="email"
                                error={!isEmailValid}
                                helperText={!isEmailValid ? 'Invalid email' : ''}
                            />
                        </Box>
                        <Typography style={{ color: 'gray', fontSize: '13px', fontWeight: 500 }}>Note: You will be sent a link to confirm your new email address.</Typography>
                    </Box>
                    <Box sx={webStyle.textButtonBoxStyle}>
                        <CancelButton variant='outlined' onClick={handleClose}>Cancel</CancelButton>
                        <SaveButton variant='contained' disabled={!isEmailValid} onClick={updateUserEmail}>Verify</SaveButton>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export const ChangeContactDialog = (props: any) => {
    const { handleClose, handleOpen, personalDetails, updateUserDetails, handleChange, contactErrMsg } = props

    return (
        <>
            <Dialog style={{ padding: '10px' }} data-testid="dialogOpenId" onClose={handleClose} open={handleOpen}>
                <DialogTitle style={{ color: 'black' }}>
                    Change contact number
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <Box sx={webStyle.labelBox}>
                            <label style={webStyle.labelNameStyle}>New contact number</label>
                            <StyledTextFeildLogin
                                name="phone_number"
                                id="name"
                                data-testid="nameInputId"
                                value={personalDetails?.phone_number}
                                onChange={handleChange}
                                variant="outlined"
                                type="tel"
                                helperText={contactErrMsg}
                                error={contactErrMsg?.length > 0}
                            />
                        </Box>
                    </Box>
                    <Box sx={webStyle.textButtonBoxStyle}>
                        <CancelButton variant='outlined' onClick={handleClose}>Cancel</CancelButton>
                        <SaveButton variant='contained' onClick={updateUserDetails}>Save</SaveButton>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export const ChangePasswordDialog = (props: any) => {
    const { handleClose, handleOpen, handleClickShowOldPassword, handleClickShowNewPassword, showOldPassword, showNewPassword, updateUserPassword, handleChange, personalDetails, passwordErrMsg, isPasswordValid } = props
    return (
        <>
            <Dialog style={{ padding: '10px' }} data-testid="dialogOpenId" onClose={handleClose} open={handleOpen}>
                <DialogTitle style={{ color: 'black' }}>
                    Change password
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <Box sx={webStyle.labelBox}>
                            <label style={webStyle.labelNameStyle}>Old Password</label>
                            <StyledTextFeildLogin
                                name="old_password"
                                id="name"
                                data-testid="nameInputId"
                                variant="outlined"
                                onChange={handleChange}
                                type={showOldPassword ? 'text' : 'password'}
                                value={personalDetails?.old_password}
                                error={passwordErrMsg?.length > 0}
                                helperText={passwordErrMsg}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowOldPassword}
                                                edge="end"
                                            >
                                                {showOldPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <Box sx={webStyle.labelBox}>
                            <label style={webStyle.labelNameStyle}>New Password</label>
                            <StyledTextFeildLogin
                                name="new_password"
                                id="name"
                                data-testid="nameInputId"
                                variant="outlined"
                                type={showNewPassword ? 'text' : 'password'}
                                value={personalDetails?.new_password}
                                onChange={handleChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowNewPassword}
                                                edge="end"
                                            >
                                                {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </Box>
                    <Box sx={webStyle.passwordBoxStyle}>
                        <FormControlLabel
                            control={
                                <Checkbox checked={isPasswordValid?.minimumEightCharacters} icon={<RadioButtonUncheckedIcon />} checkedIcon={<CheckCircleIcon style={{ color: '#008000a1' }} />} />
                            }
                            label={<Typography style={{ color: isPasswordValid?.minimumEightCharacters ? '#008000a1' : 'gray' }}>Minimum 8 characters</Typography>}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox checked={isPasswordValid?.oneUpperCase} icon={<RadioButtonUncheckedIcon />} checkedIcon={<CheckCircleIcon style={{ color: '#008000a1' }} />} />
                            }
                            label={<Typography style={{ color: isPasswordValid?.oneUpperCase ? '#008000a1' : 'gray' }}>1 Uppercase letter</Typography>}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox checked={isPasswordValid?.oneNumber} icon={<RadioButtonUncheckedIcon />} checkedIcon={<CheckCircleIcon style={{ color: '#008000a1' }} />} />
                            }
                            label={<Typography style={{ color: isPasswordValid?.oneNumber ? '#008000a1' : 'gray' }}>1 Number</Typography>}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox checked={isPasswordValid?.oneSpecialCharacter} icon={<RadioButtonUncheckedIcon />} checkedIcon={<CheckCircleIcon style={{ color: '#008000a1' }} />} />
                            }
                            label={<Typography style={{ color: isPasswordValid?.oneSpecialCharacter ? '#008000a1' : 'gray' }}>1 Special character</Typography>}
                        />
                    </Box>
                    <Box sx={webStyle.textButtonBoxStyle}>
                        <CancelButton variant='outlined' onClick={handleClose}>Cancel</CancelButton>
                        <SaveButton variant='contained' disabled={passwordErrMsg?.length > 0 || !isPasswordValid?.minimumEightCharacters || !isPasswordValid?.oneUpperCase || !isPasswordValid?.oneNumber || !isPasswordValid?.oneSpecialCharacter} onClick={updateUserPassword}>Save</SaveButton>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export const EmailSentDialog = (props: any) => {
    const { handleClose, handleOpen } = props

    return (
        <>
            <Dialog style={{ padding: '10px' }} data-testid="dialogOpenId" onClose={handleClose} open={handleOpen}>
                <DialogTitle style={{ color: 'black', width: '100%', textAlign: 'center' }}>
                    Verification sent to your new email.
                </DialogTitle>
                <DialogContent>
                    <Typography style={{ width: '100%', textAlign: 'center' }}>Please click the link in your email to verify your email address.</Typography>
                    <Box style={webStyle.verifyEmailBox}>
                        <GoBackToProfileButton variant='outlined' onClick={handleClose}>Go back to profile</GoBackToProfileButton>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}
const MultiselectTextField = styled(TextField)({
    '& input': {
        paddingRight: '50px !important'
    }
});
const BrowseButtonStyle = styled(Button)({
    "&.MuiButton-root": {
        borderRadius: "8px",
        border: "1px solid #df802d",
        color: "#df802d",
        textTransform: "uppercase",
        fontWeight: 500,
        fontSize: "16px",
        width: '100%',
        padding: '4px 8px'
    }
});

export const AvatarDialog = (props: any) => {
    const { handleClose, handleOpen, image, handleEditor, handleApplyImage } = props

    return (
        <>
            <Dialog fullWidth maxWidth="xs" style={{ padding: '10px' }} data-testid="dialogOpenId" onClose={handleClose} open={handleOpen}>
                <DialogTitle style={{ color: 'black' }}>
                    Change profile image
                </DialogTitle>
                <DialogContent>
                    <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '25px' }}>
                        {image && (
                            <div>
                                <AvatarEditor
                                    ref={handleEditor}
                                    image={image}
                                    width={200}
                                    height={200}
                                    border={50}
                                    borderRadius={150}
                                    // color={[255, 255, 255, 0.6]}
                                    color={[0, 0, 0, 0.72]}
                                    scale={1.2}
                                    style={webStyle.avatarStyle}
                                />
                            </div>
                        )}
                        <Box sx={webStyle.textButtonBoxStyle}>
                            <CancelButton variant='outlined' onClick={handleClose}>Cancel</CancelButton>
                            <SaveButton variant='contained' onClick={handleApplyImage}>Apply</SaveButton>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export const ProfileDialog = (props: any) => {
    const { handleClose, handleOpen, profileImageUrl, handleDrop, avatars, handleAvatarClick, selectedAvatarIndex, handleApplyAvatar } = props

    return (
        <>
            <Dialog fullWidth maxWidth="xs" style={{ padding: '10px' }} data-testid="dialogOpenId" onClose={handleClose} open={handleOpen}>
                <DialogTitle>
                    <Typography style={{ color: 'black', fontSize: '18px', fontWeight: 500 }}>Change profile image</Typography>
                </DialogTitle>
                <DialogContent>
                    <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '25px', flexDirection: 'column' }}>
                        <div style={webStyle.userBox}>
                            <img src={profileImageUrl} alt="" />
                        </div>
                        <Typography style={{ fontWeight: 500 }}>Select avatar</Typography>
                        <Box style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '5%' }}>
                            {avatars?.map((item: any, index: any) => {
                                return (
                                    <img key={index} style={selectedAvatarIndex === index ? webStyle.selectedAvatarStyle : webStyle.avatarImageStyle} src={item.image} alt="" onClick={() => handleAvatarClick(index)}
                                    />
                                )
                            })}
                        </Box>
                        <Box style={{ display: "flex", justifyContent: 'center', gap: '5%', width: '100%', marginBottom: '5%' }}>
                            <Dropzone
                                accept={{
                                    'image/*': ['.jpeg', '.png']
                                }}
                                onDrop={handleDrop}
                                data-testid="dropzone"
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()}>
                                        <input id="input" {...getInputProps()} />
                                        <BrowseButtonStyle>Browse Image</BrowseButtonStyle>
                                    </div>
                                )}
                            </Dropzone>
                            {selectedAvatarIndex !== "" && <SaveButton variant='contained' onClick={handleApplyAvatar}>Apply</SaveButton>}
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export const PasswordChangeDialog = (props: any) => {
    const { handleClose, handleOpen } = props

    return (
        <>
            <Dialog maxWidth="xs" style={{ padding: '10px' }} data-testid="dialogOpenId" onClose={handleClose} open={handleOpen}>
                <DialogTitle style={{ color: 'black', width: '100%', textAlign: 'center' }}>
                    Password Changed Successfully
                </DialogTitle>
                <DialogContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '14px', flexDirection: 'column', paddingBottom: '5%' }}>
                    <CheckCircleIcon style={{ color: '#008000a1', fontSize: 40, marginBottom:"5%" }} />
                    <NavLink style={webStyle.settingsNavLinkStyle} to="/DashboardWeb">
                                    <StyledSettingButton variant="contained">Go to Dashboard</StyledSettingButton>
                                </NavLink>
                </DialogContent>
            </Dialog>
        </>
    )
}

export const ProfileBreadCrumb = (props: any) => {
    const storedParamsString = localStorage.getItem('previousParams');
    const storedParams = JSON.parse(storedParamsString ?? '{}');
    return (<>
        <Box>
            <Breadcrumbs
                separator={">"}
                aria-label="breadcrumb"

            >
                <NavLink activeStyle={{ textDecoration: 'none' }} color="inherit" style={webStyle.breadcrumbLink} to={storedParams.param}>
                    {storedParams.param === '/projects' ? 'Project' : 'Dashboard'}
                </NavLink>

                <Typography style={webStyle.breadcrumbTextStyle}>Profile</Typography>
            </Breadcrumbs>
        </Box>
    </>)

}



//Customizable Area End
class ProfileMain extends ProfileMainController {
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { handleSaveData, navigation } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Header navigation={navigation} handleSaveData={handleSaveData} history={this.props.history} />
                <ProfileBreadCrumb />
                <PasswordChangeDialog handleOpen={this.state.updatePasswordPopup} handleClose={false}/>
                {this.state.profileDetails &&
                    <>
                        <AvatarDialog handleApplyImage={this.handleApplyImage} handleEditor={this.handleEditor} handleOpen={this.state.isAvatarModalOpen} handleClose={this.handleModalClose} image={this.state.image} />
                        <EditDetailsDialog options={this.state.options} updateUserDetails={this.updateUserDetails} handleChange={this.handleChange} jobTitle handleClose={this.handleModalClose} handleOpen={this.state.isEditModalOpen} personalDetails={this.state.personalDetails} />
                        <ChangeEmailDialog isEmailValid={this.state.isEmailValid} handleClose={this.handleModalClose} handleOpen={this.state.isChangeEmailModalOpen} handleChange={this.handleChange} personalDetails={this.state.personalDetails} updateUserEmail={this.updateUserEmail} />
                        <EmailSentDialog handleOpen={this.state.isEmailSentModalOpen} handleClose={this.handleModalClose} />
                        <ChangePasswordDialog handleChange={this.handleChange} handleClickShowOldPassword={this.handleClickShowOldPassword} handleClickShowNewPassword={this.handleClickShowNewPassword} showNewPassword={this.state.showNewPassword} showOldPassword={this.state.showOldPassword} handleClose={this.handleModalClose} updateUserPassword={this.updateUserPassword} handleOpen={this.state.isChangePasswordModalOpen} personalDetails={this.state.personalDetails} passwordErrMsg={this.state.passwordErrMsg} isPasswordValid={this.state.isPasswordValid} />
                        <ChangeContactDialog handleChange={this.handleChange} contactErrMsg={this.state.contactErrMsg} handleClose={this.handleModalClose} updateUserDetails={this.updateContactDetails} handleOpen={this.state.isChangeContactModalOpen} personalDetails={this.state.personalDetails} />
                        <ProfileDialog handleApplyAvatar={this.handleApplyAvatar} selectedAvatarIndex={this.state.selectedAvatarIndex} handleAvatarClick={this.handleAvatarClick} avatars={this.state.avatars} handleDrop={this.handleDrop} handleOpen={this.state.isProfileModalOpen} handleClose={this.handleModalClose} profileImageUrl={this.state.profileImageUrl} />
                        <div style={webStyle.wrapperWhite}>
                            <Box style={webStyle.accountBoxStyle}>
                                <Typography style={webStyle.fontStyle}>Profile Details</Typography>
                                <NavLink style={webStyle.settingsNavLinkStyle} to="/profile/settings">
                                    <StyledSettingButton variant="contained"><SettingsIcon style={{ marginRight: '2%' }} htmlColor="white" />Settings</StyledSettingButton>
                                </NavLink>
                            </Box>
                            <div style={webStyle.userBox}>
                                <img src={this.state.profileImageUrl} alt="" />
                            </div>
                            <Box style={webStyle.iconStyleButton}>
                                <StyledOutlinedButton variant='outlined' onClick={this.handleOpenProfileModal}>Change image</StyledOutlinedButton>
                                <StyledRemoveOutlinedButton variant='outlined' onClick={this.handelRemoveImage}>Remove image</StyledRemoveOutlinedButton>
                            </Box>
                            <Box style={webStyle.personalDetailsBox}>
                                <Box style={webStyle.headingStyle}>
                                    <Typography style={webStyle.fontStyle}>Personal Details</Typography>
                                    <StyledOutlinedButton variant='outlined' onClick={this.handleOpenEditModal}>Edit details</StyledOutlinedButton>
                                </Box>
                                <Box style={webStyle.personalDetailBox}>
                                    <Box>
                                        <Box sx={webStyle.labelBox}>
                                            <label style={webStyle.labelNameStyle}>Name</label>
                                            <StyledTextFeildLogin
                                                name="name"
                                                id="name"
                                                data-testid="nameInputId"
                                                value={this.state.profileDetails.name ? this.state.profileDetails.name : '-'}
                                                variant="outlined"
                                                type="text"
                                                disabled={true}
                                            />
                                        </Box>
                                        <Box sx={webStyle.labelBox}>
                                            <label style={webStyle.labelNameStyle}>Location</label>
                                            <StyledTextFeildLogin
                                                name="name"
                                                id="name"
                                                data-testid="nameInputId"
                                                value={this.state.profileDetails.location !== null ? this.state.profileDetails.location : '-'}
                                                variant="outlined"
                                                type="text"
                                                disabled={true}
                                            />
                                        </Box>
                                    </Box>
                                    <Box style={{ marginLeft: '30%' }}>
                                        <Box sx={webStyle.labelBox}>
                                            <label style={webStyle.labelNameStyle}>Company Name</label>
                                            <StyledTextFeildLogin
                                                name="name"
                                                id="name"
                                                data-testid="nameInputId"
                                                value={this.state.profileDetails.company_name ? this.state.profileDetails.company_name : '-'}
                                                variant="outlined"
                                                type="text"
                                                disabled={true}
                                            />
                                        </Box>
                                        <Box sx={webStyle.labelBox}>
                                            <label style={webStyle.labelNameStyle}>Job Title</label>
                                            <StyledTextFeildLogin
                                                name="name"
                                                id="name"
                                                data-testid="nameInputId"
                                                value={this.state.profileDetails.job_title ? this.state.profileDetails.job_title : '-'}
                                                variant="outlined"
                                                type="text"
                                                disabled={true}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box style={webStyle.personalDetailsBox}>
                                <Box style={webStyle.headingStyle}>
                                    <Typography style={webStyle.fontStyle}>Account Details</Typography>
                                </Box>
                                <Box style={webStyle.accountBoxStyle}>
                                    <Box>
                                        <Box sx={webStyle.labelBox}>
                                            <label style={webStyle.labelNameStyle}>Email</label>
                                            <Box style={webStyle.flexItemStyle}>
                                                <StyledTextFeildLogin
                                                    name="name"
                                                    id="name"
                                                    data-testid="nameInputId"
                                                    value={this.state.profileDetails.email ? this.state.profileDetails.email : '-'}
                                                    variant="outlined"
                                                    type="email"
                                                    disabled={true}
                                                />
                                                <StyledOutlinedButton variant='outlined' onClick={this.handleOpenEmailModal}>Change email</StyledOutlinedButton>
                                            </Box>
                                        </Box>
                                        <Box sx={webStyle.labelBox}>
                                            <label style={webStyle.labelNameStyle}>Password</label>
                                            <Box style={webStyle.flexItemStyle}>
                                                <StyledTextFeildLogin
                                                    name="name"
                                                    id="name"
                                                    data-testid="nameInputId"
                                                    value="password"
                                                    variant="outlined"
                                                    type="password"
                                                    disabled={true}
                                                />
                                                <StyledOutlinedButton variant='outlined' onClick={this.handleOpenChangePasswordModal}>Change password</StyledOutlinedButton>
                                            </Box>
                                        </Box>
                                        <Box sx={webStyle.labelBox}>
                                            <label style={webStyle.labelNameStyle}>Contact number</label>
                                            <Box style={webStyle.flexItemStyle}>
                                                <StyledTextFeildLogin
                                                    name="name"
                                                    id="name"
                                                    data-testid="nameInputId"
                                                    value={this.state.profileDetails.phone_number ? this.state.profileDetails.phone_number : "-"}
                                                    variant="outlined"
                                                    type="tel"
                                                    disabled={true}
                                                />
                                                <StyledOutlinedButton variant='outlined' onClick={this.handleOpenChangeContactModal}>Change number</StyledOutlinedButton>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </div>
                    </>
                }
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const webStyle = {
    fontStyle: {
        fontWeight: 500,
        fontSize: '18px',
        color: 'black'
    },
    avatarStyle: {
        border: '1px solid #ccc',
        // borderRadius: '50%',
        marginTop: '20px',
    },
    verifyEmailBox: {
        margin: '5% 0px',
        display: 'flex',
        justifyContent: 'center'
    },
    textButtonBoxStyle: {
        gap: '3%', width: '100%', display: 'flex', justifyContent: 'center', padding: '5%'
    },
    passwordBoxStyle: {
        display: 'flex',
        flexDirection: 'column'
    },
    breadcrumbLink: {
        textDecoration: "underline",
        fontWeight: 500,
        color: '#87888F',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            textDecoration: 'none'
        }
    },
    settingsNavLinkStyle: {
        textDecoration: "none",
        '&:focus, &:hover, &:visited, &:link, &:active': {
            textDecoration: 'none'
        }
    },
    breadcrumbTextStyle: {
        color: "orange",
        fontWeight: 500
    },
    userBox: {
        width: '150px',
        height: '150px',
        borderRadius: '100px',
        margin: 'auto',
        overflow: 'hidden',
        background: '#e3e3e3',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center'
    },
    selectedAvatarStyle: { border: '1px solid black', borderRadius: '50%', width: '50px', marginBottom: '4%', cursor: 'pointer' },
    avatarImageStyle: { width: '50px', marginBottom: '4%', cursor: 'pointer' },
    headingStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0px 3%',
        marginTop: '3%'
    },
    accountBoxStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '3%',
    },
    personalDetailBox: {
        display: 'flex',
        padding: '3%',
    },
    personalDetailModalBox: {
        display: 'flex',
        gap: '8%',
        padding: '3%',
        width: '100%'
    },
    wrapperWhite: {
        backgroundColor: 'white',
        borderRadius: '20px',
        boxShadow: '#d2d2d2 0px 0px 8px',
        paddingBottom: '3%',
        marginTop: '3%'
    },
    iconStyleButton: {
        display: 'flex',
        justifyContent: 'center',
        padding: '3% 0px',
        gap: '3%'
    },
    personalDetailsBox: {
        backgroundColor: 'white',
        
        borderRadius: '20px',
        margin: '3%',
        border: '1px solid #808080b3',
        boxShadow: '#d2d2d2 0px 0px 8px',
    },
    flexItemStyle: {
        display: 'flex',
        gap: '3%'
    },
    labelBox: {
        width: '100%',
        marginBottom: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    labelNameStyle: {
        marginBottom: 7,
        fontWeight: 500,
        width: '100%',
        color: 'black',
        fontSize: '16px',
    }
}

const styles = (theme: Theme) => ({
    logo: {
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        width: "100%",
        backgroundSize: "contain",
        borderRadious: "50%",
        [theme.breakpoints.up("xs")]: {
            height: "10rem"
        },
        [theme.breakpoints.up("sm")]: {
            height: "10rem"
        },
        [theme.breakpoints.up("md")]: {
            height: "14rem"
        },
        [theme.breakpoints.up("lg")]: {
            height: "25rem"
        }
    }
});

export { ProfileMain }
export const ProfileMainStyle = withTheme(withStyles(styles)(ProfileMain));



// Customizable Area End
