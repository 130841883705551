// Customizable Area Start
import React from "react";

import {
    Grid, Box, Typography, CircularProgress,
    Breadcrumbs, Link, Card, CardContent,
    FormControlLabel,
    Switch, Tabs, Tab, Button,
    TextField, Paper,Checkbox
} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import { withStyles } from "@material-ui/core/styles";
import { Visibility } from '@material-ui/icons';

import {
    no_data, resize,upload_image,download_invoice, arrowIcon
} from "./assets";
import Header from "../../landingpage/src/Header.web";
import { NavLink } from "react-router-dom";
import ViewReportDetailsController, { configJSON, QuotePDFDocumentProps, InvoicePDFDocumentProps, TextItem,HighlightData,DataItem } from "./ViewReportDetailsController.web";
import { ProjectsTableStyle } from './Projects.web';
import StopRoundedIcon from '@material-ui/icons/StopRounded';
import { ChartComponent } from './ViewReport.web';
import { Document, Page } from 'react-pdf';
import { styled } from "@material-ui/styles";

// Customizable Area End


// Customizable Area Start

interface HighlightPart {
    text: string;
    color?: string;
}

export const ViewReportDetailsBreadCrumb = (props: any) => {
    const { classes, project, report, goBack } = props
    return (<>
        <Box className={classes.breadcrumbStyle}>
            <Breadcrumbs separator={">"} aria-label="breadcrumb">
                <Link color="inherit" className={classes.breadcrumbLinkStyle} style={webStyle.reportUnderStyle} href="/Projects">
                    {configJSON.projectsTitle}
                </Link>

                <UploadReportContainer color="inherit"   className={classes.breadcrumbLinkStyle} style={webStyle.reportUnderStyle} onClick={props.getNavigationViewreport}>{configJSON.reportOf}'{project}'</UploadReportContainer>

                <Typography className={classes.breadcrumbText} style={webStyle.breadcrumbStyles}>{report}</Typography>
            </Breadcrumbs>
        </Box>
    </>)

}

export const InvoiceQuoteAmount = (props: any) => {
    return (
        <Box>
            <Box style={webStyle.cardMainBoxStyle}>

                <Card style={webStyle.cardStyle}>
                    <CardContent>
                        <Box style={webStyle.contentStyle}>
                            <StopRoundedIcon />
                            <Box>
                                <Typography style={webStyle.cardTextStyle}>
                                    {configJSON.totalQuoteAmount}
                                </Typography>
                                <Typography style={webStyle.cardTextStyle}>
                                    $ {props.totalQuoteAmount}
                                </Typography>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
                <Card style={webStyle.cardStyle}>
                    <CardContent>
                        <Box style={webStyle.contentStyle}>
                            <StopRoundedIcon
                                style={{ color: 'gray' }} />
                            <Box>
                                <Typography style={webStyle.cardTextStyle}>
                                    {configJSON.totalInvoiceAmount}
                                </Typography>
                                <Typography style={webStyle.cardTextStyle}>
                                    $ {props.totalInvoiceAmount}
                                </Typography>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </Box>)
};

export const pdfProps = (tabIndex: any) => {
    return {
        id: `simple-tab-${tabIndex}`,
        'aria-controls': `simple-tabpanel-${tabIndex}`,
    };
}

export const CustomButton = (props: any) => {
    const { title, icon, variant, bgColor, marginTop, height, width, marginRight, marginLeft, borderColor, textColor } = props;

    return (
        <Button
            variant={variant}
            style={{
                backgroundColor: bgColor || 'rgb(5, 5, 43)',
                color: textColor || '#fff',
                paddingLeft: '10px', 
                fontSize: '13px', 
                textTransform: 'none', 
                marginTop: marginTop || 20,
                height: height || "auto",
                width: width || "auto",
                marginRight,
                borderColor,
                marginLeft

            }}
            startIcon={icon} // Eye icon on the left side
            onClick={props.onClick}
        >
            {title}
        </Button>
    );
};


export const QuoteFormView = (props: any) => {
    const { classes, quoteData, quotePage, handleChangePage, handleResize, fullSize,highlightData ,calculateXSValue} = props
    const { quotedetails, quoteTableData } = quoteData
    const quoteRowsPerPage = 10;
    

    const newDataKey = Array.isArray(quoteTableData) ? [{name:'',data:quoteTableData}] : Object.entries(quoteTableData).map(([newKey, value]) =>{
        return {name:newKey,data:value};
    });

    const combinedData = newDataKey?.reduce<DataItem[]>((accValue, current) => {
        return accValue.concat(current?.data as DataItem[]);
    }, []);

  
    const zoomstyle = {
        display: `${fullSize}`,
        overflow: 'none',
        border: "1px solid gray",
        borderRadius: "4px"
    }


    return (
        <>
            <Box style={zoomstyle}>
                <Box style={webStyle.quoteInvoiceFormBox}>
                    <Typography variant="subtitle2" style={webStyle.viewquoteTitle}>View quote</Typography>
                    <Box style={webStyle.quoteInvoiceDeatilBox}>
                        <Grid container spacing={2}>

                            <Grid item sm={4}>
                                <Box>
                                    <Typography variant="body2" style={webStyle.quoteInvoiceDetailsContent}>{configJSON.quotedetailsContent}</Typography>
                                    <Typography style={webStyle.quoteInvoiceDetailTitle}>{configJSON.quoteNumberContent}</Typography>
                                    <TextField variant="outlined" style={webStyle.reportTextBox}
                                        value={quotedetails.quote_id} InputProps={{
                                            readOnly: true,
                                            style: webStyle.textInputFontSize,
                                            classes: {
                                                input: classes.customInput, 
                                            },
                                        }} disabled
                                        size="small"
                                    />
                                    <Typography style={webStyle.quoteInvoiceDetailTitle}>{configJSON.quoteDateContent}</Typography>
                                    <TextField variant="outlined" style={webStyle.reportTextBox} size="small"
                                        value={quotedetails.date ? quotedetails.date : "Unknown"} InputProps={{ style: webStyle.textInputFontSize, readOnly: true, endAdornment: (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" id="calendar"><g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><g stroke="gray" stroke-width="2" transform="translate(-919 -1443)"><g transform="translate(920 1444)"><rect width="18" height="18" y="2" rx="2"></rect><path d="M13 0v4M5 0v4M0 8h18"></path></g></g></g></svg>) }} disabled />
                                    <Typography style={webStyle.quoteInvoiceDetailTitle}>{configJSON.expiryquoteTitle}</Typography>
                                    <TextField variant="outlined" style={webStyle.reportTextBox}
                                        size="small" value={quotedetails.expiration_date} InputProps={{ style: webStyle.textInputFontSize, readOnly: true, endAdornment: (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" id="calendar"><g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><g stroke="gray" stroke-width="2" transform="translate(-919 -1443)"><g transform="translate(920 1444)"><rect width="18" height="18" y="2" rx="2"></rect><path d="M13 0v4M5 0v4M0 8h18"></path></g></g></g></svg>) }} disabled />
                                </Box>
                            </Grid>
                            <Grid item sm={4}>
                                <Box>
                                    <Typography variant="h6" style={webStyle.quoteInvoiceDetailsContent}>{configJSON.supplierdetailsContent}</Typography>
                                    <Typography style={webStyle.quoteInvoiceDetailTitle}>{configJSON.supplierNameContent}</Typography>
                                    <TextField variant="outlined" style={webStyle.reportTextBox}
                                        value={quotedetails.vendor} InputProps={{ style: webStyle.textInputFontSize, readOnly: true }} size="small" disabled />
                                    <Typography style={webStyle.quoteInvoiceDetailTitle}>{configJSON.supplierAddressContent}</Typography>
                                    <TextField variant="outlined" style={webStyle.reportTextBox} size="small"
                                        value={quotedetails.supplier_address} InputProps={{ style: webStyle.textInputFontSize, readOnly: true }} disabled />
                                    <Typography style={webStyle.quoteInvoiceDetailTitle}>{configJSON.salesPersonTitle}</Typography>
                                    <TextField variant="outlined" style={webStyle.reportTextBox} size="small"
                                        value={quotedetails.supplier_salesperson} InputProps={{ style: webStyle.textInputFontSize, readOnly: true }} disabled />
                                    <Typography style={webStyle.quoteInvoiceDetailTitle}>{configJSON.supplierContactContent}</Typography>
                                    <TextField variant="outlined" style={webStyle.reportTextBox} size="small"
                                        value={quotedetails.supplier_contact} InputProps={{ style: webStyle.textInputFontSize, readOnly: true }} disabled />
                                </Box>
                            </Grid>
                            <Grid item sm={4}>
                                <Box>
                                    <Typography variant="h6" style={webStyle.quoteInvoiceDetailsContent}>{configJSON.projectdetailsContent}</Typography>
                                    <Typography style={webStyle.quoteInvoiceDetailTitle}>{configJSON.siteNameContent}</Typography>
                                    <TextField variant="outlined" style={webStyle.reportTextBox}
                                        size="small" value={quotedetails.site_name} InputProps={{ style: webStyle.textInputFontSize, readOnly: true }} disabled />
                                    <Typography style={webStyle.quoteInvoiceDetailTitle}>{configJSON.siteAddressContent}</Typography>
                                    <TextField variant="outlined" style={webStyle.reportTextBox}
                                        value={quotedetails.site_address} size="small" InputProps={{ style: webStyle.textInputFontSize, readOnly: true }} disabled />
                                    <Typography style={webStyle.quoteInvoiceDetailTitle}>{configJSON.contactPersonTitle}</Typography>
                                    <TextField variant="outlined" size="small" style={webStyle.reportTextBox}
                                        value={quotedetails.site_contactperson} InputProps={{ style: webStyle.textInputFontSize, readOnly: true }} disabled />
                                    <Typography style={webStyle.quoteInvoiceDetailTitle}>{configJSON.siteContactContent}</Typography>
                                    <TextField variant="outlined" style={webStyle.reportTextBox} size="small"
                                        value={quotedetails.site_contact} InputProps={{ style: webStyle.textInputFontSize, readOnly: true }} disabled />

                                </Box>
                            </Grid>

                        </Grid>
                        <Typography variant="body2" style={webStyle.quoteInvoiceDetailsContent}>{configJSON.quotedetailsContent}</Typography>

                    {
                    newDataKey?.map((value:any,columnId:number)=>{
                        const begin = ((quotePage[columnId] || 1) - 1) * quoteRowsPerPage;
                        const endIndex = begin + quoteRowsPerPage;
                        const paginatedData = value?.data.slice(begin, endIndex);
                    return <>
                     {value?.name && (
                        <Box style={webStyle.quoteSegmentCategoryMainBox}>
                        <Typography style={webStyle.quoteSegmentLabel}>
                            Segment/Category
                        </Typography>
                        <TextField variant="outlined" style={{ width: "200px", textOverflow: "ellipsis" }}
                         value={value?.name} InputProps={{ readOnly: true }} disabled  size="small" />
                        </Box>)}
                   
                    

                        <Grid container spacing={1}>
                            <Grid item xs={1}>
                                <Typography variant="body2" style={webStyle.quoteInvoiceDetailsContent2}>S. no</Typography>

                            </Grid>
                            <Grid item xs={calculateXSValue(combinedData)}>
                                <Typography variant="body2" style={webStyle.quoteInvoiceDetailsContent2}>Description</Typography>

                            </Grid>
                            <Grid item xs={1}>
                                <Typography variant="body2" style={webStyle.quoteInvoiceDetailsContent2}>Order qty.</Typography>

                            </Grid>
                            <Grid item xs={1}>
                                <Typography variant="body2" style={webStyle.quoteInvoiceDetailsContent2}>Unit</Typography>

                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body2" style={webStyle.quoteInvoiceDetailsContent2}>Unit Price</Typography>

                            </Grid>
                            {combinedData?.some((item:DataItem) => item?.tax !== null) && 
                            <Grid item xs={1}>
                                <Typography variant="body2" style={webStyle.quoteInvoiceDetailsContent2}>Tax (%)</Typography>

                            </Grid>
                             }
                            <Grid item xs={2}>
                                <Typography variant="body2" style={webStyle.quoteInvoiceDetailsContent2}>Est. Price</Typography>

                            </Grid>
                        </Grid>
                        <Paper style={{ border: '1px solid gray', padding: 8 }}>
                            {paginatedData.map((item: any, index: any) => {
                                 const matchingHighlight = highlightData.find(
                                    (highlight:HighlightData) => highlight.product === item.description
                                  );

                                  const defaultBorder= {
                                    borderColor: "#b1b1b1",
                                    display: "flex",
                                    borderRadius: 5,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: 5,
                                    width: "100%"
                                }

                                const coloredBorder = {
                                    borderColor: matchingHighlight ? matchingHighlight.color : "#b1b1b1",
                                    display: "flex",
                                    borderRadius: 5,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: 5,
                                    width: "100%"
                                }

                                  const styleObject = matchingHighlight ? coloredBorder : defaultBorder;
                                return (
                                    <Grid container spacing={1} key={item.id}>
                                        <Grid item xs={1}>
                                            <Box border={1}
                                                style={styleObject}>
                                                <Typography
                                                    style={webStyle.tableFormText}>
                                                    {begin + index + 1}.
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={calculateXSValue(combinedData)}>
                                            <Box border={1} style={{
                                                ...styleObject,
                                                justifyContent: "flex-start"
                                            }}>
                                                <Typography style={webStyle.tableFormText}>
                                                    {item.description || "-"}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Box border={1} style={styleObject}>
                                                <Typography style={webStyle.tableFormText}>
                                                    {item.ord_quantity || "-"}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Box border={1} style={styleObject}>
                                                <Typography style={webStyle.tableFormText}>
                                                    {item.unit || "-"}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Box border={1} style={styleObject}>
                                                <Typography style={webStyle.tableFormText}>
                                                   $ {item.price || "-"}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        {combinedData?.some((item:DataItem) => item?.tax !== null)  &&
                                        <Grid item xs={1}>
                                            <Box border={1} style={styleObject}>
                                                <Typography style={webStyle.tableFormText}>
                                                    {item.tax || "0"}%
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        }
                                        <Grid item xs={2}>
                                            <Box border={1} style={styleObject}>
                                                <Typography style={webStyle.tableFormText}>
                                                   $ {item.amount || "-"}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                )
                            })}
                        </Paper>
                        <Box style={{ marginTop: "5%" }}>
                            <Pagination
                                count={Math.ceil(value?.data?.length / quoteRowsPerPage)}
                                onChange={(event, page) => handleChangePage(event, page, columnId)}
                                variant="outlined"
                                shape="rounded"
                            />
                        </Box>

                        <Box style={webStyle.subTotalBox}>
                            <Typography style={webStyle.quoteInvoiceDetailTitle2}>{configJSON.subTotalText}</Typography>
                            <Box border={1} style={{ ...webStyle.tableFormDataDesc, padding: 10, width: "30%", marginLeft: 20 }}>
                                <Typography style={webStyle.tableFormText2}>
                                   $ {paginatedData[0]?.category_subtotal || quotedetails.subtotal || "0"}
                                </Typography>
                            </Box>
                        </Box>
                        </>})}
                        <Box style={{ display: fullSize != "block" ? "" : "flex", justifyContent: 'end' }}>
                            <Box style={{ ...webStyle.quoteInvoiceTotalAmountBox, width: fullSize == "block" ? "45%" : "" }} >

                                <Box>
                                    <Box style={webStyle.quoteInvoiceSubTotalContentBox}>
                                        <Typography style={webStyle.quoteInvoiceDetailTitle2}>{configJSON.subTotalOrGrossAmountText}</Typography>
                                        <Box border={1} style={{ ...webStyle.tableFormDataDesc, padding: 10, width: "30%", marginLeft: 20 }}>
                                            <Typography style={webStyle.tableFormText2}>
                                                $ {quotedetails.gross_subtotal || "0"}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box style={webStyle.quoteInvoiceSubTotalContentBox}>
                                        <Typography style={webStyle.quoteInvoiceDetailTitle2}>{configJSON.sAndHChargesText}</Typography>
                                        <Box border={1} style={{ ...webStyle.tableFormDataDesc, padding: 10, width: "30%", marginLeft: 20 }}>
                                            <Typography style={webStyle.tableFormText2}>
                                                $ {quotedetails.s_and_h_charges || "0"}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box style={webStyle.quoteInvoiceSubTotalContentBox}>
                                        <Typography style={webStyle.quoteInvoiceDetailTitle2}>{configJSON.otherChargesText}</Typography>
                                        <Box border={1} style={{ ...webStyle.tableFormDataDesc, padding: 10, width: "30%", marginLeft: 20 }}>
                                            <Typography style={webStyle.tableFormText2}>
                                                $ {quotedetails.other_charges || "0"}
                                            </Typography>
                                        </Box>
                                    </Box>

                                    <Box style={webStyle.quoteInvoiceSubTotalContentBox}>
                                        <Typography style={webStyle.quoteInvoiceDetailTitle2}>{configJSON.discountText}</Typography>
                                        <Box border={1} style={{ ...webStyle.tableFormDataDesc, padding: 10, width: "30%", marginLeft: 20 }}>
                                            <Typography style={webStyle.tableFormText2}>
                                                $ {quotedetails.discount || "0"}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box style={webStyle.quoteInvoiceTextContent}>
                                        <Typography style={webStyle.quoteInvoiceDetailTitle}>{configJSON.taxText}</Typography>
                                        <Box style={webStyle.quoteInvoiceTextInputBox}>
                                            <Box border={1} style={{ ...webStyle.tableFormDataDesc, padding: 10, width: "30%", marginLeft: 20 }}>
                                                <Typography style={webStyle.tableFormText2}>
                                                    {quotedetails.tax_rate_amount || "0"}%
                                                </Typography>
                                            </Box>
                                            <Box border={1} style={{ ...webStyle.tableFormDataDesc, padding: 10, width: "30%", marginLeft: 20 }}>
                                                <Typography style={webStyle.tableFormText2}>
                                                    $ {quotedetails.tax || "0"}
                                                </Typography>
                                            </Box>
                                        </Box>

                                    </Box>
                                    <Box style={webStyle.quoteInvoiceSubTotalContentBox}>
                                        <Typography style={webStyle.totalPrice}>{configJSON.totalText}</Typography>
                                        <Box border={1} style={{ ...webStyle.tableFormDataDesc, padding: 10, width: "30%", marginLeft: 20 }}>
                                            <Typography style={webStyle.tableFormText2}>
                                                $ {quotedetails.total_price || "0"}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>

                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box style={{ margin: "14px 0 50px" }}><img src={resize} onClick={handleResize} style={webStyle.ImgIconStyle1} alt="" height={20} width={20} /></Box>
        </>
    )
}


export const InvoiceFormView = (props: any) => {
    const { invoicePage, handleChangePage, handleResize, fullSize, invoiceData, invoiceDetails,highlightData } = props
    const invoiceRowsPerPage = 10;
    const begin = (invoicePage - 1) * invoiceRowsPerPage;

    const endIndex = begin + invoiceRowsPerPage;
    const paginatedData = invoiceDetails?.data?.slice(begin, endIndex) || [];
    const zoomstyle = {
        display: `${fullSize}`,
        overflow: 'none',
        border: "1px solid gray",
        borderRadius: "4px"
    }


    return (
        <>
            <Box style={zoomstyle}>
                <Box style={webStyle.quoteInvoiceFormBox}>
                    <Typography variant="subtitle2" style={webStyle.viewquoteTitle}>View Invoice</Typography>
                    <Box style={webStyle.quoteInvoiceDeatilBox}>
                        <Grid container spacing={2}>

                            <Grid item sm={4}>
                                <Box>
                                    <Typography variant="body2" style={webStyle.quoteInvoiceDetailsContent}>{configJSON.invoiceDetails}</Typography>
                                    <Typography style={webStyle.quoteInvoiceDetailTitle}>{configJSON.invoiceNumberContent}</Typography>
                                    <TextField variant="outlined" style={webStyle.reportTextBox}
                                        value={invoiceData.quote_id} InputProps={{
                                            readOnly: true,
                                            style: webStyle.textInputFontSize,
                                        }} disabled
                                        size="small"
                                    />
                                    <Typography style={webStyle.quoteInvoiceDetailTitle}>{configJSON.shipDate}</Typography>
                                    <TextField variant="outlined" style={webStyle.reportTextBox} size="small"
                                        value={invoiceData.invoice_shipped_date ? invoiceData.invoice_shipped_date : "Unknown"} InputProps={{ readOnly: true, style: webStyle.textInputFontSize, endAdornment: (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" id="calendar"><g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><g stroke="gray" stroke-width="2" transform="translate(-919 -1443)"><g transform="translate(920 1444)"><rect width="18" height="18" y="2" rx="2"></rect><path d="M13 0v4M5 0v4M0 8h18"></path></g></g></g></svg>) }} disabled />
                                    <Typography style={webStyle.quoteInvoiceDetailTitle}>{configJSON.invoiceDateContent}</Typography>
                                    <TextField variant="outlined" style={webStyle.reportTextBox}
                                        size="small" value={invoiceData.date} InputProps={{ readOnly: true, style: webStyle.textInputFontSize, endAdornment: (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" id="calendar"><g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><g stroke="gray" stroke-width="2" transform="translate(-919 -1443)"><g transform="translate(920 1444)"><rect width="18" height="18" y="2" rx="2"></rect><path d="M13 0v4M5 0v4M0 8h18"></path></g></g></g></svg>) }} disabled />
                                    <Typography style={webStyle.quoteInvoiceDetailTitle}>{configJSON.invoiceTerms}</Typography>
                                    <TextField variant="outlined" style={webStyle.reportTextBox}
                                        value={invoiceData.invoice_terms} InputProps={{
                                            readOnly: true,
                                            style: webStyle.textInputFontSize,
                                        }} disabled
                                        size="small"
                                    />
                                </Box>
                            </Grid>
                            <Grid item sm={4}>
                                <Box>
                                    <Typography variant="h6" style={webStyle.quoteInvoiceDetailsContent}>{configJSON.supplierdetailsContent}</Typography>
                                    <Typography style={webStyle.quoteInvoiceDetailTitle}>{configJSON.supplierNameContent}</Typography>
                                    <TextField variant="outlined" style={webStyle.reportTextBox}
                                        value={invoiceData.vendor} InputProps={{ readOnly: true, style: webStyle.textInputFontSize, }} size="small" disabled />
                                    <Typography style={webStyle.quoteInvoiceDetailTitle}>{configJSON.supplierAddressContent}</Typography>
                                    <TextField variant="outlined" style={webStyle.reportTextBox} size="small"
                                        value={invoiceData.invoice_supplier_address} InputProps={{ readOnly: true, style: webStyle.textInputFontSize }} disabled />
                                    <Typography style={webStyle.quoteInvoiceDetailTitle}>{configJSON.salesPersonTitle}</Typography>
                                    <TextField variant="outlined" style={webStyle.reportTextBox} size="small"
                                        value={invoiceData.invoice_salesperson_name} InputProps={{ readOnly: true, style: webStyle.textInputFontSize }} disabled />
                                    <Typography style={webStyle.quoteInvoiceDetailTitle}>{configJSON.supplierContactContent}</Typography>
                                    <TextField variant="outlined" style={webStyle.reportTextBox} size="small"
                                        value={invoiceData.invoice_supplier_contact} InputProps={{ readOnly: true, style: webStyle.textInputFontSize }} disabled />
                                </Box>
                            </Grid>
                            <Grid item sm={4}>
                                <Box>
                                    <Typography variant="h6" style={webStyle.quoteInvoiceDetailsContent}>{configJSON.projectdetailsContent}</Typography>
                                    <Typography style={webStyle.quoteInvoiceDetailTitle}>{configJSON.siteNameContent}</Typography>
                                    <TextField variant="outlined" style={webStyle.reportTextBox}
                                        size="small" value={invoiceData.invoice_site_name} InputProps={{ readOnly: true, style: webStyle.textInputFontSize }} disabled />
                                    <Typography style={webStyle.quoteInvoiceDetailTitle}>{configJSON.siteAddressContent}</Typography>
                                    <TextField variant="outlined" style={webStyle.reportTextBox}
                                        value={invoiceData.invoice_site_address} size="small" InputProps={{ readOnly: true, style: webStyle.textInputFontSize }} disabled />
                                    <Typography style={webStyle.quoteInvoiceDetailTitle}>{configJSON.contactPersonTitle}</Typography>
                                    <TextField variant="outlined" size="small" style={webStyle.reportTextBox}
                                        value={invoiceData.invoice_site_person} InputProps={{ readOnly: true, style: webStyle.textInputFontSize }} disabled />
                                    <Typography style={webStyle.quoteInvoiceDetailTitle}>{configJSON.siteContactContent}</Typography>
                                    <TextField variant="outlined" style={webStyle.reportTextBox} size="small"
                                        value={invoiceData.invoice_site_contact} InputProps={{ readOnly: true, style: webStyle.textInputFontSize }} disabled />
                                </Box>
                            </Grid>
                        </Grid>
                        <Typography variant="body2" style={webStyle.quoteInvoiceDetailsContent}>{configJSON.invoiceDetails}</Typography>
                        <Grid container spacing={1}>
                            <Grid item xs={1}>
                                <Typography variant="body2" style={webStyle.quoteInvoiceDetailsContent2}>
                                    S. no
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography variant="body2" style={webStyle.quoteInvoiceDetailsContent2}>
                                    Description
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography variant="body2" style={webStyle.quoteInvoiceDetailsContent2}>
                                    Order qty.
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography variant="body2" style={webStyle.quoteInvoiceDetailsContent2}>
                                    Unit
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body2" style={webStyle.quoteInvoiceDetailsContent2}>
                                    Unit Price
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body2" style={webStyle.quoteInvoiceDetailsContent2}>
                                    Est. Price
                                </Typography>
                            </Grid>
                        </Grid>
                        <Paper style={{ border: '1px solid gray', padding: 8 }}>
                            {paginatedData.map((items: any, index: any) => {
                                const matchingHighlightRequired = highlightData.find(
                                    (highlight:HighlightData) => highlight.product === items.attributes.description
                                  );
                                  const defaultBorderInvoice= {
                                    borderRadius: 5,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: 5,
                                    width: "100%",
                                    borderColor: "#b1b1b1",
                                    display: "flex",
                                    
                                }

                                const coloredBorderInvoice = {
                                    borderRadius: 5,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: 5,
                                    width: "100%",
                                    borderColor: matchingHighlightRequired ? matchingHighlightRequired.color : "#b1b1b1",
                                    display: "flex",
                                   
                                }

                                  const styleObject = matchingHighlightRequired ? coloredBorderInvoice : defaultBorderInvoice;
                                return (

                                    <Grid container spacing={1}  key={index}>
                                        <Grid item xs={1}>
                                            <Box
                                                border={1} style={styleObject}
                                            >
                                                <Typography
                                                    style={webStyle.tableFormText}
                                                >
                                                    {begin + index + 1}.
                                                </Typography>
                                            </Box>

                                        </Grid>
                                        <Grid item xs={5}>
                                            <Box border={1} style={{ ...styleObject, justifyContent: "flex-start" }}>
                                                <Typography style={webStyle.tableFormText}>
                                                    {items.attributes.description || "-"}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Box border={1} style={styleObject}>
                                                <Typography style={webStyle.tableFormText}>
                                                    {items.attributes.ord_quantity || "-"}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Box border={1} style={styleObject}>
                                                <Typography style={webStyle.tableFormText}>
                                                    {items.attributes.order_unit || "-"}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Box border={1} style={styleObject}>
                                                <Typography style={webStyle.tableFormText}>
                                                    {items.attributes.price || "-"}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Box border={1} style={styleObject}>
                                                <Typography style={webStyle.tableFormText}>
                                                    {items.attributes.amount || "-"}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </Paper>
                        <Box style={{ marginTop: "5%" }}>
                            <Pagination
                                count={Math.ceil(invoiceDetails?.data?.length / invoiceRowsPerPage)}
                                page={invoicePage}
                                onChange={handleChangePage}
                                variant="outlined"
                                shape="rounded"
                            />
                        </Box>

                        <Box style={webStyle.subTotalBox}>
                            <Typography style={webStyle.quoteInvoiceDetailTitle2}>{configJSON.subTotalText}</Typography>
                            <Box border={1} style={{ ...webStyle.tableFormDataDesc, padding: 10, width: "30%", marginLeft: 20 }}>
                                <Typography style={webStyle.tableFormText2}>
                                    {invoiceData.invoice_subtotal}
                                </Typography>
                            </Box>
                        </Box>
                        <Box style={{ display: fullSize != "block" ? "" : "flex", justifyContent: 'end' }}>
                            <Box style={{ ...webStyle.quoteInvoiceTotalAmountBox, width: fullSize == "block" ? "45%" : "", }} >
                                <Box>
                                    <Box style={webStyle.quoteInvoiceSubTotalContentBox}>
                                        <Typography style={webStyle.quoteInvoiceDetailTitle2}>{configJSON.subTotalOrGrossAmountText}</Typography>
                                        <Box border={1} style={{ ...webStyle.tableFormDataDesc, padding: 10, width: "30%", marginLeft: 20 }}>
                                            <Typography style={webStyle.tableFormText2}>
                                                $ {invoiceData.gross_subtotal || "0"}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box style={webStyle.quoteInvoiceSubTotalContentBox}>
                                        <Typography style={webStyle.quoteInvoiceDetailTitle2}>{configJSON.sAndHChargesText}</Typography>
                                        <Box border={1} style={{ ...webStyle.tableFormDataDesc, padding: 10, width: "30%", marginLeft: 20 }}>
                                            <Typography style={webStyle.tableFormText2}>
                                                $ {invoiceData.invoice_s_and_h_charges || "0"}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box style={webStyle.quoteInvoiceSubTotalContentBox}>
                                        <Typography style={webStyle.quoteInvoiceDetailTitle2}>{configJSON.otherChargesText}</Typography>
                                        <Box border={1} style={{ ...webStyle.tableFormDataDesc, padding: 10, width: "30%", marginLeft: 20 }}>
                                            <Typography style={webStyle.tableFormText2}>
                                                $ {invoiceData.invoice_other_charges || "0"}
                                            </Typography>
                                        </Box>
                                    </Box>

                                    <Box style={webStyle.quoteInvoiceSubTotalContentBox}>
                                        <Typography style={webStyle.quoteInvoiceDetailTitle2}>{configJSON.discountText}</Typography>
                                        <Box border={1} style={{ ...webStyle.tableFormDataDesc, padding: 10, width: "30%", marginLeft: 20 }}>
                                            <Typography style={webStyle.tableFormText2}>
                                                $ {invoiceData.discount || "0"}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box style={webStyle.quoteInvoiceTextContent}>
                                        <Typography style={webStyle.quoteInvoiceDetailTitle}>{configJSON.taxText}</Typography>
                                        <Box style={webStyle.quoteInvoiceTextInputBox}>
                                            <Box border={1} style={{ ...webStyle.tableFormDataDesc, padding: 10, width: "30%", marginLeft: 20 }}>
                                                <Typography style={webStyle.tableFormText2}>
                                                    {invoiceData.invoice_tax_percent || "0"}%
                                                </Typography>
                                            </Box>
                                            <Box border={1} style={{ ...webStyle.tableFormDataDesc, padding: 10, width: "30%", marginLeft: 20 }}>
                                                <Typography style={webStyle.tableFormText2}>
                                                    $ {invoiceData.invoice_tax || "0"}
                                                </Typography>
                                            </Box>
                                        </Box>

                                    </Box>
                                    <Box style={webStyle.quoteInvoiceSubTotalContentBox}>
                                        <Typography style={webStyle.totalPrice}>{configJSON.totalText}</Typography>
                                        <Box border={1} style={{ ...webStyle.tableFormDataDesc, padding: 10, width: "30%", marginLeft: 20 }}>
                                            <Typography style={webStyle.tableFormText2}>
                                                $ {invoiceData.total_price || "0"}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>

                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}


export const QuotePDFDocument = (props: QuotePDFDocumentProps | InvoicePDFDocumentProps) => {
    const { invoiceUrl, classes, pageNumberInvoice, onPageLoadSuccess, highlightData, quoteSize } = props
    
    const applyHighlight = (text: string, fields: string[], color: string): { text: string, color?: string }[] => {
        let splitParts: HighlightPart[] = [{ text }];
        fields.forEach((field: string) => {
          let regex = new RegExp(`(${field})`, 'gi');
          splitParts = splitParts.flatMap((part: HighlightPart) => {
            if (part.color) {
              return [part];
            }
            const result = part.text.split(regex).map((splitPart: string) => ({
              text: splitPart,
              color: splitPart.toLowerCase() === field?.toLowerCase() ? color : undefined
            }));
            return result;
          });
        });
    
        return splitParts.filter(part => part.text.trim() !== ''); 
      };
    
    const customTextRenderer = (textItem: TextItem): JSX.Element => {
        const { str } = textItem as { str: string };
        let parts: any = [{ text: str }];
    highlightData.forEach(({ product, unit_price, ext_price, color }: {product: string, unit_price: string, ext_price: string, color: string}) => {
            const fieldData = [product, unit_price, ext_price];
            parts = parts.flatMap((part: any) => {
                if (part.color) {
                    return [part];
                }
                return applyHighlight(part.text, fieldData, color);
            });
        });
        return (
            <>
                {parts.map((part: any, index: any) => {
                    if (part.color) {
                        return (
                            <mark
                                key={index}
                                style={{
                                    backgroundColor: part.color,
                                    opacity: "0.5",
                                    color: "transparent",
                                    display: 'inline-block',
                                    width: '100%',
                                    padding: 0
                                }}
                            >
                                {part.text}
                            </mark>
                        );
                    }
                    return <span key={index}>{part.text}</span>;
                })}
            </>
        );
    };

    if ('invoiceValue' in props) {
        const { invoiceValue, invoiceSize } = props;
        return (
            <Document file={Array.isArray(invoiceUrl) ? invoiceUrl[invoiceValue] : invoiceUrl} className={classes.highlightItemBox}>
                <Page
                    pageNumber={pageNumberInvoice}
                    customTextRenderer={customTextRenderer}
                    onLoadSuccess={onPageLoadSuccess}
                    width={invoiceSize}
                />
            </Document>
        );
    } else {
        return (
            <Document file={invoiceUrl} className={classes.highlightItemBox} data-testid="documentTestid">
                <Page
                    pageNumber={pageNumberInvoice}
                    customTextRenderer={customTextRenderer}
                    onLoadSuccess={onPageLoadSuccess}
                    data-testid="pagerendertestid"
                    width={quoteSize}
                />
            </Document>
        );
    }

}


export const NoDataAvailable = () => (
    <Box style={webStyle.firstBoxStyle}>
        <img src={no_data} width={300} height="auto" />
        <Typography style={webStyle.noDataTextBoldStyle}>
            {configJSON.noDataAvailable}
        </Typography>
        <Typography style={webStyle.noDataTextStyle}>
            {configJSON.createReportAndQuote}
        </Typography>
    </Box>
);


export const DataContainer = ({ isNoDataAvailable, series, formatter }: any) => (
    <Box style={isNoDataAvailable ? webStyle.noDataBoxStyle : webStyle.dataBoxStyle}>
        {isNoDataAvailable ? <NoDataAvailable /> : <ChartComponent ofsetY={0} series={series} formatter={formatter} />}
    </Box>
);

export const InvoiceQuoteAmounts = ({ vendorChangeReportData }: any) => {
    if (!vendorChangeReportData) return null;
    const { invoices_total, quotes_total } = vendorChangeReportData;
    return (
        <InvoiceQuoteAmount
            totalInvoiceAmount={invoices_total}
            totalQuoteAmount={quotes_total}
        />
    );
};

export const LoadingIndicator = ({ loading }: any) => (
    loading ? <Box style={webStyle.circularLoader}><CircularProgress /></Box> : null
);



// Customizable Area End

class ViewReportDetails extends ViewReportDetailsController {

    // Customizable Area Start
    buttonStyle = () => (
        <Box style={webStyle.pageBox}>
            <Typography>
                Page {this.state.pageNumberQuote} of {this.state.quotePdfpage}
            </Typography>
            <button onClick={this.handlePrevPageQuotePdf} data-testid={"QuotePreviousBtnId"} style={{ background: "none", color: this.state.pageNumberQuote === 1 ? "#00000042" : "" }} >
                {'<'}
            </button>
            <Typography style={webStyle.pageNumber}>{this.state.pageNumberQuote}</Typography>
            <button onClick={this.handleNextPageQuotePdf} data-testid={"QuoteNextBtnId"} style={{ background: "none", color: this.state.pageNumberQuote === this.state.quotePdfpage ? "#00000042" : "" }}>
                {'>'}
            </button>
        </Box>
    )
    paginationStyle = () => (
        <>
        <Box style={webStyle.pageBox}>
            <Typography>
            {configJSON.paginationPage} {this.state.pageNumberInvoice} of {this.state.invoiceData[this.state.invoiceValue]?.pdf_page_count}
            </Typography>
            <button onClick={this.handlePrevPageInvoicePdf} data-testid={"InvoicePreviousBtnId"} style={{ background: "none", color: this.state.pageNumberInvoice === 1 ? "#00000042" : "" }} >
                {'<'}
            </button>
            <Typography style={webStyle.pageNumber}>{this.state.pageNumberInvoice}</Typography>
            <button onClick={this.handleNextPageInvoicePdf} data-testid={"InvoiceNextBtnId"} style={{ background: "none", color: this.state.pageNumberInvoice === this.state.invoiceData[this.state.invoiceValue]?.pdf_page_count ? "#00000042" : "" }} >
                {'>'}
            </button>
        </Box>
        </>
    )
    uploadInvoice = () => {
        return (
            (this.state.invoiceData.length + this.state.files.length) <= 7 &&
            <>
                <label
                    htmlFor="invoiceBtn"
                >
                    <Box style={webStyle.invoiceButton}>
                        <img src={upload_image} />
                        {configJSON.invoiceUpload}
                    </Box>
                </label>
                <input
                    type="file"
                    id="invoiceBtn"
                    data-testId="invoiceBtns"
                    multiple
                    onChange={(event) => this.handleFileChange(event)}
                    style={{ display: 'none' }}
                />
            </>
        )
    }
    
    uploadInvoiceError=()=>{
        return(
            this.state.invoiceData.length + this.state.files.length >7 && <InvoiceUploadErrTextBox><InvoiceUploadErrText>{configJSON.validationMessage}</InvoiceUploadErrText></InvoiceUploadErrTextBox>
        )
    }
    invoiceShow = () => {
        const { classes } = this.props

        return (
            this.state.isLoadingg ? <Box style={webStyle.loaderStyle}>
                <CircularProgress style={webStyle.circularLoader} />
            </Box>
                :
                <Box style={webStyle.previewInvoiceInnerBox}>
                    <QuotePDFDocument
                        invoiceUrl={this.state.invoiceUrl}
                        pageNumberInvoice={this.state.pageNumberInvoice}
                        invoiceValue={this.state.invoiceValue}
                        onPageLoadSuccess={this.onPageLoadSuccess}
                        classes={classes}
                        invoiceSize={this.state.invoiceSize}
                        highlightData={this.state.highlightData}
                        textItems={this.state.textItems}
                    />
                </Box>

        )
    }

    mainGridComponent = () => (
        <Grid container item xs={12} md={12} lg={12} style={webStyle.wrapperStyle}>
            <Box style={webStyle.firstGridStyle}>
                <Box style={webStyle.discrepancy} data-testId="discrepancy" onClick={this.handleNavigation} >
                    <img src={arrowIcon} style={webStyle.wrapperDics} />
                    <Typography style={webStyle.viewHeading}>{configJSON.viewDiscrep}</Typography>
                </Box>
                <Box style={webStyle.totalDiscrepancyBoxStyle}>
                    {!this.state.loadingVendorData && (
                        <DataContainer
                            isNoDataAvailable={this.state.isNoDataAvailable}
                            series={this.state.series}
                            formatter={this.formatNumber}
                        />
                    )}
                    {!this.state.isNoDataAvailable && (
                        <InvoiceQuoteAmounts vendorChangeReportData={this.state.vendorChangeReportData} />
                    )}
                </Box>
                <LoadingIndicator loading={this.state.loadingVendorData} />
            </Box>
        </Grid>
    );
    // Customizable Area End


    render() {
        // Customizable Area Start
        const { classes, navigation, handleSaveData } = this.props
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Box>
                    <Header
                        navigation={navigation}
                        handleSaveData={handleSaveData}
                        history={this.props.history}
                    />
                    <Grid
                        container
                        style={webStyle.fontColor}
                    >
                        <Grid item xs={12} md={6} lg={6}>
                            {<ViewReportDetailsBreadCrumb getNavigationViewreport={this.getNavigationViewreport} data-testId="breadcrumb" classes={classes} project={this.state.project_name} report={this.state.reportName} goBack={this.goBack} />}
                        </Grid>
                    </Grid>
                    {this.mainGridComponent()}
                    <Card style={webStyle.containerBoxReport}>
                        <Box style={webStyle.toggleButtonStyle}>
                            <FormControlLabel
                                value="start"
                                control={<Switch checked={this.state.toggleValue} data-testid="toggleButton" onChange={this.pdfFormToggleChangeHandler} color="default" />}
                                label={!this.state.toggleValue ? "PDF View" : "Form View"}
                                labelPlacement="start"
                            />
                        </Box>
                        <Box style={webStyle.innerContainerBox}>
                            <Typography variant="h6" style={{ ...webStyle.previewTitle, display: `${this.state.fullViewScreen}` }}>{configJSON.quoteText}</Typography>
                            <Typography variant="h6" data-testid="backviewreport" onClick={this.getNavigationViewreport} style={{ ...webStyle.previewTitle1, display: `${this.state.halfViewScreen}`,pointerEvents:'none' }} >{configJSON.invoiceText}</Typography>
                        </Box>

                        <Box style={webStyle.innerContainerBox}>
                            <Box style={{ ...webStyle.pdfBoxStyle, width: this.state.fullViewScreen === "block" ? "100%" : "50%", display: this.state.fullViewScreen === "none" ? "none" : "", }} >
                                <Box style={{ display: "flex" }}>

                                    <Tabs
                                        value={this.state.quoteValue}
                                        style={webStyle.pdfInnerBox1}
                                        data-testid="tabChangeid1"
                                        TabIndicatorProps={{
                                            style: {
                                                backgroundColor: "transparent",
                                            }
                                        }}
                                    >

                                        {
                                            this.state.quotesTabs.map((data: any, index: number) => {
                                                return (
                                                    <Tab
                                                        key={index}
                                                        label={
                                                            <>
                                                                <Box style={webStyle.pdfcurveBox}>
                                                                    <Box style={webStyle.pdfcurveInnerBox}></Box>
                                                                </Box>
                                                                <Typography variant="subtitle2" style={{ textTransform: "none" }}>{this.TruncatedTypography(data.project, 6)}</Typography>
                                                                <Box style={webStyle.pdfcurveRightBox}>
                                                                    <Box style={webStyle.curveRightPdfInnerBox}></Box>
                                                                </Box>
                                                            </>
                                                        }
                                                        {...pdfProps(0)}
                                                        title={data.project}
                                                        style={webStyle.pdfTitleBox}
                                                    />
                                                )
                                            })
                                        }

                                    </Tabs>
                                </Box>
                                <Box style={this.state.toggleValue ? webStyle.newPreviewQuoteOutterBox : webStyle.previewQuoteOutterBox}>
                                    {this.state.toggleValue && this.state.quoteUrl ?
                                        <>
                                            <QuoteFormView
                                                segmentTotal2={this.state.segmentTotal2}
                                                quoteCategoryDescription={this.state.quoteCategoryDescription}
                                                classes={classes}
                                                quoteRowsPerPage={10}
                                                segmentName2={this.state.segmentName2}
                                                segmentName1={this.state.segmentName1}
                                                segmentTotal1={this.state.segmentTotal1}
                                                fullSize={this.state.fullViewScreen}
                                                handleResize={this.handleQuoteResize}
                                                quotePage={this.state.quotePage}
                                                handleChangePage={this.handleQuotePageChange}
                                                quoteColumn={this.state.columns}
                                                quoteData={this.state.quoteData}
                                                highlightData={this.state.highlightData}
                                                calculateXSValue={this.calculateXSValue}
                                            />
                                        </>
                                        :
                                        <>
                                            {this.state.quoteUrl ? <>

                                                <Box style={webStyle.previewInvoiceInnerBox}>
                                                    <QuotePDFDocument
                                                        invoiceUrl={this.state.quoteUrl}
                                                        pageNumberInvoice={this.state.pageNumberQuote}
                                                        onPageLoadSuccess={this.onPageLoadSuccess}
                                                        classes={classes}
                                                        highlightData={this.state.highlightData}
                                                        textItems={this.state.textItems}
                                                        data-testid="loaddataid"
                                                        invoiceSize={this.state.invoiceSize}
                                                        quoteSize={this.state.quoteSize}
                                                        fullScreen={this.state.fullViewScreen}
                                                    />
                                                </Box>
                                                <Box style={webStyle.resizeAndPageWrapper1}>
                                                    <Box><img src={resize} style={webStyle.ImgIconStyle} onClick={this.handleQuoteResize} alt="" height={20} width={20} /></Box>
                                                    {this.buttonStyle()}
                                                </Box>
                                            </> :
                                                <Box style={webStyle.pdfQuoteInvoiceNodata}>
                                                    <Typography>
                                                        Data not available
                                                    </Typography>
                                                   
                                                </Box>
                                            }
                                        </>

                                    }
                                </Box>
                                
                                <NavLink to={{pathname:"/report/quotescanresult", state:{reportId: this.state.reportDataid, reportDetailsData: this.state.reportDetailsData}}}> 
                                <CustomButton
                                    title="View Scan Results"
                                    onClick={() => {}}
                                    icon={<Visibility />}
                                    variant="contained"
                                />
                                </NavLink>
                                <CustomButton
                                        title="Download quote"
                                        onClick={this.quoteDownload}
                                        data-testId="downloadBtn1"
                                        icon={<img src={download_invoice} />}
                                        variant="contained"
                                        marginLeft="20px"
                                        marginRight="20px"
                                    />                               
                            </Box>

                            <Box style={{ ...webStyle.pdfBox, display: `${this.state.halfViewScreen}`, width: this.state.halfViewScreen === "block" ? "100%" : "50%", }} >
                                <Box style={{ display: "flex", }}>
                                    <InvoiceTabs
                                        value={this.state.invoiceValue}
                                        onChange={this.handleTabChange}
                                        data-testid="tabChangeid"
                                        TabIndicatorProps={{
                                            style: {
                                                backgroundColor: "transparent"
                                            }
                                        }}
                                    >
                                          {
                                            (this.state.invoiceData.length > 0 ? this.state.invoiceData : [{ name: 'Invoice1' }]).map((obj:{[key:string]:string | undefined}, index: number) => {
                                                return (
                                                    <Tab
                                                        key={index}
                                                        label={
                                                            <>
                                                                <Box style={webStyle.pdfcurveBox1}>
                                                                    <Box style={webStyle.pdfcurveInnerBox1}></Box>
                                                                </Box>
                                                                <Typography variant="subtitle2" style={{
                                                                    whiteSpace: "nowrap",
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis", maxWidth: "8ch", textTransform: "none"
                                                                }}>{this.TruncatedInvoice(obj.project as string)}</Typography>
                                                                <Box style={webStyle.pdfcurveRightBox1}>
                                                                    <Box style={webStyle.curveRightPdfInnerBox1}></Box>
                                                                </Box>
                                                            </>
                                                        }

                                                        {...pdfProps(0)}
                                                        title={obj.project}
                                                        style={webStyle.pdfTitleBox1}
                                                    />
                                                )
                                            }).reverse()
                                        }

                                    </InvoiceTabs>

                                </Box>
                                <Box style={this.state.toggleValue ? webStyle.newPreviewInvoiceOutterBox : webStyle.previewInvoiceOutterBox}>
                                    {this.state.toggleValue && this.state.invoiceUrl.length > 0 ?
                                        <>
                                            <InvoiceFormView
                                                handleResize={this.handleInvoiceResize}
                                                fullSize={this.state.halfViewScreen}
                                                invoiceColumn={this.state.columns}
                                                invoicePage={this.state.invoicePage}
                                                handleChangePage={this.handleInvoicePageChange}
                                                classes={classes}
                                                invoiceDetails={this.state.invoiceItems[this.state.invoiceValue]}
                                                invoiceData={this.state.invoiceData[this.state.invoiceValue]}
                                                quoteData={this.state.quoteData}
                                                segmentName2={this.state.segmentName2}
                                                segmentName1={this.state.segmentName1}
                                                segmentTotal1={this.state.segmentTotal1}
                                                quoteColumn={this.state.columns}
                                                highlightData={this.state.highlightData}
                                            />
                                            <Box style={webStyle.resizeAndPageWrapper}>
                                                <Box style={{ margin: "14px 0 7px" }}><img src={resize} onClick={this.handleInvoiceResize} style={webStyle.ImgIconStyle1} alt="" height={20} width={20} /></Box>
                                                        <div style={{ display: 'flex', alignItems: 'center',flex:'auto',marginBottom:"-15px" }}>
                                                            <Checkbox
                                                                data-testid="invoicedetails"
                                                                checked={this.state.checkedBox}
                                                                onChange={this.handleChangeCheckbox}
                                                                style={this.getCheckboxStyle(webStyle)}
                                                            />
                                                             <label style={this.getLabelStyle(webStyle)}>{this.getLabelTect()}</label>
                                                        </div>
                                            </Box>
                                        </>
                                        :
                                        <>
                                            {this.state.invoiceUrl.length > 0 ?

                                                <>
                                                {this.invoiceShow()}
                                                    <Box style={webStyle.resizeAndPageWrappers}>
                                                        <Box><img src={resize} style={webStyle.ImgIconStyle} onClick={this.handleInvoiceResize} alt="" height={20} width={20} /></Box>
                                                        <div style={{ display: 'flex', alignItems: 'center',flex:'auto',marginBottom:"-20px", whiteSpace: "nowrap"}}>
                                                            <Checkbox
                                                                data-testid="payapprove"
                                                                checked={this.state.checkedBox}
                                                                onChange={this.handleChangeCheckbox}
                                                                style={this.getCheckboxStyle(webStyle)}
                                                            />
                                                            <label style={this.getLabelStyle(webStyle)}>{this.getLabelTect()}</label>
                                                        </div>
                                                       {this.paginationStyle()}
                                                    </Box>
                                                </> :
                                                <Box style={{ height: "96vh" }}>
                                                    <Typography>
                                                        { configJSON.loadingMessage }
                                                    </Typography>         
                                                </Box>
                                            }

                                        </>
                                    }
                                </Box>
                                <Box style={webStyle.buttonWrapper}>
                                    <CustomButton
                                        title="View Scan Results"
                                        onClick={this.getNavigationScaninvoice}
                                        icon={<Visibility />}
                                        variant="contained"
                                        marginTop="10px"
                                    />
                                    {this.uploadInvoice()}
                                    {this.uploadInvoiceError()}
                                </Box>
                                <Box style={webStyle.buttonWrapper}>
                                    <CustomButton
                                        title="Download invoice"
                                        onClick={this.handleDownload}
                                        data-testId="downloadBtn"
                                        icon={<img src={download_invoice} />}
                                        variant="contained"
                                        marginTop="10px"
                                    />
                                </Box>
                            </Box>
                        </Box>


                    </Card>
                </Box >
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const UploadReportContainer = styled(Box)({
    cursor: "pointer",
    "&:hover": {
        color: "#1d6dcb",
        textDecoration: "underline"
    }
})

const InvoiceUploadErrTextBox = styled(Box)({
    display: "flex",
    justifyContent: "flex-start !important",
    width: "340px"
})

const InvoiceUploadErrText = styled("span")({
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: 1.5,
    color: "red",
    textAlign: "left",
    flex: "0 0 auto !important",
    marginTop: "6px",
    marginLeft: "6px"
})
const webStyle = {
    pdfQuoteInvoiceNodata:{
        height: "96vh"
    },
    checkboxChecked: {
        color: '#4caf50',
},
    checkboxDefault: {
        color: '#05052B',
    },
    labelChecked: {
        color: '#4caf50',
        fontSize: '14px',
    },
    viewHeading: {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        fontFamily: "Poppins",
        color: "#05052B",
        float: "right",
        marginRight: "10px"
    } as React.CSSProperties,
    wrapperDics: {
        float: "right"
    } as React.CSSProperties,
    labelDefault: {
        color: '#05052B',
        fontSize: '14px',
    },
    actionCardWebStyle: {
        height: "60px",
        borderRadius: "16px",
        backgroundColor: "#ffffff",
        border: "0px solid #39b68e",
        width: "100%",
        display: "flex",
        alignItems: "center",
        cursor: 'pointer'
    },
    fontColor: {
        color: 'black',
        alignItems: "center"
    },
    generateImgStyle: {
        backgroundImage:
            "linear-gradient(180deg, #fefadb 0%, #ffeba8 78%, #ffe79a 100%)"
    },
    generateTextStyle: {
        color: "#df802d",
        fontSize: '20px',
        paddingLeft: '20px',
        paddingRight: '20px'
    },
    linkStyle: {
        textDecoration: 'none',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            textDecoration: 'none'
        }
    },
    firstGridStyle: {
        width: '100%', padding: '3%', background: '#fff', borderRadius: '2%', border: '1px solid rgb(223, 219, 219)'
    },
    discrepancy :{
        cursor: "pointer"
    },
    wrapperStyle:{
        marginTop: 20, 
        minHeight: 300
    },
    totalDiscrepancyBoxStyle: {
        display: 'flex',
        alignItems: "center"
    },
    noDataBoxStyle: {
        width: '100%'
    },
    dataBoxStyle: {
        color:'auto'
    },
    noDataTextStyle: {
        marginTop: '3%'
    },
    contentStyle: {
        display: 'flex'
    },
    noDataTextBoldStyle: {
        marginTop: '3%',
        fontSize: '18px',
        fontWeight: 550
    },
    cardMainBoxStyle: {
        display: 'flex', gap: '30px',
    },
    breadcrumbStyles: {
        color: "orange",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "150px",
        fontWeight: 500,
    } as React.CSSProperties,

    reportUnderStyle: {
        fontWeight: 500,
        textDecoration: "underline",
        color: '#87888F'
    },
    buttonWrapper: {
        marginLeft: 15,
        display: "flex",
        alignItems: "center"
    },
    invoiceButton: {
        marginLeft: 15,
        backgroundColor: "rgb(5, 5, 43)",
        borderRadius: "5px",
        marginTop: "10px",
        fontSize: "13px",
        color: "#fff",
        height: "35px",
        width: "205px",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "10px",
        fontWeight: 500,
        padding: "8px"
    },
    cardStyle: {
        minWidth: '50%', borderRadius: '10px'
    },
    cardTextStyle: {
        fontSize: '18px',
        fontWeight: 550
    },
    firstBoxStyle: { textAlign: 'center' as 'center', marginTop: '3%' },
    circularLoader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    circularLoader2: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 50
    },
    containerBoxReport: {
        display: "flex",
        flexDirection: "column" as "column",
        gap: "20px",
        marginTop: "25px",
        padding: "50px 25px"
    },
    loaderStyle: {
        top: 0,
        left: 0,
        zIndex: 9999,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        display: "flex",
        overflow: "auto",
        justifyContent: "center",
        alignItems: "center"
    } ,
    toggleButtonStyle: {
        display: "flex",
        justifyContent: "flex-end"
    },
    switchStyle: {
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            opacity: 0.8
        }
    },
    innerContainerBox: {
        display: "flex",
        flexDirection: "row" as "row",
        width: "100%",
        justifyContent: "flex-start"
    },
    previewTitle: {
        width: "50%",
        paddingLeft: "5px",
        fontWeight: 700
    },
    previewTitle1: {
        width: "50%",
        paddingLeft: "16px",
        fontWeight: 700

    },
    pdfBoxStyle: {
        display: 'block',
        maxHeight: '100%',
        width: "50%",
    },
    pdfInnerBox1: {

        top: 0,
        zIndex: 3,
        '& div:nth-child(2)': {
            margin: '0 9px 0 16px'
        },
        '& MuiBox-root-67': {
            padding: 0
        },
        '& .MuiTabs-scroller.MuiTabs-fixed': {
            overflow: 'visible !important'
        },

        '& span.MuiTabs-indicator': {
            display: 'none!important'
        },
        '& span': {
            // textTransform: 'capitalize',
        },

        overflow: 'visible',
        width: '100%',
    },
    pdfInnerBox2: {
        top: 0,
        overflow: 'visible',
        '& .MuiTabs-scroller.MuiTabs-fixed': {
            overflow: 'visible !important'
        },
        width: '100%',
        marginLeft: "12px",
        zIndex: 3,
        '& span.MuiTabs-indicator': {
            display: 'none'
        },
        '& MuiBox-root-67': {
            padding: 0
        },
        '& span': {
            // textTransform: 'capitalize',
        },
        '& div:nth-child(2)': {
            margin: '0 9px 0 16px'
        },

    },
    pdfInnerBox3: {
        overflow: 'visible',
        '& span.MuiTabs-indicator': {
            display: 'none'
        },
        '& .MuiTabs-scroller.MuiTabs-fixed': {
            overflow: 'visible !important'
        },
        '& span': {
            // textTransform: 'capitalize',
        },
        '& MuiBox-root-67': {
            padding: 0
        },
        width: '100%',
        zIndex: 3,
        top: 0,
    },
    pdfcurveBox: {
        width: '31px',
        background: '#fcf7f2',
        height: '30px',
        bottom: 0,
        left: '-31px',
        position: 'absolute' as 'absolute',
    },
    pdfcurveBox1: {
        background: '#fcf7f2',
        position: 'absolute' as 'absolute',
        bottom: 0,
        width: '31px',
        height: '30px',
        left: '-31px',
    },
    pdfcurveInnerBox: {
        borderBottomRightRadius: '15px',
        border: '1px solid #e0802e',
        borderLeft: 'none',
        borderTop: 'none',
        width: '31px',
        height: '30px',
    },
    pdfcurveInnerBox1: {
        width: '31px',
        border: '1px solid #e0802e',
        borderBottomRightRadius: '15px',
        borderTop: 'none',
        height: '30px',
        borderLeft: 'none'
    },
    pdfcurveRightBox: {
        width: '30px',
        bottom: 0,
        background: '#fcf7f2',
        position: 'absolute' as 'absolute',
        height: '33px',
        zIndex: 99,
        right: '-30px',
    },
    pdfcurveRightBox1: {
        background: '#fcf7f2',
        height: '33px',
        position: 'absolute' as 'absolute',
        right: '-30px',
        width: '30px',
        bottom: 0,
        zIndex: 99,
    },
    curveRightPdfInnerBox: {
        border: '1px solid #e0802e',
        width: '30px',
        height: '32px',
        borderTop: 'none',
        borderBottomLeftRadius: '15px',
        borderRight: 'none'
    },
    curveRightPdfInnerBox1: {
        borderTop: 'none',
        borderBottomLeftRadius: '15px',
        border: '1px solid #e0802e',
        borderRight: 'none',
        width: '30px',
        height: '32px',
    },
    pdfTitleBox: {

        backgroundColor: "white",
        '&.Mui-selected': {
            borderTopLeftRadius: '18px',
            background: '#ffffff',
            border: '1px solid #gray',
            borderBottom: 'none',
            borderTopRightRadius: '18px',
        },
        borderTopLeftRadius: '15px',
        border: '1px solid gray',
        borderBottom: 'none',
        borderTopRightRadius: '15px',
        textAlign: 'center' as 'center',
        minWidth: '115px',
        padding: '13px 0px',

    },
    pdfTitleBox1: {
        borderBottom: 'none',
        backgroundColor: "white",
        '&.Mui-selected': {
            border: '1px solid #gray',
            background: '#ffffff',
            borderTopRightRadius: '18px',
            borderTopLeftRadius: '18px',
            borderBottom: 'none'
        },
        textAlign: 'center' as 'center',
        minWidth: '115px',

        border: '1px solid gray',
        padding: '13px 0px',
        borderTopRightRadius: '15px',
        borderTopLeftRadius: '15px',
    },
    pdfTitleBox2: {
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
        borderBottom: 'none',
        backgroundColor: "white",
        minWidth: '115px',
        width: '150px',
        textAlign: 'center' as 'center',
        // padding: '13px 0px',


        '&.Mui-selected': {
            borderTopLeftRadius: '18px',
            borderTopRightRadius: '18px',
            borderBottom: 'none',
            background: '#ffffff',
            border: '1px solid #gray',
        },
        border: '1px solid gray',
    },
    pageNumber: {
        margin: "0px 2px",
        background: "#e8e2e2",
        padding: "2px 10px",
        borderRadius: "3px"
    },
    previewQuoteOutterBox: {
        height: "860px",
        width: "98%",
        border: "1px solid gray",
        borderRadius: "0 8px 8px 8px",
        padding: "15px 15px 20px 15px",
        // '& body #toolbarContainer': {
        //     display: "none"
        // },
        marginTop: "-1px"
    },
    previewInvoiceOutterBox: {
        height: "860px",
        width: "97%",
        border: "1px solid gray",
        borderRadius: "0 8px 8px 8px",
        padding: "15px 15px 20px 15px",
        marginLeft: "12px",
        marginTop: "-1px",
        overflow: 'hidden'
        // position:"relative"
    },
    previewInvoiceInnerBox: {
        height: '97%',
        // display: 'block',
        maxHeight: '97%',
        width: '100%',
        borderRadius: "8px",
        border: "1px solid #c3c0c0",
        overflowX: "scroll" as 'scroll',
    },
    previewInvoicePdfInnerBox: {
        width: '100%',
        borderRadius: "8px",
        border: "1px solid #c3c0c0",
        overflowX: "hidden"
    },
    pdfView: {
        display: 'block',
        maxHeight: '100%',
        width: '100%',
        height: '630px',
        border: '1px solid white'
    },
    resizeAndPageWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    resizeAndPageWrappers: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        overflowX: "scroll",
        overflowY: "hidden",
    } as React.CSSProperties,
    resizeAndPageWrapper1: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    ImgIconStyle: {
        marginTop: "19px",
        marginLeft: "3px",
        fontWeight: 500
    },

    ImgIconStyle1: {
        marginTop: "9px",
        marginLeft: "1px",
        fontWeight: 500
    },
    pdfBox: {
        position: "relative" as "relative",
        width: "50%",
        color: "black",
    },
    pageBox: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '15px 0 0 10px',
        whiteSpace: "nowrap"
    } as React.CSSProperties,
    quoteInvoiceFormBox: {
        background: '#fff',
        borderRadius: '10px',
        padding: "26px 15px 0px 15px",
        '& .MuiTable-root': {
            // borderCollapse: 'separate',
            borderSpacing: '8px'
        },
        '& .MuiTableContainer-root': {
            overflowX: 'unset',
        },
        '& .MuiTableCell-root': {
            borderBottom: 'none'
        },
        '& .MuiTableCell-head': {
            paddingLeft: 0,
            fontWeight: 600,
            fontSize: "10px"
        }

    },
    viewquoteTitle: {
        color: '#000',
        fontWeight: 600
    },
    quoteDeatilBox: {
        paddingBottom: '20px'
    },
    quoteInvoiceDeatilBox: {
        paddingBottom: '0px',
        '&.MuiGrid-spacing-xs-2 .MuiGrid-item': {
            padding: 0
        }
    },
    quoteInvoiceDetailsContent: {
        color: '#000',
        fontWeight: 600,
        fontSize: '10px',
        marginTop: '50px',
        marginBottom: '20px'
    },
    quoteSegmentCategoryMainBox :{
        display:"flex",
        alignItems:"center",
        gap:'30px',
        marginBottom:'30px'
    },
    quoteSegmentLabel:{
        color:'#05052B',
        fontSize:'14px',
        fontWeight:400
    },
    quoteInvoiceDetailsContent2: {
        color: '#000',
        fontWeight: 600,
        fontSize: '9px',
        marginBottom: '10px'
    },
    quoteInvoiceDetailTitle: {
        color: '#0a0019',
        fontWeight: 500,
        fontSize: "10px",
        margin: '15px 0 5px',
    },
    quoteInvoiceDetailTitle2: {
        color: '#0a0019',
        fontWeight: 500,
        fontSize: "12px",
        margin: '15px 0 5px',
    },
    reportTextBox: {
        width: "100%",
        padding: "8px !important",
        fontSize: 12,
        "& .MuiInputBase-root": {
            fontSize: "10px",
        },
    },
    tableFormText: { fontSize: 8, },
    tableFormText2: { fontSize: 12, },
    tableFormDataDesc: {
        borderColor: "#b1b1b1",
        display: "flex",
        borderRadius: 5,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 5,
        width: "100%"
    },
    quoteInvoiceTotalAmountBox: {
        border: '2px solid #eee',
        borderRadius: '8px',
        padding: '20px',
        width: '55%',
        marginBottom: 20
    },
    quoteInvoiceSubTotalContentBox: {
        marginBottom: '15px',
        '& p:last-child': {
            width: '40%',
            color: '#aeaab2',
            fontWeight: 500,
            padding: '8px',
            border: '1px solid #d5d5d5',
            borderRadius: '5px',
            fontSize: "10px"
        },
        display: 'flex',
        justifyContent: 'space-between',
    },
    totalPrice: {
        fontWeight: 500,
        fontSize: "11.5px",
        color: '#0a0019',
        margin: '15px 0 5px',
    },
    quoteInvoiceTextContent: {
        alignItems: 'center',
        marginBottom: '20px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    quoteInvoiceTextInputBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'end',
        fontSize: "10px",
        '& p:first-child': {
            width: '22%',
            marginRight: '20px',
            color: '#aeaab2',
            fontWeight: 500,
            padding: '8px',
            border: '1px solid #d5d5d5',
            borderRadius: '4px',
            fontSize: "10px"
        },
        '& p:last-child': {
            width: '38%',
            color: '#aeaab2',
            fontWeight: 500,
            padding: '8px',
            border: '1px solid #d5d5d5',
            borderRadius: '4px',
            fontSize: "10px"
        },

    },
    subTotalBox: {
        display: 'flex',
        justifyContent: 'end',
        margin: '50px 0',
        alignItems: 'baseline',
        '& .MuiFormControl-root': {
            marginLeft: '20px',
            marginRight: '8px',
            width: '20%'
        }
    },
    textInputFontSize: {
        fontSize: 12
    },
    newPreviewInvoiceOutterBox: {
        height: "93%",
        width: "97%",
        border: "1px solid gray",
        borderRadius: "0 8px 8px 8px",
        padding: "15px 15px 20px 15px",
        marginLeft: "12px",
        marginTop: "-1px",
        overflow: 'hidden'
    },


    newPreviewQuoteOutterBox: {
        height: "93%",
        border: "1px solid gray",
        borderRadius: "0 8px 8px 8px",
        padding: "15px 15px 20px 15px",
        marginTop: "-1px",
        width: "98%",

    },
}

const InvoiceTabs = styled(Tabs)({
    top: 0,
    width: '100%',
    marginLeft: "12px",
    zIndex: 3,
    "& .MuiTabs-flexContainer": {
        overflow: "scroll",
        display: "flex",
        scrollbarWidth: "none"
    }
});

export { ViewReportDetails }
export default withStyles(ProjectsTableStyle)(ViewReportDetails)


// Customizable Area End
