import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  AppBar,
  Toolbar,
  styled
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import MoreIcon from "@material-ui/icons/MoreVert";
import NewHeader from "./NewHeader.web";
import { bannerConstructionImg } from "./assets";
import { TermsAndCond } from "./TermsandconditionsController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const BannerImgBoxPrivacy = styled(Box)({
    maxWidth: 1290,
    display:"flex",
    alignItems: 'center',
    marginTop:"40px",
  width: '100%',
  margin: 'auto',
  backgroundImage: `url(${bannerConstructionImg})`,
  backgroundRepeat: 'no-repeat',
  padding: '100px 20px 100px 65px',
  borderRadius: 12,
  backgroundSize: 'cover',
  '@media(max-width: 1300px)': {
    width: '90%',
  },
  '@media(max-width: 960px)': {
    padding: '20px',
  },

  '& .inner-box': {
    maxWidth: 853,
  }
})


const BannerHeadingTextPrivacy = styled(Typography)({
fontSize:"24px",
lineHeight:"36px",
color:"#FFFFFF",
fontFamily:"Poppins",
fontWeight:600,

})

const BannerDescriptionTextPrivacy = styled(Typography)({
    lineHeight:"32px",
  color:"#FFFFFF",
  letterSpacing:"1px",
  marginTop:"14px",
  fontFamily:"Poppins",
  fontWeight:400,
  fontSize:"18px",
  
})

const TermsBoxPrivacy = styled(Box)({
    justifyContent:"center",
    marginBottom:"30px",
    marginTop:"20px",
  display:"flex",
 
})

const MainTermsBoxPrivacy = styled(Box)({
    alignItems:"center",
  maxWidth:1290,
  width:"100%",
  display:"flex",
  flexDirection:"column",
  
})

const HeadingAndDescriptionBoxPrivacy = styled(Box)({
flexDirection:"column",
width:"82%",
marginTop:"20px",
display:"flex",

})

const TermsHeadingTextPrivacy = styled(Typography)({
    fontSize:"16px",
lineHeight:"24px",
textAlign:"initial",
color:"#05052B",
fontFamily:"Poppins",
fontWeight:600,

})

const TermsDescriptionTextPrivacy = styled(Typography)({
  color:"#05052B",
  fontWeight:400,
  fontSize:"16px",
  lineHeight:"24px",
  textAlign:"initial",
  fontFamily:"Poppins",
 
  })

  const ButtonBoxPrivacy = styled(Box)({
    justifyContent:"center",
    marginBottom:"40px !important",
    display:"flex",
    
  })

const GoBackBtnPrivacy = styled(Button)({
    color:"#05052B",
  textTransform:"none",
  cursor:"pointer",
  padding:"13px",
  minWidth:"213px",
  border:"1px solid #05052B",
  marginBottom:"40px !important",
})


// Customizable Area End

import PrivacyPolicyController, {
  Props,
  configJSON,
} from "./PrivacyPolicyController";

export default class PrivacyPolicy extends PrivacyPolicyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
       <NewHeader navigation={this.props.navigation} id={""}/>
       <BannerImgBoxPrivacy>
       <Box className="inner-box">
            <BannerHeadingTextPrivacy>We value your privacy</BannerHeadingTextPrivacy>
            <BannerDescriptionTextPrivacy>
            At My Invoice Check, safeguarding your privacy is a top priority. Learn about our practices and commitment to protecting your personal information. Your trust is paramount, and we want you to feel confident in our approach to privacy.
            </BannerDescriptionTextPrivacy>
       </Box>
       </BannerImgBoxPrivacy>
       <TermsBoxPrivacy>
       <MainTermsBoxPrivacy>
      {this.state.privacyPolicies.map((privacy:TermsAndCond)=>{
        return <HeadingAndDescriptionBoxPrivacy key={`privacy_${privacy.id}`}>
          <TermsHeadingTextPrivacy>
           {privacy.id + "."} {privacy.title}
          </TermsHeadingTextPrivacy>
          <TermsDescriptionTextPrivacy>
            {privacy.description}
          </TermsDescriptionTextPrivacy>
        </HeadingAndDescriptionBoxPrivacy>
      })}
       </MainTermsBoxPrivacy>
       </TermsBoxPrivacy>
       <ButtonBoxPrivacy>
        <GoBackBtnPrivacy onClick={()=>this.props.navigation.goBack()}>Go back</GoBackBtnPrivacy>
       </ButtonBoxPrivacy>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
