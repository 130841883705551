// Customizable Area Start
import React from "react";

import {
    Grid, Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
    Button, TableSortLabel, CircularProgress,
    Breadcrumbs, Link, Card, CardContent,
    DialogTitle,
    MenuItem,
    OutlinedInput,
    Dialog,
    Slider,
    DialogContent, TextField, InputAdornment, Menu,InputLabel,Select
} from "@material-ui/core";
import MomentUtils from '@date-io/moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ErrorIcon from '@material-ui/icons/Error';
import { createTheme, withStyles, styled } from "@material-ui/core/styles";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
  import { NumericFormat } from "react-number-format";
  import { makeStyles } from '@material-ui/core/styles';
  import Checkbox from '@material-ui/core/Checkbox';


import TuneIcon from '@material-ui/icons/Tune';
import SearchIcon from '@material-ui/icons/Search';
import Done from "@material-ui/icons/Done"
import Close from "@material-ui/icons/Close"

import { NavLink } from "react-router-dom";

import {
    create_new_invoice,
    exportCSV, no_data,calendarImage
} from "./assets";
import Header from "../../landingpage/src/Header.web";
import ViewReportController, { Props, configJSON,ColumnProjects, FilterDialogProps } from "./ViewReportController.web";
import { StyledTableRowItems, TableSortIcon } from "../../landingpage/src/DashboardNewTable.web";
import moment from "moment";
import Chart from "react-apexcharts";
import { TableFooter, ProjectsTableStyle, useStyles } from './Projects.web';
import StopRoundedIcon from '@material-ui/icons/StopRounded';
import { CSVLink } from "react-csv";

// Customizable Area End


// Customizable Area Start
const theme = createTheme({});

const StyledTableModal = styled(Menu)({
    "& .MuiPopover-paper ul.MuiMenu-list":{
          maxHeight:'100px !important',
          overflow: "auto",
    },
    "& .MuiPaper-elevation8": {
        overflow: 'visible',
        boxShadow: "none",
        border: "1px solid #d2d1d1",
        marginTop: '45px',
        transform: 'translate(-31%) !important',
        '&::before': {
            content: '" "',
            position: 'absolute',
            width: '0',
            borderLeft: 'solid 20px transparent',
            borderBottom: 'solid 12px #dbdbdb',
            borderRight: 'solid 1px transparent',
            height: '0',
            left: '180px',
            top: '-12px',
        },
        '&::after': {
            position: 'absolute',
            width: '0',
            content: '" "',
            height: '0',
            borderLeft: 'solid 19px transparent',
            borderBottom: 'solid 9px white',
            top: '-8px',
            borderRight: 'solid 1px transparent',
            left: '180px',
        }
    }
});

const StyledSaveButton = styled(Button)({
    "&.MuiButton-root": {
        borderRadius: "4px",
        color: "rgb(5, 5, 43)",
        width: '25%',
        textTransform: 'capitalize',
        background: "#ffffff",
        border: "1px solid rgb(5, 5, 43)",
    }
})

const StyledDeleteButton = styled(Button)({
    "&.MuiButton-root": {
        borderRadius: "4px",
        color: "red",
        width: '180px',
        marginLeft: "10px",
        padding: '10px 0px',
        textTransform: 'capitalize',
        background: "#ffffff",
        border: "1px solid red",
    }
})

const StyledSettingButton = styled(Button)({
    "&.MuiButton-root": {
        borderRadius: "4px",
        color: "#fff",
        textTransform: 'none',
        width: '180px',
        padding: '10px 0px',
        background: 'rgb(5, 5, 43)'
    }
})

const StyledTableMenuItem = styled(Menu)({
    "& .MuiPaper-elevation8": {
        overflow: 'visible',
        boxShadow: "none",
        border: "1px solid #d2d1d1",
        marginTop: '54px',
        transform: 'translate(-33%, -2%) !important',
        '&::before': {
            content: '" "',
            width: '0',
            position: 'absolute',
            borderBottom: 'solid 12px #dbdbdb',
            borderLeft: 'solid 20px transparent',
            height: '0',
            borderRight: 'solid 12px transparent',
            top: '-12px',
            left: '121px',
        },
        '&::after': {
            position: 'absolute',
            width: '0',
            height: '0',
            content: '" "',
            borderBottom: 'solid 9px white',
            borderLeft: 'solid 20px transparent',
            borderRight: 'solid 10px transparent',
            top: '-9px',
            left: '121px',
        }
    }
});


const StartDateCalendar = styled(KeyboardDatePicker)({
    height: "55px",
    border: '1px solid #bdbdbd',
    borderRadius:'4px',
    margin: 0,
    display:'flex',
    justifyContent:"center",
    "& .MuiFormHelperText-root.Mui-error": {
        display: 'none !important'
    },
    "& .MuiInputBase-input": {
        paddingLeft:'11px'
    },
   
});

const CustomTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none', 
      },
      '&:hover fieldset': {
        border: 'none', 
      },
      '&.Mui-focused fieldset': {
        border: 'none',
      },
    },
  });

const StyledButton = styled(Button)({
    textTransform: 'capitalize',
    fontSize: '0.75rem',
    fontWeight: 500,
});  

const styleCheckBox = makeStyles({
    root: {
        '&$checked': {
            color: '#b3a500',
        },
    },
    checked: {},
});



export const DashboardNavNewCard = (props: any) => {
    const classes = useStyles();
    return (
        <>
            <div style={webStyle.actionCardWebStyle} onClick={props.handleOnClick}>
                <div
                    className={`${classes.iconImageWrapper}`}
                    style={props.imageStyle}
                >
                    <img src={props.img} alt="" style={{ height: '35px' }} />
                </div>
                <div className={classes.actionDataTxt} style={props.textStyle}>
                    {props.actionCardText}
                </div>
            </div>
        </>
    );
};

export const DeleteReportDialog = (props: any) => {
    const { handleClose, handleOpen, handleCancelButton, handleDeleteAPI } = props

    return (
        <>
            <Dialog maxWidth="xs" style={{ padding: '10px' }} data-testid="dialogOpenId" onClose={handleClose} open={handleOpen}>
                <DialogTitle style={{ color: 'black', width: '100%', textAlign: 'center' }}>
                    Are you sure you want to delete this report? This cannot be undone.
                </DialogTitle>
                <DialogContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '14px', flexDirection: 'column', paddingBottom: '5%' }}>
                    <ErrorIcon style={{ color: 'orange', fontSize: 40, marginBottom:"5%" }} />
                    <Box style={{display:"flex", justifyContent: "space-between"}}>
                    <StyledSettingButton onClick={handleCancelButton} variant="contained">Cancel</StyledSettingButton>
                    <StyledDeleteButton variant="contained" onClick={handleDeleteAPI}>Delete</StyledDeleteButton>
                    </Box>
                                    
                </DialogContent>
            </Dialog>
        </>
    )
}


export const ViewReportBreadCrumb = (props: any) => {
    const { classes } = props
    return (<>
        <Box className={classes.breadcrumbStyle}>
            <Breadcrumbs
                separator={">"}
                aria-label="breadcrumb"

            >
                <Link color="inherit" className={classes.breadcrumbLinkStyle} style={webStyle.viewUnderStyle} href="/Projects">
                    {configJSON.projectsTitle}
                </Link>

                <Typography className={classes.breadcrumbText} style={webStyle.viewTextStyle}>{configJSON.reportOf}'{props.project_name}'</Typography>
            </Breadcrumbs>
        </Box>
    </>)

}

export const AdditionalCardsSection = (props: any) => {
    return (<Box style={{marginTop: 20}}>
        <Box style={webStyle.cardMainBoxStyle}>
        <Card style={webStyle.cardStyle}>
                <CardContent>
                    <Box style={webStyle.contentStyle}>
                        <StopRoundedIcon />
                        <Box>
                            <Typography style={webStyle.cardTextStyle}>{configJSON.totalQuoteAmount}</Typography>
                            <Typography style={webStyle.cardTextStyle}>$ {props.totalQuoteAmount}</Typography>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
            <Card style={webStyle.cardStyle}>
                <CardContent>
                    <Box style={webStyle.contentStyle}>
                        <StopRoundedIcon style={{ color: 'gray' }} />
                        <Box>
                            <Typography style={webStyle.cardTextStyle}>{configJSON.totalInvoiceAmount}</Typography>
                            <Typography style={webStyle.cardTextStyle}>$ {props.totalInvoiceAmount}</Typography>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    </Box>)
};


export const ChartComponent = (props: any) => {
    return (
        <Chart
            options={{
                labels: ["Overcharged", "Undercharged"],
                colors: ["#f7728a", "#ffcb00"],
                plotOptions: {
                    radialBar: {
                        inverseOrder: false,
                        offsetX: -30,
                        offsetY: props.ofsetY ? props.ofsetY : 50,
                        startAngle: 0,
                        endAngle: 360,
                        hollow: {
                            dropShadow: {
                                enabled: false,
                                top: 0,
                                left: 0,
                                blur: 3,
                                opacity: 0.5,
                            },
                            margin: 5,
                            size: "60%",
                            background: "transparent",
                            position: "front",
                            imageWidth: 150,
                            imageHeight: 150,
                            imageOffsetX: 0,
                            image: undefined,
                            imageOffsetY: 0,
                            imageClipped: true,
                        },
                        track: {
                            dropShadow: {
                                enabled: false,
                                opacity: 0.5,
                                top: 0,
                                left: 0,
                                blur: 3,
                            },
                            show: true,
                            startAngle: undefined,
                            strokeWidth: "97%",
                            opacity: 1,
                            margin: 5,
                            endAngle: undefined,
                            background: ["#16164d", "#bbb2b2"],
                        },
                        dataLabels: {
                            show: true,
                            name: {
                                show: true,
                                fontSize: "12px",
                                fontFamily: undefined,
                                color: undefined,
                                offsetY: 0,
                            },
                            value: {
                                show: true,
                                fontSize: "16px",
                                fontFamily: undefined,
                                color: "#000",
                                offsetY: 16,
                            },
                            total: {
                                show: true,
                                label: "Total Discrepancy",
                                color: "#000",
                                formatter: props.formatter,
                                fontSize: "12px"
                            },
                        },
                    },
                },
                legend: {
                    show: false,
                    showForZeroSeries: true,
                    width: undefined,
                    height: undefined,
                    formatter: undefined,
                    position: "right",
                    horizontalAlign: "center",
                    floating: false,
                    fontSize: "12px",
                    fontFamily: "Helvetica, Arial",
                    offsetX: 0,
                    offsetY: 50,
                    showForSingleSeries: false,
                    showForNullSeries: true,
                    labels: {
                        colors: undefined,
                        useSeriesColors: false,
                    },
                    onItemClick: {
                        toggleDataSeries: true,
                    },
                    markers: {
                        strokeWidth: 0,
                        customHTML: undefined,
                        onClick: undefined,
                        offsetX: 0,
                        offsetY: 0,
                    },
                    onItemHover: {
                        highlightDataSeries: true,
                    },
                    itemMargin: {
                        horizontal: 20,
                        vertical: 5,
                    },


                },
            }}
            series={props.series}
            type="radialBar"
            width={250}
            height={250} 
        /> 
    )
}

export const DiscrepancyByVendor = (props: any) => {
    return (
        <Grid container xs={12} md={7} lg={7} style={{ paddingLeft: 30 }}>
            <Box style={webStyle.firstGridStyle}>
                <Box style={{ ...webStyle.totalDiscrepancyBoxStyle, alignItems: 'center', marginBottom: 20 }}>
                    <Typography style={{ fontSize: 24, color: "rgb(5, 5, 43)", fontWeight: 500 }}>{configJSON.discrepancyByVendorTitle}</Typography>
                    <Typography onClick={props.handleOpenVendor} style={{ color: "rgb(5, 5, 43", textDecoration: 'underline',  cursor: 'pointer' }}>{configJSON.seeAll}</Typography>
                </Box>
                <Box style={webStyle.totalDiscrepancyBoxStyle}>
                    <Card variant="outlined" style={{ width: "60%", height: "240px" }}>
                        <CardContent style={{ padding: 0 }}>
                            <Box style={{ display: 'flex', padding: 20 }}>
                                <Typography style={{ width: '10%', color: "rgb(5, 5, 43)", fontWeight: 500  }}>{configJSON.sno}</Typography>
                                <Typography style={{ width: '50%', marginLeft: 20, color: "rgb(5, 5, 43)", fontWeight: 500  }}>{configJSON.vendorTitle}</Typography>
                                <Typography style={{ width: '40%', textAlign: 'center', color: "rgb(5, 5, 43)", fontWeight: 500  }}>{configJSON.totalDiscrepancy}</Typography>
                            </Box>
                            <Box style={{ display: 'flex', borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }} />
                            
                                 { props.loadingVendorData ?  <Box style={webStyle.circularLoader2}><CircularProgress /></Box> :  
                            
                            <Box style={{ overflow: 'auto', maxHeight: "130" }}>
                            <Box style={{ marginTop: 10, paddingLeft: 20, paddingRight: 20 }}>
                                
                                {props.discrepancyArray
                                ?.sort((num: { total_discrepancy: string }, num1: { total_discrepancy: string }) => {
                                    const parseDiscrepancy = (discrepancy: string) => {
                                        if (typeof discrepancy !== 'string') {
                                          return 0;
                                        }
                                        return parseFloat(discrepancy.replace(/,/g, '') || '0');
                                      };
                                return parseDiscrepancy(num1.total_discrepancy) - parseDiscrepancy(num.total_discrepancy);})
                                            .map((item: { [key: string]: string | number }, currentIndex: number) => (
                                                <Box key={currentIndex} style={{ display: 'flex', lineHeight: 2 }}>
                                                    <Typography style={{ width: '10%', textAlign: 'center', color: 'rgb(5, 5, 43)' }}>
                                                        {currentIndex + 1}
                                                    </Typography>
                                                    <Typography style={{ width: '50%', marginLeft: 20, color: 'rgb(5, 5, 43)', whiteSpace:'nowrap', textOverflow:'ellipsis', overflow:'hidden'}}
                                                     className="ellipsis"
                                                    >
                                                    {item.vendor as string}
                                                    </Typography>
                                                    <Typography style={{ width: '40%', textAlign: 'center', color: 'rgb(5, 5, 43)' }}>
                                                        {"$" + item.total_discrepancy}
                                                    </Typography>
                                                </Box>
                                            ))
                                        }
                            </Box>
                            </Box>
}
                        </CardContent>
                    </Card>

                    <Card variant="outlined" style={{ width: "35%", height: "240px", display: 'flex', alignItems: "center", justifyContent: 'center' }}>
                        <CardContent>
                            <Box>
                                <Typography style={{ color: "rgb(5, 5, 43)", fontSize: "14" }} >{configJSON.totalVendor}</Typography>
                                <Typography variant="h6" style={{ textAlign: "center", color: "rgb(5, 5, 43)"  }}>{props.totalUniqueVendor}</Typography>
                            </Box>

                        </CardContent>
                    </Card>
                </Box>
            </Box>
        </Grid>
    )
}


export const VendorListDialog = (props: any) => {
    const { title, handleClose, handleOpen, handleSearch, vendorSearchInputValue, filteredVendorList, } = props

    return (
        <>
            <Dialog maxWidth={"sm"} fullWidth={true} style={{ padding: '20px', minHeight: 300 }} data-testid="dialogOpenId" onClose={handleClose} open={handleOpen}>
                <Box style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 20, paddingRight: 24 }}>
                    <DialogTitle style={{ fontWeight: 550, textAlign: 'center',  color: "rgb(5, 5, 43)" }}>{title}</DialogTitle>
                    <TextField
                        variant="outlined"
                        placeholder="Search vendors"
                        style={{ width: "92%" }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            )
                        }}
                        value={vendorSearchInputValue}
                        onChange={(event) => {
                            handleSearch(event);
                        }}
                        InputLabelProps={{
                            style: { color: 'rgb(5, 5, 43)' }
                        }}
                    />
                </Box>
                <DialogContent>
                    <Box style={{ display: 'flex', padding: 20 }}>
                        <Typography style={{ width: '10%', fontWeight: 500, fontSize: 20 }}>{configJSON.sno}</Typography>
                        <Typography style={{ width: '50%', marginLeft: 20 , fontWeight: 500, fontSize: 20 }}>{configJSON.vendorTitle}</Typography>
                        <Typography style={{ width: '40%', textAlign: 'center', fontWeight: 500, fontSize: 20 }}>{configJSON.totalDiscrepancy}</Typography>
                    </Box>
                    <Card variant="outlined" style={{ width: "100%", }}>
                        <CardContent style={{ padding: 0 }}>
                            {
                                filteredVendorList && filteredVendorList.length > 0 ?
                            <Box style={{ maxHeight: '300px', overflowY: 'auto', paddingLeft: 20, paddingRight: 20, marginTop: 10, paddingBottom: 20 }}>
                                {filteredVendorList.map((item: any, index: any) => (
                                    <Box key={index} style={{ display: 'flex', lineHeight: 2}} >
                                        <Typography style={{ width: '10%', textAlign: 'center', color: "rgb(5, 5, 43)" }}>{index + 1}.</Typography>
                                        <Typography style={{ width: '50%', marginLeft: 20, color: "rgb(5, 5, 43)", whiteSpace:'nowrap', textOverflow:'ellipsis', overflow:'hidden' }}>                                                       
                                         {item.vendor as string}
                                        </Typography>
                                        <Typography style={{ width: '40%', textAlign: 'center', color: "rgb(5, 5, 43)" }}>{"$" + item.total_discrepancy}</Typography>
                                    </Box>
                                ))}
                            </Box>
                            :
                            <Typography style={{ textAlign: 'center', color: "rgb(5, 5, 43)", padding: 30 }}>
                                {configJSON.noDataContent}
                            </Typography>
                            }
                        </CardContent>
                    </Card>
                </DialogContent>
                <Box style={{ display: "flex", justifyContent: 'center', padding: 20 }}>
                    <StyledSaveButton variant='contained' onClick={handleClose}>Go Back</StyledSaveButton>
                </Box>
            </Dialog>
        </>
    )
}

export const FilterDialog = (props: FilterDialogProps) => {
    const {handleCheckboxChange ,sliderInvoiceValue,handleInvoiceSliderChange,openDialog,classes, daterangeOptionsData, vendorListData, endDateDisabledVal, toDateVal, resetAllHandler, handleToDateChange, handleFromDateChange, dateVal, MaxSliderVal, MinSliderVal, filterApplyHandler, handleMinSliderChange, handleMaxSliderChange,  handleVendorChange, handleClose,  vendorName, handleSliderChange, sliderValue, isCustomValue, handleDateChange ,minNumberSilder,maxNumberSilder} = props
    
    const datastyle =styleCheckBox();

    return (
        <>
            <Dialog className={classes.dialogBoxStyle} data-testid="dialogOpenId" onClose={handleClose} open={openDialog}>
                <Box className={classes.filterBox}>
                    <Typography variant="h6">{configJSON.filterTitle}</Typography>
                    <CloseIcon onClick={handleClose} data-testid="filterCloseId" style={{ cursor: "pointer" }} />
                </Box>
                <Box className={classes.selectMenuBox}>
                    <Box className={classes.vendorOptionBox}>
                        <Box className={classes.vendorBox}>
                            <InputLabel id="label">{configJSON.vendorTitle}</InputLabel>
                            <Select
                                variant="outlined"
                                IconComponent={ExpandMoreIcon}
                                value={vendorName == "" ? 'selected' : vendorName}
                                onChange={handleVendorChange}
                                data-test-id="vendorInputField"
                                input={<OutlinedInput />}
                                displayEmpty
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                                data-testid="vendorTestId"
                            >
                                <MenuItem value="selected" disabled className={classes.placeHolderMenu}>{vendorName == "" ? configJSON.vendorPlaceholder : vendorName}</MenuItem>
                                {vendorListData.map((vendorItem) => {
                                    return (
                                        <MenuItem key={vendorItem} value={vendorItem} className={classes.selectMenubox}>
                                            {vendorItem}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </Box>
                       
                        <Box className={classes.vendorBox}>
                            <InputLabel id="label">{configJSON.dateCreatedTitle}</InputLabel>
                            <Select
                                variant="outlined"
                                IconComponent={ExpandMoreIcon}
                                value={isCustomValue == "" ? "selected" : isCustomValue}
                                onChange={handleDateChange}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                                data-testid="dateTestId"
                            >
                                <MenuItem value={"selected"} disabled className={classes.placeHolderMenu}>{configJSON.dateRangePlaceholder}</MenuItem>
                                {daterangeOptionsData.map((dateValue) => {
                                    return (
                                        <MenuItem key={dateValue} value={dateValue} className={classes.selectMenubox}>
                                            {dateValue}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                           
                            {
                            isCustomValue !== "" &&
                            <Box className={classes.dateBox}>
                                <Box className={classes.minValueBox}>
                                    <InputLabel id="label">From</InputLabel>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <StartDateCalendar
                                                 autoOk
                                                 disableToolbar
                                                 variant="inline"
                                                 format="MM-DD-YYYY"
                                                 margin="normal"
                                                 value={dateVal}
                                                 placeholder="MM-DD-YYYY"
                                                 onChange={handleFromDateChange}
                                                 KeyboardButtonProps={{
                                                   'aria-label': 'change date',
                                                 }}
                                                 InputProps={{
                                                   disableUnderline: true,
                                                 }}
                                                 data-test-id="datechangeId"
                                                 className={classes.datePicker} 
                                                 keyboardIcon={<img src={calendarImage}  alt="fromimage" />
                                               }
                                            />
                                    </MuiPickersUtilsProvider>
                                </Box>
                            </Box>
                        }
                        </Box>
                        <Box className={classes.vendorBox}>

                        {
                            isCustomValue !== "" &&
                        <Box >
                                    <InputLabel id="label">To</InputLabel>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <StartDateCalendar
                                                 autoOk
                                                 disableToolbar
                                                 variant="inline"
                                                 format="MM-DD-YYYY"
                                                 margin="normal"
                                                 value={toDateVal}
                                                 placeholder="MM-DD-YYYY"
                                                 data-testid="toDateChangeId"
                                                 disabled={endDateDisabledVal}
                                                 onChange={handleToDateChange}
                                                 KeyboardButtonProps={{
                                                   'aria-label': 'change date',
                                                 }}
                                                 InputProps={{
                                                   disableUnderline: true,
                                                 }}
                                                 className={classes.datePicker} 
                                                 keyboardIcon={<img src={calendarImage}  alt="toimage" />
                                               }
                                            />
                                        </MuiPickersUtilsProvider>
                        </Box>
                        }
                    </Box>
                    <Box className={classes.vendorBox} style={{display:"flex",gap:"5px",alignItems:'center'}}>
                    <Checkbox
                        checked={sliderInvoiceValue === 1 ? true : false}
                        onChange={handleCheckboxChange}
                        data-test-id="checkbox-invoice"
                        classes={{ root: datastyle.root, checked: datastyle.checked }}
                    />
                    <Typography id="label">{configJSON.atleastOneInvoice}</Typography>
                    </Box>  
                    </Box>
                    <Box className={classes.discrepancyBox}>
                    <InputLabel id="label">Number of Invoice</InputLabel>
                        <Box className={classes.PrettoSliderBox}>
                            <Slider
                                value={sliderInvoiceValue}
                                onChange={handleInvoiceSliderChange}
                                aria-labelledby="range-slider"
                                data-test-id="sliderTestId-invoice"
                                className={classes.slider}
                            />
                        </Box>
                        <InputLabel id="label" style={{marginTop:"30px"}}>Discrepancy</InputLabel>
                        <Box className={classes.PrettoSliderBox}>
                            <Slider
                                value={sliderValue}
                                onChange={handleSliderChange}
                                aria-labelledby="range-slider"
                                data-testid="sliderTestId"
                                min={minNumberSilder}
                                max={maxNumberSilder}
                                className={classes.slider}
                            />
                        </Box>
                        <Box className={classes.minMaxValueBox}>
                            <Box className={classes.minValueBox}>
                                <InputLabel id="label">{configJSON.minTitle}</InputLabel>
                                <NumericFormat
                                    value={MinSliderVal}
                                    placeholder="$ 100"
                                    prefix="$ "
                                    thousandSeparator=","
                                    decimalSeparator="."
                                    decimalScale={4}
                                    onValueChange={(values) => handleMinSliderChange(values.floatValue)}
                                    customInput={TextField}
                                    data-testid="minSliderTestId"
                                    variant="outlined"
                                />
                            </Box>
                            <Box>
                                <InputLabel id="label">{configJSON.maxTitle}</InputLabel>
                                <NumericFormat
                                    value={MaxSliderVal}
                                    placeholder="$ 200"
                                    prefix="$ "
                                    thousandSeparator=","
                                    decimalSeparator="."
                                    decimalScale={4}
                                    onValueChange={(values) => handleMaxSliderChange(values.floatValue)}
                                    customInput={TextField}
                                    data-testid="maxSliderTestId"
                                    variant="outlined"
                                />
                            </Box>
                        </Box>
                      
                    </Box>
                </Box>
                <Box className={classes.buttonBox}>
                    <Button variant="outlined" onClick={resetAllHandler} data-testid="resetAllHandlerTestId">Reset All</Button>
                    < Button variant="contained" onClick={filterApplyHandler} data-test-id="filterApplyHandlerTestid" >Apply</Button>          
                </Box>
            </Dialog >
        </>
    )
}

// Customizable Area End

class ViewReport extends ViewReportController {
    find(FilterDialog: (props: FilterDialogProps) => JSX.Element) {
        throw new Error("Method not implemented.");
    }
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    sortingTable = (reportColumn: ColumnProjects,index: number) => {
        return (
            <TableSortLabel
                data-testid={`sortDataId${index}`}
                IconComponent={reportColumn.label === "Action" ? undefined : TableSortIcon}
                onClick={() => { this.sortClick(reportColumn.key) }}
            >
                {reportColumn.label}
            </TableSortLabel>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes, navigation, handleSaveData} = this.props ;
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Box>
                    <Header
                        navigation={navigation}
                        handleSaveData={handleSaveData}
                        history={this.props.history}
                    />
                    <DeleteReportDialog handleDeleteAPI={this.handleDeleteReport} handleCancelButton={(event:any)=>this.handleCancelButton(event)} handleOpen={this.state.deleteReportDialoguebox} handleClose={this.state.cancelDeleteReportDialogbox}/>
                    <VendorListDialog
                        handleClose={this.handleCloseVendor}
                        handleOpen={this.state.vendorDilogueOpen}
                        title={configJSON.discrepancyByVendorTitle}
                        discrepancyArray={this.state.discrepancyArray}
                        filteredVendorList={this.state.filteredVendorList}
                        vendorSearchInputValue={this.state.vendorSearchInputValue}
                        handleSearch={this.handleSearchVendor}
                    />
                      <FilterDialog
                        classes={classes}
                        openDialog={this.state.openDialog}
                        StatusOptionsData={this.state.StatusOptionsData}
                        handleVendorChange={this.handleVendorChange}
                        vendorName={this.state.vendorName}
                        daterangeOptionsData={this.state.daterangeOptionsData}
                        handleDateChange={this.handleDateChange}
                        handleClose={this.handleClose}
                        handleOpen={this.handleOpen}
                        isCustomValue={this.state.isCustomValue}
                        sliderValue={this.state.sliderValue}
                        handleSliderChange={this.handleSliderChange}
                        statusValue={this.state.statusValue}
                        minNumberSilder={this.state.minNumberSilder}
                        maxNumberSilder={this.state.maxNumberSilder}
                        handleMinSliderChange={this.handleMinSliderChange}
                        handleMaxSliderChange={this.handleMaxSliderChange}
                        MinSliderVal={this.state.MinSliderVal}
                        MaxSliderVal={this.state.MaxSliderVal}
                        filterApplyHandler={this.filterApplyHandler}
                        handleFromDateChange={this.handleFromDateChange}
                        dateVal={this.state.dateVal}
                        endDateDisabledVal={this.state.endDateDisabledVal}
                        vendorListData={this.state.vendorListData}
                        handleToDateChange={this.handleToDateChange}
                        toDateVal={this.state.toDateVal}
                        resetAllHandler={this.resetAllHandler}
                        handleInvoiceSliderChange={this.handleInvoiceSliderChange}
                        sliderInvoiceValue={this.state.sliderInvoiceValue}
                        handleCheckboxChange={this.handleCheckboxChange}
                    />
                    
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        spacing={6}
                        style={webStyle.fontColor}
                    >
                        <Grid item xs={12} md={6} lg={6}>
                            {<ViewReportBreadCrumb classes={classes} project_name={this.state.project_name} />}
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}
                            container justifyContent="flex-end">
                            <NavLink activeStyle={{ textDecoration: 'none' }} style={webStyle.linkStyle} to="/report/create-report">
                                <DashboardNavNewCard
                                    className="a"
                                    img={create_new_invoice}
                                    imageStyle={webStyle.generateImgStyle}
                                    textStyle={webStyle.generateTextStyle}
                                    actionCardText={configJSON.createNewReport}
                                />
                            </NavLink>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ ...webStyle.totalDiscrepancyBoxStyle, marginTop: 20, marginLeft: 0, marginRight: 0, width: "100%" }}>
                        <Grid container xs={12} md={5} lg={5}>
                            <Box style={webStyle.firstGridStyle}>
                                <Box style={webStyle.totalDiscrepancyBoxStyle}>
                                    <Box style={this.state.isNoDataAvailable ? webStyle.noDataBoxStyle : { ...webStyle.dataBoxStyle }}>
                                        {this.state.loadingVendorData ? null : this.state.isNoDataAvailable ? <Box style={webStyle.firstBoxStyle}>
                                            <img src={no_data} width={300} height="auto" />
                                            <Typography style={webStyle.noDataTextBoldStyle}>{configJSON.noDataAvailable}</Typography>
                                            <Typography style={webStyle.noDataTextStyle}>
                                                {configJSON.createReportAndQuote}
                                            </Typography>
                                        </Box>
                                            :
                                            <ChartComponent series={this.state.series} formatter={this.formatterFn} />
                                        }
                                    </Box>
                                    {!this.state.isNoDataAvailable &&
                                        <AdditionalCardsSection totalInvoiceAmount={this.state.vendorChangeReportData?.total_invoice_amount} totalQuoteAmount={this.state.vendorChangeReportData?.total_quote_amount} />
                                    }
                                </Box>
                                { this.state.loadingVendorData ?  <Box style={webStyle.circularLoader}><CircularProgress /></Box> : null }
                            </Box>
                        </Grid>

                        <DiscrepancyByVendor  loadingVendorData={this.state.loadingVendorData} handleOpenVendor={this.handleOpenVendor} totalUniqueVendor={this.state.totalUniqueVendor} discrepancyArray={this.state.discrepancyArray} />

                    </Grid>
                    <Grid
                        container spacing={2}
                        justifyContent="space-between"
                        style={{ marginTop: 20, width: "100%", marginLeft: 0, marginRight: 0 }}
                    >
                        <Grid item sm={4}
                            className={classes.projectSearchBox}>
                            <Box className={classes.projectSearchIconBox}>
                                <CustomTextField
                                variant="outlined"
                                placeholder="Search reports..."
                                style={{ width: "92%" }}
                                data-test-id="search-input"
                                InputProps={{
                                 startAdornment: (
                                    <InputAdornment position="start">
                                       <SearchIcon />
                                    </InputAdornment>
                                )
                               }}
                               onChange={(event:any)=>this.handleSearchReport(event)}
                               value={this.state.reportSearchInputValue}
                               InputLabelProps={{
                                 style: { color: 'rgb(5, 5, 43)' } 
                              }}
                            />                            
                        </Box>
                            <TuneIcon onClick={this.handleOpen}/>
                        </Grid>
                        
                            <Box className={classes.downloadCsvBox}>
                              <Button>
                                <CSVLink data={this.getCsvData()} filename={"data.csv"}>
                                    <img src={exportCSV} /><Typography>{configJSON.downloadCSV}</Typography>
                                </CSVLink>
                            </Button>
                        </Box>
                    </Grid>
                    <TableContainer
                        style={webStyle.tableProjectsContainerStyle}>
                        <Table
                            className={classes.tableProjectBox} >
                            <TableHead>
                                <TableRow>
                                    {this.state.columnsProject.map((reportColumn, index) => (
                                        <TableCell
                                            key={reportColumn.id}
                                            style={webStyle.tableProjectsCellStyle}
                                            align="center"
                                        >
                                            {this.sortingTable(reportColumn,index)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody data-testid="tableBodyId" className={classes.styledTableBody}>
                                {this.state.isLoading ?
                                    <CircularProgress
                                        style={{ position: 'absolute', left: '55%', marginTop: '2%' }} />
                                    : <>
                                        {this.sortTableColumn()?.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                            .map((item: any, index: any) => {
                                                return (
                                                    <StyledTableRowItems
                                                        hover                                                    
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={index}
                                                    >
                                                        <TableCell
                                                            scope="row"
                                                            align="center"
                                                        >
                                                            <span>{item.idx}</span>
                                                        </TableCell>
                                                        <TableCell style={{maxWidth: '200px', textOverflow: 'ellipsis', overflow: 'hidden' }} scope="row"
                                                            padding="none" align="center">{item.report_name}</TableCell>
                                                        <TableCell align="center">{this.handleDateFormat(item.date_created)}</TableCell>
                                                        <TableCell style={{ color: this.handleCheckFileUpload(item.quote_uploaded), textTransform: "capitalize" }} align="center">
                                                            {item.quote_uploaded == "Yes" ? <Done /> : <Close />}
                                                        </TableCell>
                                                        <TableCell style={{ color: this.handleCheckFileUpload(item.invoice_uploaded), textTransform: "capitalize" }} align="center">
                                                            {item.invoice_uploaded == "Yes" ? <Done /> : <Close />}
                                                        </TableCell>

                                                        <TableCell align="center">{item.last_modified ? moment(item.last_modified).format('MM-DD-YYYY, hh:mm A') : "-"}</TableCell>
                                                                <TableCell className={classes.container} data-testid="paymodalOne"
                                                                 onClick={(event)=>{this.handleinvoicenoPopoverOpen(event,item.status_invoices)}}
                                                                 align="center" style={{color:this.invoiceNocolor(item),pointerEvents:this.invoiceEvent(item)}} >
                                                                    {item.no_of_inovices}
                                                                        </TableCell>
                                                                <StyledTableModal
                                                                id="popover-example"
                                                                open={Boolean(this.state.anchorimvoiceEl)}
                                                                anchorEl={this.state.anchorimvoiceEl}
                                                                style={{position:"fixed"}}
                                                                anchorOrigin={{
                                                                    vertical: "bottom",
                                                                    horizontal: "center"
                                                                  }}
                                                                  transformOrigin={{
                                                                    vertical: "top",
                                                                    horizontal: "center"
                                                                  }}
                                                                onClose={this.handleinvoicePopoverClose}
                                                                disableRestoreFocus
                                                                >
                                                                       
                                                                    {this.state.currentModelValue?.map((getPopvalue: [string, number] ,index) =>{
                                                                            return(
                                                                    <div style={{maxWidth:"300px",padding:'10px'}}>
                                                                        <Typography>
                                                                                 <span style={{fontWeight:500,fontSize:'14px',color:'#05052B'}}>Invoice {1+index} :</span>&nbsp;&nbsp;&nbsp;<span style={{fontWeight:500,fontSize:'14px',color:this.vaitingColorForapproval(getPopvalue[0])}}>{this.vaitingForapproval(getPopvalue[0])}</span>
                                                                        </Typography>
                                                                        
                                                                    </div>
                                                                            )
                                                                        })}
                                                                </StyledTableModal>
                                                            
                                                        <TableCell align="center">
                                                            $ {item.total_discrepancy}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <MoreVertIcon data-testid="iconId" onClick={(event: any) => this.handleOpenMenu(event, item.report_id,item)}/>
                                                            <StyledTableMenuItem
                                                                data-testid="styledTableMenuItemId"
                                                                id="simple-menu"
                                                                anchorEl={this.state.anchorEl}
                                                                keepMounted
                                                                open={Boolean(this.state.anchorEl)}
                                                                onClose={this.handleClickCloseMenu}
                                                            >
                                                                <MenuItem
                                                                    onClick={() => this.navigateToViewReport(item.report_id, item.report_name)}
                                                                    data-testid="TableMenuOptionsOpenerId">
                                                                    View Report Details
                                                                </MenuItem>
                                                                <MenuItem style={{color:"red"}} onClick={(event:any)=>this.handleDeleteDialogBox(event)} data-testid="TableMenuOptionsId">
                                                                    Delete report 
                                                                </MenuItem>
                                                            </StyledTableMenuItem>
                                                        </TableCell>
                                                    </StyledTableRowItems>
                                                )
                                            })}
                                    </>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                            <div style={{ marginBottom:"45px" }} ></div>
                    <TableFooter
                        reportProjects={this.state.reportList?.length}
                        page={this.state.page}
                        handleClickChangePage={this.handleClickOnChangePage}
                        rowsPerPage={this.state.rowsPerPage}
                        handleChangePerPage={this.handleChangeOnPerPage}
                        isLoading={this.state.isLoading}
                        classes={classes}
                    />

                </Box >
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    actionCardWebStyle: {
        backgroundColor: "#ffffff",
        height: "60px",
        border: "0px solid #39b68e",
        borderRadius: "16px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        cursor: 'pointer'
    },
    fontColor: {
        color: 'black',
        alignItems: "center"
    },
    uploadImage: {
        opacity: "0.655",
        backgroundImage:
            "linear-gradient(180deg, rgba(198,255,230,0.55) 0%, #96e0c8 100%)"
    },
    compareTextStyle: {
        color: " #4670b6"
    },

    generateImgStyle: {
        backgroundImage:
            "linear-gradient(180deg, #fefadb 0%, #ffeba8 78%, #ffe79a 100%)"
    },
    generateTextStyle: {
        color: "#df802d",
        paddingLeft: '20px',
        fontSize: '20px',
        paddingRight: '20px'
    },
    noDataContentstyle: {
        margin: 'auto',
        textAlign: 'center' as 'center',
        fontSize: '18px',
        marginTop: '5%',
        fontWeight: 550
    },
    uploadProjectImg: {
        opacity: "0.655",
        backgroundImage:
            "linear-gradient(180deg, rgba(198,255,230,0.55) 0%, #96e0c8 100%)"
    },

    compareText: {
        color: " #4670b6"
    },
    generateImgBox: {
        backgroundImage:
            "linear-gradient(180deg, #fefadb 0%, #ffeba8 78%, #ffe79a 100%)"
    },
    linkStyle: {
        textDecoration: 'none',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            textDecoration: 'none'
        }
    },
    tableProjectsContainerStyle: {
        marginTop: '2%',
        overflowX: "auto" as "auto"
    },
    tableProjectsCellStyle: {
        fontSize: "15px",
        fontWeight: 550,
        paddingLeft: 30,
    },
    generateContent: {
        color: " #df802d"
    },
    navLinkProjectStyle: {
        textDecoration: 'none',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            textDecoration: 'none'
        }
    },

    navProjectActionCard: {
        borderRadius: "16px",
        height: "100px",
        border: "0px solid #39b68e",
        backgroundColor: "#ffffff",
        width: "100%",
        alignItems: "center",
        cursor: 'pointer',
        padding: '0 60px'
    },

    firstGridStyle: {
        width: '100%', padding: '3%', background: '#fff', borderRadius: '2%', border: '1px solid rgb(223, 219, 219)'
    },
    totalDiscrepancyBoxStyle: {
        display: 'flex', justifyContent: 'space-between'
    },
    noDataBoxStyle: {
        width: '100%'
    },
    dataBoxStyle: {
        width: '50%'
    },
    noDataTextStyle: {
        marginTop: '3%'
    },
    contentStyle: {
        display: 'flex'
    },
    noDataTextBoldStyle: {
        marginTop: '3%',
        fontSize: '18px',
        fontWeight: 550
    },
    cardMainBoxStyle: {
        display: 'flex', flexDirection: 'column' as 'column', gap: '30px', marginTop: '10%'
    },
    cardStyle: {
        minWidth: '50%', borderRadius: '10px'
    },
    cardTextStyle: {
        fontSize: '18px',
        fontWeight: 550
    },
    firstBoxStyle: { textAlign: 'center' as 'center', marginTop: '3%' },
    titleFontStyle: {
        fontSize: '20px',
        fontWeight: 500
    },
    circularLoader: {  
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    circularLoader2: {  
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 50,
        alignItems: 'center'
    },

    viewUnderStyle: {
        fontWeight: 500,
        textDecoration: "underline",
        color: '#87888F'
    },

    viewTextStyle: {
        color: "orange",
        fontWeight: 500,
    }
}

export { ViewReport }
export default withStyles(ProjectsTableStyle)(ViewReport)


// Customizable Area End
