// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { setStorageData } from "../../../framework/src/Utilities";

import React from "react";
// import pdfjs from 'pdfjs-dist';

export const configJSON = require("./config");


interface InvoiceDescription {
  id: number;
  created_at: string;
  updated_at: string;
  description: string;
  ord_quantity: number;
  price: string;
  amount: string;
  report_invoice_id: number;
  order_unit: string;
  tax: any;
  invoice_category_name: any;
  part_number: any;
  invoice_category_subtotal: any;
  equipment: any;
  minimum: any;
  day: any;
  week: any;
  month: any;
}

interface InvoiceAttributes {
  id: number;
  quote_id: string;
  report_id: number;
  date: string;
  vendor: string;
  project: string;
  status: any; 
  total_price: string;
  invoice_supplier_address: string;
  invoice_salesperson_name: string;
  invoice_supplier_contact: string;
  invoice_site_name: string;
  invoice_site_address: string;
  invoice_site_person: string;
  invoice_site_contact: string;
  invoice_subtotal: string;
  invoice_s_and_h_charges: string;
  invoice_tax: string;
  invoice_other_charges: string;
  discount: string;
  url_id: string;
  invoice_terms: string;
  invoice_shipped_date: string;
  invoice_tax_percent: string;
  invoice_rental_subtotal: string;
  created_at: string;
  updated_at: string;
}

interface InvoiceData {
  id: string;
  type: string;
  attributes: InvoiceAttributes;
}

interface Invoice {
  data: InvoiceData;
}

interface InvoiceObject {
  message: string;
  pdf_page_count: number;
  url: string;
  invoice: Invoice;
  invoice_description: InvoiceDescription[];
  invoice_rental_description: any[];
}


export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  classes: any;
  projectName:any;
  reportName:any;
  vendorItems:any;
  history: any;
  location: any;
  handleSaveData: () => {},
  goBackButtonNavigation: any,
  quoteUrl: any,
  invoiceUrl: any,
  quoteData: any,
  invoiceData: any,
  quotePdfpage: any,
  invoicePdfPage: any,
  handleInvoiceUpload: any,
  uploadAcceptedFile: any,
  acceptedFile: any,
  pdfUrl: any
  handleDelete: any
  handleQuoteUpload: any
  segmentName1: any;
  segmentTotal1: any;
  segmentName2: any;
  segmentTotal2: any;
  quoteCategoryDescription: any;
  projectId: any;
  reportId: any;
  invoiceURLs: string[];
  quoteName: any;
  // iframeRef: React.RefObject<any>
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // quoteComparisonData: any,
  // invoiceComparisonData: any,
  quotesTabs: any,
  reportName:any,
  invoiceTabs: any,
  quoteValue: any
  invoiceValue: any
  numPagesQuote: any,
  pageNumberQuote: any,
  numPagesInvoice: any,
  pageNumberInvoice: any,
  toggleValue: any,
  resultReportTab: any,
  tabheadervalue: any,
  quotePdfUrl: any;
  invoicePdfUrl: any;
  quoteItems: any;
  invoiceItems: any;
  columns: any;
  invoicePage: any;
  quotePage: any;
  minPage: any;
  series: any;
  options: any;
  discrepancyMsg: any;
  discrepancyMsgColor: any;
  fullViewScreen: any;
  halfViewScreen: any;
  segements: any;
  invoiceSegements: any;
  discrepancyCheck: any;
  multipleSegmentPage:any;
  updated_table_data:any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ReportResultsController extends BlockComponent<
  Props,
  S,
  SS
> {
  deleteReportApiId: string = "";
  createNotificationId: string = ""
  constructor(props: Props) {
    super(props);


    this.state = {
      // Customizable Area Start
      // quoteComparisonData: this.props.location?.state?.quoteComparisonData,
      // invoiceComparisonData: this.props.location?.state?.idState?.lenghInvoice,
      quotesTabs: this.props.quoteName,
      reportName:this.props.reportName,
      invoiceTabs: this.props.uploadAcceptedFile,
      quoteValue: 0,
      invoiceValue: 0,
      numPagesQuote: this.props.quotePdfpage,
      numPagesInvoice: this.props.invoicePdfPage,
      pageNumberQuote: 1,
      pageNumberInvoice: 1,
      toggleValue: false,
      resultReportTab: [
        {
          id: 1,
          type: "Upload Quote"
        },
        {
          id: 2,
          type: "Review Quote Details"
        },
        {
          id: 3,
          type: "Upload Invoice"
        },
        {
          id: 4,
          type: "Review Invoice Details"
        },
        {
          id: 5,
          type: "Results"
        },
      ],
      tabheadervalue: 4,
      quotePdfUrl: this.props.quoteUrl,
      invoicePdfUrl: this.props.invoiceUrl,
      quoteItems: this.props.quoteData,
      invoiceItems: this.props.invoiceData,
      columns: [
        {
          id: 'Sr.no', label: 'Sr. No',
        },
        {
          id: 'description', label: 'Description',
        },
        {
          id: 'OrderQty.', label: 'Order Qty.',
        },
        {
          id: 'unit', label: 'Unit',
        },
        {
          id: 'unitPrice', label: 'Unit Price',
        },
        {
          id: 'Ext. Price', label: 'Ext. Price'
        }
      ],
      quotePage: 1,
      invoicePage: 1,
      minPage: 1,
      series: [0, 0],
      options: {
        colors: ["#f7635c", "#e6e669eb"],
        labels: ["Prepared <B>73%</B>", "Received <B>49%</B>"],
        legend: {
          show: false,
          showForSingleSeries: false,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: "right",
          horizontalAlign: "center",
          floating: false,
          fontSize: "14px",
          fontFamily: "Helvetica, Arial",
          width: undefined,
          height: undefined,
          formatter: undefined,
          offsetX: 0,
          offsetY: 50,
          labels: {
            colors: undefined,
            useSeriesColors: false
          },
          stroke: {
            colors: ['#ff0000'], // Set the default color of the tracks
            lineCap: 'round',
            width: 10,
          },
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0,
          },
          itemMargin: {
            horizontal: 5,
            vertical: 5
          },
          onItemClick: {
            toggleDataSeries: false
          },
          onItemHover: {
            highlightDataSeries: false
          }
        },
        plotOptions: {
          radialBar: {
            size: undefined,
            inverseOrder: false,
            startAngle: 0,
            endAngle: 360,
            offsetX: -20,
            offsetY: 50,
            hollow: {
              margin: 0,
              size: "65%",
              background: "transparent",
              image: undefined,
              imageWidth: 150,
              imageHeight: 150,
              imageOffsetX: 0,
              imageOffsetY: 0,
              imageClipped: true,
              position: "front",
              dropShadow: {
                enabled: false,
                top: 0,
                left: 0,
                blur: 3,
                opacity: 0.5
              }
            },
            track: {
              show: true,
              startAngle: undefined,
              endAngle: undefined,
              background: ["#16164d", "#D8D8D8"],
              strokeWidth: "98%",
              opacity: 1,
              margin: 0,
              dropShadow: {
                enabled: false,
                top: 0,
                left: 0,
                blur: 3,
                opacity: 0.5
              }
            },
            dataLabels: {
              show: false,
              name: {
                show: false,
                fontSize: "22px",
                fontFamily: undefined,
                color: undefined,
                offsetY: -10
              },
              value: {
                show: true,
                fontSize: "16px",
                fontFamily: undefined,
                color: undefined,
                offsetY: 16
              }
            }
          }
        }
      },
      discrepancyMsg: "You are good!",
      discrepancyMsgColor: "green",
      fullViewScreen: "",
      halfViewScreen: "",
      segements: true,
      invoiceSegements: true,

      discrepancyCheck: false,
      multipleSegmentPage:1,
      updated_table_data:[],
      // Customizable Area End
    };

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async componentDidMount(): Promise<void> {
    this.discrepancyCalculation()

    if(this.props.history.location?.state?.updated_table_data){
      this.setState({
        updated_table_data:this.props.history.location?.state?.updated_table_data
      })
    }
  }

  discrepancyCalculation = () => {
    this.setState((prevState) => ({ discrepancyCheck: !prevState.discrepancyCheck }))
    const totalPrice1 = this.props.invoiceData.length > 0 ? this.props.invoiceData.reduce((sumno: number, objvaldata: {invoice:{data:{attributes:{total_price:string}}}}) => sumno + (parseFloat(objvaldata?.invoice?.data?.attributes?.total_price) || 0), 0) : 0;
    const discrepancy = parseFloat(totalPrice1) - parseFloat(this.props.quoteData.quotedetails.total_price)
    const sum = parseFloat(totalPrice1) + parseFloat(this.props.quoteData.quotedetails.total_price)
    if (discrepancy > 0) {
      const per = Math.abs((discrepancy / sum) * 100)
      const formattedNumber = per.toFixed(1);
      this.setState({ series: [per, 0] })
      this.setState({ discrepancyMsg: `Overcharged ${formattedNumber}%` })
      this.setState({ discrepancyMsgColor: "#f7635c" })

    }
    else {
      if (discrepancy === 0) {
        this.setState({ series: [0, 0] })
        this.setState({ discrepancyMsgColor: "green" })

      }
      else {
        if (discrepancy < 0 && this.props.invoiceData.length > 0) {
          const per = Math.abs((discrepancy / this.props.quoteData.quotedetails.total_price) * 100)
          const formattedNumber = per.toFixed(1);
          this.setState({ series: [0, per] })
          this.setState({ discrepancyMsg: `Undercharged ${formattedNumber}%` })
          this.setState({ discrepancyMsgColor: "#bcbc49cc" })
        }
      }
    }
  }


  onDocumentLoadSuccess = ({ numPages }: any) => {
    this.setState({ numPagesQuote: numPages })
  };

  loadSuccess = ({ numPages }: any) => {
    this.setState({ numPagesInvoice: numPages })
  }


  handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ invoiceValue: newValue, multipleSegmentPage:1 })
    this.setState({ pageNumberInvoice: 1 })
  }



  handlePrevPageQuotePdf = () => {
    const { pageNumberQuote, minPage } = this.state;
    if (pageNumberQuote > minPage) {
      this.setState({ pageNumberQuote: pageNumberQuote - 1 });
    }
  };

  handleNextPageQuotePdf = () => {
    const { pageNumberQuote, numPagesQuote } = this.state;
    if (pageNumberQuote < this.props.quotePdfpage) {
      this.setState({ pageNumberQuote: pageNumberQuote + 1 });
    }
  };
  handlePrevPageInvoicePdf = () => {
    const { pageNumberInvoice, minPage } = this.state;
    if (pageNumberInvoice > minPage) {
      this.setState({ pageNumberInvoice: pageNumberInvoice - 1 });
    }
  };

  handleNextPageInvoicePdf = () => {
    const { pageNumberInvoice, invoiceItems } = this.state;
    if (pageNumberInvoice < this.props.invoiceData[this.state.invoiceValue].pdf_page_count) {
      this.setState({ pageNumberInvoice: pageNumberInvoice + 1 });
    }
  };

  pdfFormToggleChangeHandler = () => {
    this.setState((prevState) => ({ toggleValue: !prevState.toggleValue }))
  }

  handleInvoicePageChange = (event: unknown, newPage: number) => {
    this.setState({ invoicePage: newPage })
  };

  handleQuotePageChange = (event: unknown, newPage: number) => {
    this.setState({ quotePage: newPage })
  };

  handleEditChangePage = (event: React.ChangeEvent<unknown>, page: number, columnId: number) =>{
    const updatedTablesData = [...this.props.quoteCategoryDescription];
    updatedTablesData[columnId].pagination.page = page;
    this.setState({ quotePage: updatedTablesData });

}
 

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.deleteReportApiId) {
        this.handleDeleteReportApiCallResponse(responseJson)
      }


    }
  }

  handleReportDelete = () => {
    const header = {
      "token": document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1]
    };

    const requestReportGenerateMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteReportApiId = requestReportGenerateMessage.messageId;
    requestReportGenerateMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteReportEndPoint}${this.props.invoiceData.length > 0 ? this.props.invoiceData[this.state.invoiceValue].invoice.data.attributes.report_id : this.state.quoteItems.quotedetails.report_id}`
    );

    requestReportGenerateMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestReportGenerateMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestReportGenerateMessage.id, requestReportGenerateMessage);

    return true;
  }

  handleDeleteReportApiCallResponse = (responseJson: any) => {
    if (responseJson && responseJson.message) {
      this.props.history.push('/DashboardWeb')
    }
  }


  NavigateToProjectDashboard = () => {

    const projectNameValue = this.props.vendorItems.filter((val:any)=> val.id === this.props.projectName);
   
    const dataValue = {id:this.props.quoteData.quotedetails.report_id,project:projectNameValue[0]?.project,reportName:this.props.reportName};
    setStorageData("dataViewreport",JSON.stringify(dataValue))
    this.props.history.push('/ViewReportDetails')
    const header = {
      "Content-Type": configJSON.fileUploadApiContentType,
      "token": document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1]
    };

 
    const totalInvoicePrice = this.props.invoiceData.reduce((acc:number,curr:InvoiceObject)=>{
      return acc + parseFloat(curr.invoice.data.attributes.total_price)
    },0)

    const httpBody = {
      report_id: this.props.quoteData.quotedetails.report_id ? this.props.quoteData.quotedetails.report_id : this.props.reportId,
      project_id: this.props.projectId,
      quote_subtotal: this.props.quoteData.quotedetails.total_price ? this.props.quoteData.quotedetails.total_price : 0,
      invoice_subtotal: this.props.invoiceData.length > 0 ? totalInvoicePrice : 0
    };

    const replyNotificationMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createNotificationId = replyNotificationMessage.messageId;

    replyNotificationMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createNotificationEndpoint
    );

    replyNotificationMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    replyNotificationMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    replyNotificationMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.fileUploadApiMethod
    );

    runEngine.sendMessage(replyNotificationMessage.id, replyNotificationMessage);

    return true;
  }

  handleQuoteResize = () => {
    if (this.state.fullViewScreen == "") {
      this.setState({ halfViewScreen: "none", fullViewScreen: "block" })
    }
    else {
      this.setState({ halfViewScreen: "", fullViewScreen: "" })
    }
  }

  handleInvoiceResize = () => {
    if (this.state.fullViewScreen == "") {
      this.setState({ halfViewScreen: "block", fullViewScreen: "none" })
    }
    else {
      this.setState({ halfViewScreen: "", fullViewScreen: "" })
    }
  }

  handleDiscrepancyCal = () => {
    if (this.state.quoteItems.quotedetails.total_price) {
      return "N/A"
    }
    else {
      return "$ 0"
    }
  }

  calculateDiscrepancy = (invoiceMetaData: any, quoteItems: any) => {
    if (this.props.invoiceData.length > 0) {
      let totalPrice1 = 0
      if(this.props.invoiceData[0]?.totalValue){
        totalPrice1 = this.props.invoiceData.reduce((sum: number,item: { totalValue: any; })=>{
          return sum + Number(item.totalValue)
        },0)
      }else{
        totalPrice1 = this.props.invoiceData.reduce((sums: number, objvalue: {invoice:{data:{attributes:{total_price:string}}}}) => sums + (parseFloat(objvalue?.invoice?.data?.attributes?.total_price) || 0), 0);
      }
      const totalPrice2 = parseFloat(quoteItems.total_price) || 0;
      const discrepancy = Math.abs(totalPrice1 - totalPrice2);
      const formattedNumber = discrepancy.toFixed(2);
      if (this.state.discrepancyCheck) {
        this.discrepancyCalculation()
      }
      return `$ ${formattedNumber}`;
    }
  }

  handleTotalQuoteAmount = () => {
    if (this.props.quoteData?.quotedetails?.total_price) {
      return this.props.quoteData?.quotedetails?.total_price
    }
    else {
      return 0
    }
  }

  handleTotalInvoiceAmount = () => {
    const { invoiceData } = this.props;

    if (invoiceData.length === 0) {
      return 0;
    }
  
    const totalPrice = invoiceData.reduce((sum: number, item: any) => {
      const totalPrice = item.editQuoteDetail?.total_price || item.invoice?.data?.attributes?.total_price || 0;
      return sum + parseFloat(totalPrice);
    }, 0);
  
    return totalPrice;
  }

  handleQuoteAndInvoiceValidation = () => {
    if (this.props.invoiceData.length > 0 && this.handleTotalInvoiceAmount() && this.handleTotalQuoteAmount()) {
      return true
    }
    else {
      return false
    }

  }

  handleInvoiceData = () => {
    if (this.props.invoiceData.length > 0 && this.props.invoiceData[this.state.invoiceValue].editQuoteDetail) {
      return this.props.invoiceData[this.state.invoiceValue].editQuoteDetail
    }
  }

  handleGoback = () => {
    if (this.props.invoiceData.length > 0) {
      this.props.goBackButtonNavigation(4, 3)
    }
    else {
      this.props.goBackButtonNavigation(4, 2)
    }
  }

  handleFileDrop = (files: any) => {
    this.props.handleInvoiceUpload(files)
  }

  handleMultipleSegmentPage = (event: unknown, newPage: number) => {
    this.setState({ multipleSegmentPage: newPage })
  };


}

// Customizable Area End
