// Customizable Area Start
import React from "react";
import { NavLink } from "react-router-dom";
import { styled } from '@material-ui/styles'
import {
    Box, Button, Badge, DialogTitle,
    Dialog,
    DialogContent, TextField,
    InputLabel,
    Typography
} from "@material-ui/core";
import Popover from '@material-ui/core/Popover';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import NotificationPopupController, { configJSON } from "./NotificationPopupController.web";
import moment from "moment";
import DoneAllIcon from '@material-ui/icons/DoneAll';

const NotificationMenuOptionsBlock = styled(Box)({
    '&:after, &:before': {
        position: "absolute",
        bottom: "100%",
        left: "52%",
        border: "solid transparent",
        height: 0,
        width: 0,
        pointerEvents: "none",
        content: "''",
    },
    position: "relative",
    marginTop: "21px",
    border: "1px solid #f3f1f1",
    background: "#fff",
    marginBottom: "20px",
    '&:before': {
        borderWidth: "9px",
        borderColor: "rgba(204, 204, 204, 0)",
        borderBottomColor: "#d1d1d1",
        marginLeft: "-9px",
    },
    '&:after': {
        borderWidth: "8px",
        borderBottomColor: "#fff",
        borderColor: "rgba(136, 183, 213, 0)",
        marginLeft: "-8px",
    }
});

const ViewNotificationBlock = styled(Box)({
    '&:after, &:before': {
        position: "absolute",
        bottom: "100%",
        left: "85%",
        border: "solid transparent",
        height: 0,
        width: 0,
        pointerEvents: "none",
        content: "''",
    },
    position: "relative",
    marginTop: "21px",
    border: "1px solid #f3f1f1",
    background: "#fff",
    marginBottom: "20px",
    '&:before': {
        borderWidth: "16px",
        borderColor: "rgba(204, 204, 204, 0)",
        borderBottomColor: "#d1d1d1",
        marginLeft: "-16px",
    },
    '&:after': {
        borderWidth: "15px",
        borderBottomColor: "#161635",
        borderColor: "rgba(136, 183, 213, 0)",
        marginLeft: "-15px",
    },
    '& .MuiBox-root': {
        maxHeight: '50vh',
        overflow: 'auto'
    }
});

const SmallPopupBlock = styled(Popover)({
    '& .MuiPaper-root.MuiPopover-paper': {
        overflowY: 'hidden',
        boxShadow: 'none !important',
        width: '13%',
        height: '40%',
        backgroundColor: 'transparent',
    }
});
const PopupBlock = styled(Popover)({
    '& .MuiPaper-root.MuiPopover-paper': {
        overflowY: 'hidden',
        boxShadow: 'none',
        width: '26%',
        backgroundColor: 'transparent',
        marginLeft: '4%'
    }
});

const StyledBox = styled(Box)({
    '&:last-child': {
        borderBottom: 'none !important',
    }
});
const SmallPopupButton = styled(Button)({
    textTransform: "inherit",
    fontWeight: 500,
    display: 'block',
})
const SaveButtonStyle = styled(Button)({
    "&.MuiButton-root": {
        borderRadius: "6px",
        color: "#fff",
        background: "#05052B",
        textTransform: 'inherit',
        fontFamily:"Poppins",
        fontWeight:500,
        fontSize:"12px",
        lineHeight:"18px",
        padding:"15px"
    },
    "&.Mui-disabled": {
        background: "#2929467d",
    },
})

const StyledCancelButton = styled(Button)({
    "&.MuiButton-root": {
        borderRadius: "4px",
        border: "1px solid #292946",
        color: "#292946",
        width: '50%',
        textTransform: 'capitalize'
    }
})

const StyledSaveButton = styled(Button)({
    "&.MuiButton-root": {
        borderRadius: "4px",
        color: "#fff",
        background: "#292946",
        width: '50%',
        textTransform: 'capitalize'
    }
})

const NotificationPopupHeadingText = styled(Typography)({
    fontFamily:"Poppins",
    fontWeight:500,
    fontSize:"16px",
    lineHeight:"24px",
    color:"#FFFFFF"
})

const MiddleLineBox = styled(Box)({
    display:"flex",
    justifyContent:"center"
})

const MiddleLine = styled(Box)({
    width:"90%",
    borderBottom:"1px solid #BEBEBE"
})

export const AddProjectNameDialog = (props: any) => {
    const { title, type, handleClose, handleOpen, handleInputChange, inputName, addProject, addNameErrMsg } = props

    return (
        <>
            <Dialog style={{ padding: '10px' }} data-testid="dialogOpenId" onClose={handleClose} open={handleOpen}>
                <DialogTitle style={{ fontWeight: 550, textAlign: 'center' }}>{title}</DialogTitle>
                <DialogContent>
                    <Box style={{ width: '100%' }}>
                        <InputLabel style={{ textTransform: 'capitalize', color: 'black', fontSize: '16px' }}>{`${type} name`}</InputLabel>
                        <TextField autoComplete="off" error={addNameErrMsg?.length > 0} helperText={addNameErrMsg} value={inputName}  onChange={handleInputChange} style={webStyle.helperText} id="outlined-basic" variant="outlined" placeholder={`Enter ${type} name`}  FormHelperTextProps={{style:{marginLeft:"0px"}}}/>
                    </Box>
                    <Box style={webStyle.buttonBox}>
                        <StyledCancelButton variant='outlined' onClick={handleClose}>Cancel</StyledCancelButton>
                        <StyledSaveButton variant='contained' onClick={addProject}>Save</StyledSaveButton>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export const NotificationListData = ({ item, handleClickPopover, handleReplyNotification, markAsReadNotificationReplyApi, smallPopoverOpen, SmallEnchor, handleClosePopover, handleDeleteAll, handleCloseFunction, props, setState, redirectToReportDetails, selectedItem, handleReportText,notificationListIndex,goToReport }: any) => {
    return (
        <>
            {item.data?.slice(0, 40).map((items: any, index: any) => (

                <React.Fragment key={index}>
                    <StyledBox key={index} style={!items.attributes.is_read ? webStyle.notificationListStyle : webStyle.notificationListLeftExtraSpace} data-testid="NotificationListDataBox">
                        <Box style={{ display: 'flex', gap: '4%', alignItems: 'baseline' }}>
                            <FiberManualRecordIcon style={{ color: !items.attributes.is_read ? 'green' : 'transparent', fontSize: '12px' }} />
                            <Box>
                                <p style={webStyle.headingTextStyle}>{items.attributes.headings}
                                </p>
                                <p style={webStyle.contentTextStyle}>{handleReportText && handleReportText(items.attributes.contents)} <span style={{ color: items.attributes.overcharged ? 'red' : 'orange' }}>{(items.attributes.undercharged || items.attributes.overcharged) ? `$${items.attributes.overcharged || items.attributes.undercharged}` : ''}</span>
                                </p>
                            </Box>
                        </Box>
                        <div style={webStyle.contentBodyTextStyle}>

                            <MoreVertIcon data-testid={`moreIcon${index}`} aria-describedby={items.id} onClick={(e) => handleClickPopover(e, items,items.attributes.go_to_report)} />
                            <SmallPopupBlock
                                id={items.id}
                                open={smallPopoverOpen}
                                anchorEl={SmallEnchor}
                                onClose={handleClosePopover}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center"
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center"
                                }}
                            >
                                <>
                                    {goToReport ? <NotificationMenuOptionsBlock style={webStyle.iconDropdownStyle}>
                                        <SmallPopupButton data-testid={`replyButtonId${index}`} onClick={markAsReadNotificationReplyApi}>Mark as read</SmallPopupButton>
                                        <SmallPopupButton data-testid={`deleteButtonId${index}`} onClick={handleDeleteAll}>Remove</SmallPopupButton>
                                        <SmallPopupButton data-testid={`deleteButtonId${index}`} onClick={redirectToReportDetails}>{configJSON.GotoreportTitle}</SmallPopupButton>
                                    </NotificationMenuOptionsBlock>:<NotificationMenuOptionsBlock style={webStyle.iconDropdownStyle}>
                                        <SmallPopupButton data-testid={`replyButtonId${index}`} onClick={markAsReadNotificationReplyApi}>Mark as read</SmallPopupButton>
                                        <SmallPopupButton data-testid={`deleteButtonId${index}`} onClick={handleDeleteAll}>Remove</SmallPopupButton>
                                    </NotificationMenuOptionsBlock>}
                                </>
                            </SmallPopupBlock>
                        </div>
                    </StyledBox>
                    <MiddleLineBox>
                    {items.attributes.go_to_report ? <MiddleLine/> : null}
                    </MiddleLineBox>
                </React.Fragment>))}
        </>
    )
}

export const NotificationData = ({ notificationList, convertDates, handleClickPopover, handleReplyNotification, markAsReadNotificationReplyApi, smallPopoverOpen, SmallEnchor, handleDeleteAll, handleClosePopover, handleCloseFunction, props, setState, redirectToReportDetails, selectedItem, handleReportText,goToReport }: any) => {
    return (
        <Box>
            {notificationList?.map((item: any, index: number) => {
                return (
                    <React.Fragment key={index}>
                        <NotificationListData
                            data-testid="notificationListRenderId"
                            item={item}
                            handleClickPopover={handleClickPopover}
                            smallPopoverOpen={smallPopoverOpen}
                            SmallEnchor={SmallEnchor}
                            handleClosePopover={handleClosePopover}
                            handleCloseFunction={handleCloseFunction}
                            props={props}
                            setState={setState}
                            handleReplyNotification={handleReplyNotification}
                            handleDeleteAll={handleDeleteAll}
                            markAsReadNotificationReplyApi={markAsReadNotificationReplyApi}
                            redirectToReportDetails={redirectToReportDetails}
                            selectedItem={selectedItem}
                            handleReportText={handleReportText}
                            notificationListIndex={index}
                            goToReport={goToReport}
                        />
                    </React.Fragment>
                )
            })}
        </Box>
    )
}

class NotificationPopup extends NotificationPopupController {
    render() {
        return (
            <div >
                <AddProjectNameDialog addNameErrMsg={this.state.addNameErrMsg} addProject={this.handleAddProject} inputName={this.state.inputName} handleInputChange={this.handleInputChange} title={`Add a new project`} type="project" handleClose={this.handleClose} handleOpen={this.state.openAddProjectDialog} />
                <Badge
                    data-testid="badgeNotificationIcon"
                    badgeContent={this.state.badgeCount}
                    color="error"
                    overlap="circular"
                    max={99}
                    onClick={(e: any) => this.handleClick(e)}
                >
                    <NotificationsNoneIcon
                        data-testid="btnNotificationIcon"
                        aria-describedby={this.state.id}
                        style={webStyle.pointerStyle}
                    />
                </Badge>
                <PopupBlock
                    id={`${this.state.id}`}
                    open={this.state.open}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleCloseFunction}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                >
                    <ViewNotificationBlock style={webStyle.containerPopupStyle}>
                        <Box style={webStyle.h3HeadStyle}>
                            <NotificationPopupHeadingText>Notification</NotificationPopupHeadingText>
                            <DoneAllIcon data-testid='allReadId' style={{ cursor: 'pointer' }} onClick={() => this.markAsReadNotificationReplyApi("All")} />
                        </Box>
                        {this.state.apiError || this.state.notificationList.length == 0 ?
                            <p data-testid="pTagId" style={webStyle.ErrorContentTextStyle}>
                                {this.state.serverSideError ? "No notifications found." : "No new notifications yet!"}
                            </p> :
                            <div data-testid="notificationdataid">
                                <NotificationData
                                    notificationList={this.state.notificationList}
                                    convertDates={this.convertDates}
                                    handleClickPopover={this.handleClickPopover}
                                    smallPopoverOpen={this.state.smallPopoverOpen}
                                    SmallEnchor={this.state.SmallEnchor}
                                    handleClosePopover={this.handleClosePopover}
                                    handleCloseFunction={this.handleCloseFunction}
                                    props={this.props}
                                    setState={this.setState}
                                    handleReplyNotification={this.handleReplyNotification}
                                    handleDeleteAll={this.handleDeleteAll}
                                    markAsReadNotificationReplyApi={this.markAsReadNotificationReplyApi}
                                    redirectToReportDetails={this.redirectToReportDetails}
                                    selectedItem={this.state.selectedItem}
                                    handleReportText={this.handleReportText}
                                    goToReport={this.state.goToReport}
                                />

                            </div>
                        }
                        <div style={webStyle.navlinkDivStyle}>
                            <SaveButtonStyle variant='contained' onClick={this.handleOpenDialog}>Add a new project</SaveButtonStyle>
                        </div>
                    </ViewNotificationBlock>
                </PopupBlock>
            </div >
        );
    }
}
// Customizable Area End
export default NotificationPopup
// Customizable Area Start
const webStyle = {
    containerPopupStyle: {
        background: "#fff",
        border: "1px solid #00000080",
        borderRadius: "12px",
        height: "100%",
        position: 'relative' as 'relative',
    },
    pointerStyle: {
        cursor: 'pointer'
    },
    clickHereStyle: {
        color: '#df802d',
    },
    contentBodyTextStyle: {
        display: 'flex',
        justifyCcontent: 'center',
        alignItems: 'center',
    },
    h3HeadStyle: {
        color: 'white',
        borderBottom: '1px solid #161635',
        margin: '0',
        padding: "17px",
        background: '#161635',
        borderTopRightRadius: '10px',
        borderTopLeftRadius: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    h4HeadStyle: {
        margin: '8px 0 0',
        padding: "0 15px",
    },
    notificationListStyle: {
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'space-between',
        position: 'relative' as 'relative',
        padding: '10px',
    },
    helperText: {
        marginTop: '1%',
        '& p': {
            '&.MuiFormHelperText-contained': {
                marginLeft: '0px'
            }
        },
    },
    buttonBox: {
        marginTop: '15%', gap: '20px', width: '100%', display: 'flex', justifyContent: 'space-between', paddingBottom: '5%'
    },
    ErrorContentTextStyle: {
        margin: '0',
        fontSize: '14px',
        width: '100%',
        padding: '15px'
    },
    contentTextStyle: {
        marginTop: '3%',
        fontSize: '14px',
        width: '100%',
        fontFamily:"Poppins",
        fontWeight:400,
        lineHeight:"21px",
        marginBottom:"9px"
    },
    headingTextStyle: {
        fontWeight: 500,
        fontSize: '14px',
        fontFamily:"Poppins",
        lineHeight:"21px",
        color:"#05052B",
        marginTop:"8px"
    },
    ListGetTimeStyle: {
        color: '#939191',
    },
    spanBudgeStyle: {
        background: '#df802d',
        borderRadius: '3px',
        fontSize: '10px',
        fontWeight: 600,
        color: '#fff',
        padding: '3px 10px',
        marginLeft: '10px'
    },
    iconDropdownStyle: {
        background: "#fff",
        border: "1px solid rgb(243 241 241)",
        borderRadius: 4,
        position: 'absolute' as 'absolute',
        top: '0 !important',
        zIndex: 9,
        right: 'unset !important',
        left: '0 !important',
        padding: '10px',
        fontSize: '12px',
        width: '100%'
    },
    contentFootStyle: {
        textDecoration: "none",
        padding: "15px 0px",
        color: '#df802d',
        fontWeight: 600,
        width: '100%',
        fontSize: '14px',
        textAlign: 'center' as 'center',
    },
    navlinkDivStyle: {
        padding: '10px 0px 36px 36px',
    },
    notificationListLeftExtraSpace: {
        justifyContent: 'space-between',
        position: 'relative' as 'relative',
        padding: '10px 10px 10px 3px',
        display: 'flex',
        alignItems: 'start',     
       },
};
// Customizable Area End