// Customizable Area Start
import React from "react";
import {
    Box, Switch,
    Grid, Button, Checkbox, Modal,
    Typography, TextField, Table, TableHead, TableRow, TableCell, Breadcrumbs, Link, TableBody, Tabs, Tab
} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { withStyles } from "@material-ui/core/styles";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import Header from "../../landingpage/src/Header.web";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import EditInvoiceScanResultController, {
    Props, TableHeadProps, AddSegmentBoxProps,
    NoItemBoxProps, QuoteTableHeadProps, DeleteIconProps,
    AddSegmentButtonProps, QuoteDateBoxProps, QuoteSiteBoxProps,
    EditTableCellProps, QuantityTableCellProps, TaxTableRowProps,
    QuoteExpiryDateBoxProps, TaxDraggedTableCellProps, TaxwiseTableCellProps,
    DeleteButtonProps, UnitTableCellProps, PriceTableCellProps, configJSON,PriceTableCellNewProps
} from "./EditInvoiceScanResultController.web";
import { deletePng } from "./assets";
import DeleteModal from "./DeleteModal.web";
import EditRentalInvoice from "../../MatchAlgorithm/src/EditRentalInvoice.web";
// Customizable Area End

// Customizable Area Start
export const TableHeadData = (props: TableHeadProps) => {
    const { editQuoteColumn, addNewSegmentState, categoryBox, handleDragDataSelectAllItemClick, data, selected, itemTaxData } = props
    const numSelected = selected.length

    return (
        <TableHead >
            <TableRow >
                {addNewSegmentState && categoryBox && <TableCell padding="checkbox" data-testid="tableheadtestid">
                    <Checkbox
                        data-testid="selectDragAllItemTestid"
                        onChange={handleDragDataSelectAllItemClick}
                        checked={data.length > 0 && numSelected === data.length}
                        indeterminate={numSelected > 0 && numSelected < data.length}
                    />
                </TableCell>}
                {editQuoteColumn.map((column) => (
                    <>
                        {
                            !itemTaxData && column.id == 'tax' ? null
                                : <TableCell
                                    key={column.id}
                                >
                                    {column.label}
                                </TableCell>
                        }
                    </>
                ))}
            </TableRow>
        </TableHead>
    )
}

export const QuoteTableHead = (props: QuoteTableHeadProps) => {
    const { addNewSegmentState, categoryBox, classes, columnId, handleSelectAllItemClick, selected, itemTaxData } = props
    const numSelected = selected.length;
    return (
        <TableHead>
            {addNewSegmentState && categoryBox && <TableCell padding="checkbox" style={{ textAlign: 'center' }}>
                <Checkbox
                    onChange={(event: any) => { handleSelectAllItemClick(event, columnId) }}
                    data-testid={"selectAllItemTestid"}
                    checked={selected.length > 0 && numSelected === selected.length}
                    indeterminate={numSelected > 0 && numSelected < selected.length}
                />
            </TableCell>}
            <TableCell className={classes.editSrNoInput}>{'Sr No.'}</TableCell>
            <TableCell className={classes.editInput}>{'Description'}</TableCell>
            <TableCell className={classes.editunitContent}>{'Order Qty.'}</TableCell>
            <TableCell className={classes.editunitContent}>{'Unit'}</TableCell>
            <TableCell className={classes.editPriceContent}>{'Unit Price'}</TableCell>
            {itemTaxData && <TableCell className={classes.editunitContent}>{'Tax (%)'}</TableCell>}
            <TableCell>{'Ext Price'}</TableCell>
        </TableHead>
    )
}

export const AddSegmentBox = (props: AddSegmentBoxProps) => {
    const { addNewSegmentState, classes, addNewItemRow, subTotalValue, columnId } = props
    let newSubtotalValue = typeof subTotalValue === 'number' ? subTotalValue.toFixed(2) : parseFloat(subTotalValue).toFixed(2)

    
    return (
        <>
            {
                addNewSegmentState ?

                    <Box className={classes.addSegmentBox}>
                        <Button onClick={() => addNewItemRow(columnId)} data-testid={`addNewItemRowTestId-${columnId}`}><AddCircleOutlineIcon />{configJSON.addNewItem}</Button>

                        <Box className={classes.editsubTotalBox}>
                            <Typography>{configJSON.invoiceCategoryTotal}</Typography>
                            <Typography>$ {newSubtotalValue}</Typography>
                        </Box>
                    </Box> :

                    <Box className={classes.addSegmentBox}>
                        <Button onClick={() => addNewItemRow(columnId)} data-testid={`addNewItemRowId-${columnId}`}><AddCircleOutlineIcon />{configJSON.addNewItem}</Button>

                        <Box className={classes.editsubTotalBox}>
                            <Typography>{configJSON.segmentContent}</Typography>
                            <Typography>$ {newSubtotalValue}</Typography>
                        </Box>
                    </Box>
            }
        </>
    )
}

export const NoItemBox = (props: NoItemBoxProps) => {
    const { classes, data } = props
    return (
        <>
            <Box className={classes.quoteDragBox}><Typography>{data}</Typography>
                <Typography>{configJSON.invoiceBoxTextBold}</Typography>
            </Box>
        </>
    )
}

export const DeleteButton = (props: DeleteButtonProps) => {
    const { addNewSegmentState, addCategory, handleInvoiceCategoryChange, columnId, classes, deleteInvoiceCategoryModal, categoryErrorMessage } = props
    return (
        <Box>
            {
                addNewSegmentState &&
                <Box className={classes.categoryErrorBox}>
                    <Box className={classes.addNewSegmentBox}>
                        <Typography className={classes.addNewSegmentContent}>{configJSON.invoiceCategory}</Typography>
                        <TextField
                            placeholder={configJSON.invoiceEnterCategory}
                            id="category_name"
                            value={addCategory}
                            data-testid="categoryid"
                            onChange={(event) => handleInvoiceCategoryChange(event, columnId)} />
                        <img src={deletePng} alt="deletePng" onClick={() => { deleteInvoiceCategoryModal(columnId) }} data-testid="deleteIconTestid" />
                    </Box>
                    <Box>
                        {categoryErrorMessage && (<Typography className={classes.errorBox}>{categoryErrorMessage}</Typography>)}
                    </Box>
                </Box>
            }
        </Box>
    )
}

export const DeleteIcon = (props: DeleteIconProps) => {
    const { addNewSegmentState, categoryBox, editquoteData, deleteOpenTableItemModal } = props
    return (
        <>
            {
                !addNewSegmentState && !categoryBox && <TableCell><img src={deletePng} alt="deletePng" onClick={deleteOpenTableItemModal} data-testid={`deleteItemTestid-${editquoteData.id}`} /></TableCell>
            }
        </>
    )
}

export const AddSegmentButton = (props: AddSegmentButtonProps) => {
    const { selected, addNewSegmentHandler, classes, deleteOpenModal } = props
    return (
        <Box>
            {selected && <Button className={classes.deleteButton} data-testid="deleteModalTestid" onClick={deleteOpenModal}>{configJSON.deleteSelectedButton}</Button>}
            <Button onClick={addNewSegmentHandler} data-testid="addNewsegmentid"><AddCircleOutlineIcon />{configJSON.newSegment}</Button>
        </Box>
    )
}


export const QuoteDateBox = (props: QuoteDateBoxProps) => {
    const { editQuoteDetail, classes, typeOfFocused, handleDate, handleEditQuoteDetail } = props
    const dateVal = moment(editQuoteDetail.invoice_shipped_date).format("MM-DD-YYYY")
    return (
        <>
            {
                editQuoteDetail.invoice_shipped_date == null ? <TextField className={classes.quotedateContent} placeholder={configJSON.enterDatePlaceholder}  type={typeOfFocused || "text"} onFocus={handleDate} id="invoice_shipped_date" data-testid='dateTestid1' onChange={(e) => handleEditQuoteDetail('invoice_shipped_date', e.target?.value)} /> :
                    <TextField value={dateVal} onFocus={handleDate} data-testid='dateTestid' id="invoice_shipped_date" onChange={(e) => handleEditQuoteDetail('invoice_shipped_date', e.target?.value)} />
            }
        </>
    )
}



export const QuoteSiteBox = (props: QuoteSiteBoxProps) => {
    const { editQuoteDetail, handleEditQuoteDetail } = props
    return (
        <>
            {
                editQuoteDetail.invoice_site_contact == null ? <TextField value={"Unknown"} id="unknown" onChange={(e) => handleEditQuoteDetail('unknown', e.target.value)} /> : <TextField value={editQuoteDetail.invoice_site_contact} id="site_contact" onChange={(e) => handleEditQuoteDetail('invoice_site_contact', e.target.value)} />
            }
        </>
    )
}

export const QuoteExpiryDateBox = (props: QuoteExpiryDateBoxProps) => {
    const { editQuoteDetail, classes, typeOfFocused, handleDate, handleEditQuoteDetail } = props
    const expiryDateVal = moment(editQuoteDetail.date).format("MM-DD-YYYY")
    return (
        <>
            {
                editQuoteDetail.date == null ?
                    <TextField className={classes.quotedateContent} placeholder={configJSON.enterDatePlaceholder} type={typeOfFocused || "text"} onFocus={handleDate} id="date" data-testid="expiryDateTestid" onChange={(e) => handleEditQuoteDetail("date", e.target.value)} /> :
                    <TextField value={expiryDateVal} id="date" onChange={(e) => handleEditQuoteDetail("date", e.target.value)} onFocus={handleDate} data-testid="expiryDateTestid" />
            }
        </>
    )
}

export const InvoiceTermsBox = (props: QuoteExpiryDateBoxProps) => {
    const { editQuoteDetail, classes, typeOfFocused, handleDate, handleEditQuoteDetail } = props

    return (
        <>
            {
                editQuoteDetail.invoice_terms == null ?
                    <TextField
                        className={classes.quotedateContent}
                        placeholder={configJSON.enterDatePlaceholder}
                        type={typeOfFocused || "text"} onFocus={handleDate}
                        id="invoice_terms" data-testid="invoiceTermsTestid"
                        onChange={(e) => handleEditQuoteDetail('invoice_terms', e.target.value)} /> :
                    <TextField
                        value={editQuoteDetail.invoice_terms}
                        id="invoice_terms"
                        onChange={(e) => handleEditQuoteDetail("invoice_terms", e.target.value)}
                        onFocus={handleDate} data-testid="invoiceTermsTestid" />
            }
        </>
    )
}

export const TaxDraggedTableCell = (props: TaxDraggedTableCellProps) => {
    const { itemTaxData, selected, classes, editquoteDragIdData, editquoteDragData, handleEditQuoteDragDescription } = props
    const isSelected = (name: string) => selected.indexOf(name) !== -1;
    const isItemSelected = isSelected(editquoteDragIdData);

    return (
        <>
            {
                itemTaxData &&
                <>
                    {
                        editquoteDragData === null ?
                            <TableCell className={isItemSelected && classes.editUnitPriceBox}>
                                <NumericFormat
                                    value={`0`}
                                    id="tax"
                                    suffix=" %"
                                    onValueChange={(values) => handleEditQuoteDragDescription(values.floatValue, Number(editquoteDragIdData), 'tax')}
                                    customInput={TextField}
                                />
                            </TableCell>
                            : <TableCell className={isItemSelected && classes.editUnitPriceBox}>
                                <NumericFormat
                                    value={editquoteDragData}
                                    data-testid="taxDragid"
                                    id="tax"
                                    suffix="%"
                                    onValueChange={(values) => handleEditQuoteDragDescription(values.floatValue, Number(editquoteDragIdData), 'tax')}
                                    customInput={TextField}
                                />
                            </TableCell>
                    }
                </>

            }
        </>
    )
}

export const TaxwiseTableCell = (props: TaxwiseTableCellProps) => {
    const { itemTaxData, classes, editquoteIdData, editquoteData, selected, handleEditQuoteDescription, columnId, editQuoteDataDes } = props
    const isSelected = (name: string) => selected.indexOf(name) !== -1;
    const isItemSelected = isSelected(editquoteIdData);
    return (
        <>
            {
                itemTaxData &&
                <>
                    {
                        editquoteData === null ?
                            <TableCell className={isItemSelected && classes.editUnitPriceBox}>
                                <NumericFormat
                                    value={editQuoteDataDes && `0`}
                                    data-testid="taxid"
                                    id="tax"
                                    suffix=" %"
                                    onValueChange={(values) => handleEditQuoteDescription(values.floatValue, columnId, editquoteIdData, 'tax')}
                                    customInput={TextField}
                                />
                            </TableCell>
                            : <TableCell className={isItemSelected && classes.editUnitPriceBox}>
                                <NumericFormat
                                    value={editquoteData}
                                    data-testid="taxid"
                                    id="tax"
                                    suffix="%"
                                    onValueChange={(values) => handleEditQuoteDescription(values.floatValue, columnId, editquoteIdData, 'tax')}
                                    customInput={TextField}
                                />
                            </TableCell>
                    }
                </>

            }
        </>
    )
}

export const EditTableCell = (props: EditTableCellProps) => {
    const { selected, classes, editquoteData, editquoteIdData, handleEditQuoteDescription, columnId } = props

    const isSelected = (name: string) => selected.indexOf(name) !== -1;
    const isItemSelected = isSelected(editquoteIdData);

    return (
        <TableCell
            className={isItemSelected ? classes.editInputBox : classes.editInput}>
            <TextField data-testid="descriptionid" id="description" value={editquoteData}
                onChange={(e) => handleEditQuoteDescription(e, columnId, editquoteIdData, 'description')}
            /></TableCell>
    )
}

export const QuantityTableCell = (props: QuantityTableCellProps) => {
    const { selected, classes, editquoteIdData, editquoteData, handleEditQuoteDescription, columnId } = props
    const isSelected = (name: string) => selected.indexOf(name) !== -1;
    const isItemSelected = isSelected(editquoteIdData);
    return (
        <TableCell className={isItemSelected ? classes.editQuantityInputBox : classes.editunitContent}>
            <NumericFormat
                value={editquoteData}
                id="ord_quantity"
                onChange={(e) => handleEditQuoteDescription(e, columnId, editquoteIdData, 'ord_quantity')}
                data-testid="ord_quantityid"
                style={{ width: '100%', height: '62px' }}
            />
        </TableCell>
    )
}

export const TaxTableRow = (props: TaxTableRowProps) => {
    const { classes, itemTaxData } = props
    return (
        <TableRow>
            <TableCell>
                <Checkbox
                    id="checkBox"
                />
            </TableCell>
            <TableCell className={classes.idBox}>{'1'}</TableCell>
            <TableCell className={classes.editInput}><TextField /></TableCell>
            <TableCell className={classes.editunitContent}><TextField /></TableCell>
            <TableCell className={classes.editunitContent}><TextField /></TableCell>
            <TableCell><TextField /></TableCell>
            {
                itemTaxData && <TableCell><TextField /></TableCell>
            }
            <TableCell><TextField /></TableCell>
        </TableRow>
    )
}

export const UnitTableCell = (props: UnitTableCellProps) => {
    const { classes, editquoteData, selected, handleEditQuoteDescription, editquoteIdData, columnId } = props
    const isSelected = (name: string) => selected.indexOf(name) !== -1;
    const isItemSelected = isSelected(editquoteIdData);
    return (
        <TableCell className={isItemSelected ? classes.editQuantityInputBox : classes.editunitContent}>
            <TextField data-testid="unitid" id="unit" value={editquoteData} onChange={(e) => handleEditQuoteDescription(e, columnId, editquoteIdData, 'order_unit')} />
        </TableCell>
    )
}

export const documentOcra11yProps = (indextab: number) => {
    return {
        id: `simple-tab-${indextab}`,
        'aria-controls': `simple-tabpanel-${indextab}`,
    };
}




export const TableRowData = (props: any) => {
    const { addNewSegmentState, categoryBox, classes, columnId, selected, editquoteIdData, handleRowItemClick } = props
    const isSelected = (name: string) => selected.indexOf(name) !== -1;
    const isItemSelected = isSelected(editquoteIdData);
    return (
        <>
            {
                addNewSegmentState && categoryBox &&
                <TableCell padding="checkbox" className={classes.checkBox}>
                    <Checkbox
                        id="checkBox"
                        checked={isItemSelected}
                        onClick={(event) => { handleRowItemClick(event, columnId, editquoteIdData) }}
                        data-testid={`dataItemClickId-${columnId}`}
                    />
                </TableCell>
            }
        </>
    )
}

export const PriceTableCell = (props: PriceTableCellProps) => {
    const { classes, editquotePriceData, selected, handleEditQuoteDescription, editquoteIdData, columnId } = props
    
    const isSelected = (id: string) => selected.indexOf(id) !== -1;
    const isItemSelected = isSelected(String(editquoteIdData));

    return (
        <TableCell className={isItemSelected ? classes.editUnitPriceBox : classes.editUnitPricewidthBox}>
            <NumericFormat
                data-testid="priceid"
                id="price"
                value={editquotePriceData}
                prefix="$ "
                thousandSeparator=","
                decimalSeparator="."
                decimalScale={4}
                onValueChange={(values) => handleEditQuoteDescription(values.floatValue, columnId, editquoteIdData, 'price')}
                customInput={TextField}
            />
        </TableCell>
    )
}


export const PriceTableCellNew = (props:PriceTableCellNewProps)=>{
    const {classes,isItemSelected,price,handleEditQuoteDescription,calculateAllDataTotal,columnId,editquoteDataId} = props;

    return <TableCell className={isItemSelected ? classes.editUnitPriceBox : classes.editUnitPricewidthBox}>
    <NumericFormat
        data-testid="priceid"
        id="price"
        value={price}
        prefix="$ "
        thousandSeparator=","
        decimalSeparator="."
        decimalScale={4}
        onValueChange={(values) =>{ 
            handleEditQuoteDescription(values.floatValue, columnId, editquoteDataId, 'price')
            setTimeout(()=>{
                calculateAllDataTotal()
            },1000)    
        }}
        customInput={TextField}
    />
</TableCell>

}


// Customizable Area End

class EditInvoiceScanResult extends EditInvoiceScanResultController {

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes, navigation, handleSaveData } = this.props
        const { editQuoteDetail, addNewSegmentState, categoryBox } = this.state
        const isSelected = (name: string) => this.state.selected.indexOf(name) !== -1;
        const selectedData = this.state?.tablesData?.some((item => item.selected.length > 0))
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Header navigation={navigation} handleSaveData={handleSaveData} history={this.props.history} />

                <Box style={{ padding: "30px 0" }}>
                    <Box className={classes.breadcrumbStyle}>
                        <Breadcrumbs
                            separator={">"}
                            aria-label="breadcrumb"

                        >
                            <Link color="inherit" style={{ textDecoration: "underline" }} href="/DashboardWeb">
                                Dashboard
                            </Link>
                            <Link color="inherit" style={{ textDecoration: "underline" }} onClick={this.cancelEditHandler} >
                                Create a new report
                            </Link>

                            <Typography style={{ color: "orange" }}>{configJSON.invoiceTitle}</Typography>
                        </Breadcrumbs>
                    </Box>
                </Box>
                <Tabs
                    value={this.state.activeTab}
                    onChange={this.handleChangeTab}
                    className={classes.invoiceInnerBox2} data-testid="tabChangeid">
                    {this.state.invoiceTabs?.slice(0, 8)?.map((data) => {
                        return (
                            <Tab
                                label={
                                    <>
                                        <Box className={classes.invoicecurveBox}>
                                            <Box className={classes.invoicecurveInnerBox}></Box>
                                        </Box>
                                        <Typography variant="subtitle2" className={classes.invoiceTabData}>{data.name}</Typography>
                                        <Box className={classes.invoicecurveRightBox}>
                                            <Box className={classes.curveRightinvoiceInnerBox}></Box>
                                        </Box>
                                    </>
                                }
                                {...documentOcra11yProps(1)}
                                className={classes.invoiceTitleBox}
                            />
                        )
                    })
                    }
                </Tabs>

                <Box className={classes.editquoteScanBox}>
                    <Box className={classes.itemWiseTaxBox}>
                        <Typography variant="h5" className={classes.editquoteTitle}>{configJSON.invoiceDetails}</Typography>
                        <Box className={classes.editTaxContentBox}>
                            <Switch
                                checked={this.state.itemTaxData}
                                onChange={this.handleTaxfieldChange}
                                name="checkedA"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                data-testid="handleTaxfieldChange"
                            />
                            <Typography>{configJSON.invoiceIosSwitch}</Typography>
                        </Box>
                    </Box>
                    <Box className={classes.editquoteDeatilBox}>
                        <Grid container spacing={2}>
                            <>
                                <Grid item sm={4}>
                                    <Box>
                                        <Typography variant="h6" className={classes.editquotedetailsContent}>{configJSON.invoiceDetails}</Typography>
                                        <Typography>{configJSON.invoiceNumberContent}</Typography>
                                        <TextField value={editQuoteDetail?.quote_id} id="quote_id" onChange={(e) => this.handleEditQuoteDetail('quote_id', e.target.value)} />
                                        <Typography>{configJSON.shipDate}</Typography>

                                        <QuoteDateBox handleDate={this.handleDate} handleEditQuoteDetail={this.handleEditQuoteDetail} editQuoteDetail={this.state.editQuoteDetail} classes={classes} typeOfFocused={this.state.typeOfFocused} />


                                        <Typography>{configJSON.invoiceDateContent}</Typography>
                                        <QuoteExpiryDateBox handleDate={this.handleDate} handleEditQuoteDetail={this.handleEditQuoteDetail} editQuoteDetail={this.state.editQuoteDetail} classes={classes} typeOfFocused={this.state.typeOfFocused} />

                                        <Typography>{configJSON.invoiceTerms}</Typography>
                                        <InvoiceTermsBox
                                            handleDate={this.handleDate}
                                            handleEditQuoteDetail={this.handleEditQuoteDetail}
                                            editQuoteDetail={this.state.editQuoteDetail}
                                            classes={classes}
                                            typeOfFocused={this.state.typeOfFocused}
                                        />

                                    </Box>
                                </Grid>
                                <Grid item sm={4}>
                                    <Box>
                                        <Typography variant="h6" className={classes.editquotedetailsContent}>{configJSON.supplierdetailsContent}</Typography>
                                        <Typography>{configJSON.supplierNameContent}</Typography>
                                        <TextField value={editQuoteDetail?.project} id="project" onChange={(e) => this.handleEditQuoteDetail('project', e.target.value)} />
                                        <Typography>{configJSON.supplierAddressContent}</Typography>
                                        <TextField value={editQuoteDetail?.invoice_supplier_address} id="supplier_address" onChange={(e) => this.handleEditQuoteDetail('invoice_supplier_address', e.target.value)} />
                                        <Typography>{configJSON.salesPersonTitle}</Typography>
                                        <TextField value={editQuoteDetail?.invoice_salesperson_name} id="supplier_salesperson" onChange={(e) => this.handleEditQuoteDetail('invoice_salesperson_name', e.target.value)} />
                                        <Typography>{configJSON.supplierContactContent}</Typography>
                                        <TextField value={editQuoteDetail?.invoice_supplier_contact} id="supplier_contact" onChange={(e) => this.handleEditQuoteDetail('invoice_supplier_contact', e.target.value)} />
                                    </Box>
                                </Grid>
                                <Grid item sm={4}>
                                    <Box>
                                        <Typography variant="h6" className={classes.editquotedetailsContent}>{configJSON.projectdetailsContent}</Typography>
                                        <Typography>{configJSON.siteNameContent}</Typography>
                                        <TextField value={editQuoteDetail?.invoice_site_name} id="invoice_site_name" onChange={(e) => this.handleEditQuoteDetail('invoice_site_name', e.target.value)} />
                                        <Typography>{configJSON.siteAddressContent}</Typography>
                                        <TextField value={editQuoteDetail?.invoice_site_address} id="site_address" onChange={(e) => this.handleEditQuoteDetail('invoice_site_address', e.target.value)} />
                                        <Typography>{configJSON.contactPersonTitle}</Typography>
                                        <TextField value={editQuoteDetail?.invoice_site_person} id="site_contactperson" onChange={(e) => this.handleEditQuoteDetail('invoice_site_person', e.target.value)} />
                                        <Typography>{configJSON.siteContactContent}</Typography>
                                        <QuoteSiteBox editQuoteDetail={this.state.editQuoteDetail} handleEditQuoteDetail={this.handleEditQuoteDetail} />
                                    </Box>
                                </Grid>
                            </>
                        </Grid>
                    </Box>

                    <Box>
                        <Box className={classes.addSegmentBox}>
                            <Typography variant="h6" className={classes.editquotedetailsContent}>{configJSON.itemdetailsContent}</Typography>
                            <AddSegmentButton selected={selectedData} classes={classes} addNewSegmentHandler={this.addNewSegmentHandler} deleteOpenModal={this.deleteOpenModal} deleteCloseModal={this.deleteCloseModal} />
                        </Box>
                        
                                <DragDropContext
                                    onDragEnd={this.onDragEnd}
                                    data-testid="DragDropContextTestid">

                                    {this.state.tablesData.map((column, columnId) => {
                                        const { page, rowsPerPage } = column.pagination;
                                        const begin = (page - 1) * rowsPerPage;
                                        const end = Math.min(begin + rowsPerPage, column.data.length);
                                        return (

                                                <Box key={columnId}
                                                    className={
                                                        column.data.length == 0 ?
                                                            classes.edititemtableRedBox : classes.edititemtableBox
                                                    }
                                                >
                                                    <DeleteButton
                                                        classes={classes}
                                                        categoryErrorMessage={column.categoryErrorMessage}
                                                        handleDeleteSegmentBox={this.handleDeleteSegmentBox}
                                                        addNewSegmentState={this.state.addNewSegmentState}
                                                        addCategory={column.invoice_category_name}
                                                        handleInvoiceCategoryChange={this.handleInvoiceCategoryChange}
                                                        deleteInvoiceCategoryModal={this.deleteInvoiceCategoryModal}
                                                        columnId={column.id}
                                                        data-testid={`DeleteButtonId-${columnId}`}
                                                    />
                                                    <Table>
                                                        <Droppable
                                                            droppableId={columnId.toString()}
                                                            key={columnId}
                                                            data-testid={`droppableTestId-${columnId}`}
                                                        >
                                                            {(provided, snapshot) => {
                                                                return (
                                                                    <div className={classes.tableHeadBox}>
                                                                        {
                                                                            column.data.length > 0 &&

                                                                            <QuoteTableHead
                                                                                addNewSegmentState={addNewSegmentState}
                                                                                categoryBox={categoryBox}
                                                                                handleSelectAllItemClick={this.handleSelectAllItemClick}
                                                                                selected={column.selected}
                                                                                classes={classes}
                                                                                columnId={column.id}
                                                                                data-testid={`quotetableheadId-${columnId}`}
                                                                                itemTaxData={this.state.itemTaxData}
                                                                            />
                                                                        }

                                                                        <TableBody
                                                                            {...provided.droppableProps}
                                                                            ref={provided.innerRef}
                                                                            className={classes.edittableBodyBox}
                                                                            data-testid={`tableBodyTestId`}
                                                                        >
                                                                            {
                                                                                column.data.length == 0 && column.id !== 1 ?
                                                                                    (
                                                                                        <NoItemBox classes={classes} data={column.content} />
                                                                                    ) : (
                                                                                        <>
                                                                                            <div style={{ display: 'contents' }}>
                                                                                                {column?.data.length !== 0 ? column.data.slice(begin, end).map((editquoteData, index: number) => {
                                                                                                    const isItemSelected = isSelected(editquoteData?.id);
                                                                                                    return (
                                                                                                        <Draggable
                                                                                                            index={index}
                                                                                                            key={editquoteData.id.toString()}
                                                                                                            draggableId={editquoteData.id.toString()}
                                                                                                            data-testid={`draggableTestId-${editquoteData.id}`}
                                                                                                        >
                                                                                                            {(provided, snapshot) => {
                                                                                                                return (
                                                                                                                    <TableRow
                                                                                                                        ref={provided.innerRef}
                                                                                                                        {...provided.draggableProps}
                                                                                                                        {...provided.dragHandleProps}
                                                                                                                        hover
                                                                                                                        key={editquoteData.id.toString()}
                                                                                                                        data-testid="tablerowid"

                                                                                                                    >
                                                                                                                        <TableRowData
                                                                                                                            data-testid={`TableRowDataTestId`}
                                                                                                                            classes={classes}
                                                                                                                            addNewSegmentState={this.state.addNewSegmentState}
                                                                                                                            categoryBox={this.state.categoryBox}
                                                                                                                            handleRowItemClick={this.handleRowItemClick}
                                                                                                                            selected={column.selected}
                                                                                                                            editquoteIdData={editquoteData.id}
                                                                                                                            columnId={column.id}
                                                                                                                        />
                                                                                                                        <TableCell className={classes.idBox}>{begin + index + 1}</TableCell>

                                                                                                                        <EditTableCell selected={this.state.selected} columnId={column.id} classes={classes} editquoteData={editquoteData.description} editquoteIdData={editquoteData.id} handleEditQuoteDescription={this.handleEditQuoteDescription} />
                                                                                                                        <QuantityTableCell selected={this.state.selected} classes={classes} columnId={column.id} editquoteData={editquoteData.ord_quantity} editquoteIdData={editquoteData.id} handleEditQuoteDescription={this.handleEditQuoteDescription} />

                                                                                                                        <UnitTableCell classes={classes} selected={this.state.selected} editquoteData={editquoteData.order_unit} editquoteIdData={editquoteData.id} columnId={column.id} handleEditQuoteDescription={this.handleEditQuoteDescription} />

                                                                                                                        <PriceTableCellNew classes={classes} isItemSelected={isItemSelected} price={editquoteData.price} handleEditQuoteDescription={this.handleEditQuoteDescription}
                                                                                                                          calculateAllDataTotal={this.calculateAllDataTotal} columnId={column.id} editquoteDataId={editquoteData.id} />
                                                                                                                        <TaxwiseTableCell classes={classes} selected={this.state.selected} editquoteData={editquoteData.tax} columnId={column.id} editquoteIdData={editquoteData.id} itemTaxData={this.state.itemTaxData} handleEditQuoteDescription={this.handleEditQuoteDescription} editQuoteDataDes={editquoteData.description} />
                                                                                                                        <TableCell className={isItemSelected && classes.editUnitPriceBox}>
                                                                                                                            <NumericFormat
                                                                                                                                id="amount"
                                                                                                                                data-testid="amountid"
                                                                                                                                value={editquoteData.amount}
                                                                                                                                prefix="$ "
                                                                                                                                thousandSeparator=","
                                                                                                                                decimalSeparator="."
                                                                                                                                decimalScale={4}
                                                                                                                                onValueChange={(values) =>{ 
                                                                                                                                    this.handleEditQuoteDescription(values.floatValue, column.id, editquoteData.id, 'amount')
                                                                                                                                    setTimeout(()=>{
                                                                                                                                        this.calculateAllDataTotal()
                                                                                                                                    },1000)
                                                                                                                                }}
                                                                                                                                customInput={TextField}
                                                                                                                            />
                                                                                                                        </TableCell>
                                                                                                                        <DeleteIcon classes={classes} editquoteData={editquoteData.id} addNewSegmentState={this.state.addNewSegmentState} categoryBox={this.state.categoryBox} deleteOpenTableItemModal={() => this.deleteOpenTableItemModal(editquoteData?.id)} data-testid="deleteIconId" />
                                                                                                                    </TableRow>
                                                                                                                );
                                                                                                            }}
                                                                                                        </Draggable>
                                                                                                    );

                                                                                                }) : <TaxTableRow classes={classes} itemTaxData={this.state.itemTaxData} />}

                                                                                            </div>
                                                                                        </>)

                                                                            }
                                                                            {provided.placeholder}
                                                                        </TableBody>

                                                                    </div>
                                                                );
                                                            }}

                                                        </Droppable>
                                                    </Table>


                                                    < Pagination
                                                        count={Math.ceil(column.data.length / column.pagination.rowsPerPage)}
                                                        variant="outlined"
                                                        shape="rounded"
                                                        page={column.pagination.page}
                                                        data-testid={`editpaginationTestId-${columnId}`}
                                                        onChange={(event, page) => this.handlePageChange(event, page, columnId)}
                                                    />
                                                    <AddSegmentBox
                                                        data-testid={`AddSegmentBoxId-${columnId}`}
                                                        addNewSegmentState={addNewSegmentState}
                                                        editQuoteDetail={editQuoteDetail}
                                                        subTotalValue={this.state.tablesData[columnId].subtotalValue ? this.state.tablesData[columnId].subtotalValue: 0}
                                                        columnId={columnId}
                                                        classes={classes}
                                                        addNewItemRow={this.addNewItemRow}
                                                    />
                                                </Box>

                                             
                                        )


                                    })}
                                </DragDropContext>


                    </Box>

                    <Box className={classes.editquoteTotalAmountBox}>
                        <Box>
                            <Box className={classes.editSubTotalContentBox}>
                                <Typography>{configJSON.subTotalContent}</Typography>
                                <Typography>$ {Number(this.state.calculateValue).toFixed(2)}</Typography>
                            </Box>
                            <Box className={classes.editSubTotalContentBox}>
                                <Typography>{configJSON.chargesContent}</Typography>
                                <NumericFormat
                                    id="s_and_h_charges"
                                    value={editQuoteDetail?.invoice_s_and_h_charges}
                                    prefix="$ "
                                    onValueChange={(values) => this.handleEditQuoteDetail('invoice_s_and_h_charges', values.floatValue)}
                                    customInput={TextField}
                                    data-testid="s_and_h_chargesid"
                                />
                            </Box>
                            <Box className={classes.editSubTotalContentBox}>
                                <Typography>{configJSON.otherchargesContent}</Typography>
                                <NumericFormat
                                    id="other_charges"
                                    value={editQuoteDetail?.invoice_other_charges}
                                    prefix="$ "
                                    onValueChange={(values) => this.handleEditQuoteDetail('invoice_other_charges', values.floatValue)}
                                    customInput={TextField}
                                    data-testid="other_chargesid"
                                />
                            </Box>

                            <Box className={classes.editSubTotalContentBox}>
                                <Typography>{configJSON.discountText}</Typography>
                                <NumericFormat
                                    id="discount"
                                    value={editQuoteDetail?.discount}
                                    prefix="$ "
                                    onValueChange={(values) => this.handleEditQuoteDetail('discount', values.floatValue)}
                                    customInput={TextField}
                                    data-testid="discountid"
                                />
                            </Box>
                            <Box className={classes.edittaxContent}>
                                <Typography className={classes.quoteDetailTitle}>{configJSON.taxContent}</Typography>
                                <Box className={classes.edittaxInputBox}>
                                    <NumericFormat
                                        id="tax_rate_amount"
                                        value={editQuoteDetail?.invoice_tax_percent}
                                        suffix=" %"
                                        onValueChange={(values) => this.handleEditQuoteDetail('invoice_tax_percent', values.floatValue)}
                                        customInput={TextField}
                                        data-testid="tax_rate_amountid"
                                    />
                                    <NumericFormat
                                        id="tax"
                                        value={this.totalTax()}
                                        prefix="$ "
                                        onValueChange={(values) => this.handleEditQuoteDetail('invoice_tax', values.floatValue)}
                                        customInput={TextField}
                                        data-testid="taxInputid"
                                    />

                                </Box>
                            </Box>
                            <Box className={classes.editSubTotalContentBox}>
                                <Typography className={classes.edittotalPrice}>{configJSON.totalContent}</Typography>
                                <Typography>$ {this.state.totalValue}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box >

                <Box className={classes.cancelSaveButton}>
                    <Button onClick={this.cancelEditHandler} data-testid="cancelTestid">
                        {configJSON.invoiceCancel}
                    </Button>
                    <Button onClick={this.editQuoteDetailApi} data-testid="invoiceSaveTestid">
                        {configJSON.invoiceSave}
                    </Button>
                </Box>

                <Modal
                    open={this.state.deleteModalState}
                    onClose={this.deleteCloseModal}
                >
                    <DeleteModal
                        openModal={this.state.deleteModalState}
                        handleClose={this.deleteCloseModal}
                        DeleteItemsHandler={this.DeleteItemsHandler}
                        deleteHandler={this.deleteHandler}
                        selectedItem={this.state.selected}
                        classes={classes}
                        data-testid="deleteModalId"
                        deleteCategory={this.deleteInvoiceCategory} />
                </Modal>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const editQuoteScanResultStyle = () => ({
    editquoteScanBox: {
        background: '#fff',
        borderRadius: '10px',
        padding: '50px 25px 50px 50px',
        '& p': {
            color: '#0a0019',
            fontWeight: 500,
            margin: '15px 0 5px',
        },
        '& .MuiInput-underline::before': {
            display: 'none'
        },
        '& .MuiInput-underline::after': {
            display: 'none'
        },
        '& .MuiTable-root': {
            borderCollapse: 'separate',
            borderSpacing: '8px'
        },
        '& .MuiTableContainer-root': {
            overflowX: 'unset',
        },
        '& .MuiTableCell-root': {
            borderBottom: 'none',
            padding: 0
        },
        '& .MuiTableCell-head': {
            paddingLeft: 0,
            fontWeight: 600,
        },
        '& .MuiCheckbox-colorSecondary.Mui-checked': {
            color: '#000'
        }
    },
    editquotedateContent: {
        '& input': {
            color: '#9f9292',
            fontWeight: 500,
        },
        "input[type='date']::before": {
            content: 'attr(placeholder)',
            position: 'absolute',
            color: '#999999',
        },
        "input[type='date']": {
            color: '#ffffff',
        },

        "input[type='date']:focus": {
            color: '#666666',
        },
        "input[type='date']:valid": {
            color: "#666666",
        },
        "input[type='date']:valid::before": {
            content: '',
        },
        "input[type='date']:focus::before": {
            content: '',
        },
    },
    editquoteTitle: {
        color: '#000',
        fontWeight: 600
    },
    editquoteDeatilBox: {
        paddingBottom: '20px',
        '& .MuiFormControl-root': {
            width: '95%',
            marginBottom: '15px',
            marginRight: '25px',
        },
        '& input': {
            color: '#000',
            fontWeight: 500,
            padding: '20px',
            border: '2px solid #d5d5d5',
            background: '#fff',
            borderRadius: '5px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap' as 'nowrap',
            display: 'block',
            width: '95%',
            fontSize: '16px'
        },
    },
    editquotedetailsContent: {
        color: '#000',
        fontWeight: 600,
        fontSize: '18px',
        marginTop: '50px',
        marginBottom: '20px'
    },

    edittableBodyBox: {
        position: 'relative' as 'relative',
        display: 'blocks',
        zIndex: 1,
        "& td": {
            borderRadius: '5px',
            color: '#aeaab2',
            fontWeight: 500,
            width: 'min-content',
            '& div': {
                width: '100%'
            },
            '&:first-child': {
                textAlign: 'center',
                width: '75px',
                padding: 0
            },
            '&:last-child': {
                border: 'none !important',
                textAlign: 'center',
            },
            '& input:focus-visible': {
                border: '2px solid #d5d5d5',
                outline: 'none',
            }
        },
        '& td:focus-visible': {
            border: 'none',
            outline: 'none',
        },
        '& img': {
            width: '20px',
            height: '23px',
            color: '#d5d5d5'
        }
    },

    idBox: {
        border: '2px solid #d5d5d5 !important',
        width: '5% !important',
        textAlign: 'center' as 'center',
        borderRadius: '5px'
    },

    checkBox: {
        border: 'none !important',
        width: '5% !important',
    },

    editsubTotalBox: {
        display: 'flex',
        alignItems: 'center',
        '& p:first-child': {
            color: '#000',
            margin: '0px'
        },
        '& p:last-child': {
            marginLeft: '20px',
            color: '#aeaab2',
            fontWeight: 500,
            padding: '12px 100px 12px 12px',
            border: '2px solid #d5d5d5',
            borderRadius: '5px',
            marginBottom: '0px',
            marginTop: '0px',
        }
    },
    editquoteTotalAmountBox: {
        border: '2px solid #eee',
        borderRadius: '8px',
        padding: '20px',
        width: '37%',
        margin: '0 20px 0 auto',
        '& .MuiFormControl-root': {
            width: '40%',
        },
        '& input': {
            color: '#000',
            fontWeight: 500,
            padding: '12px',
            border: '2px solid #d5d5d5',
            background: '#fff',
            borderRadius: '5px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap' as 'nowrap',
            display: 'block',
        },
    },
    editSubTotalContentBox: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '15px',
        '& p:last-child': {
            width: '40%',
            color: '#aeaab2',
            fontWeight: 500,
            padding: '12px',
            border: '2px solid #d5d5d5',
            borderRadius: '5px',
        },
    },
    edittaxContent: {
        display: 'flex',
        marginBottom: '20px',
    },
    edittaxInputBox: {
        textAlign: 'right' as 'right',
        '& .MuiFormControl-root': {
            width: '43%',
        },
        '& .MuiFormControl-root:first-child': {
            width: '20%',
            marginRight: '20px',
        },
    },

    edittotalPrice: {
        fontWeight: 600,
        color: '#0a0019',
        margin: '15px 0 5px',
    },

    edititemtableBox: {
        marginRight: '29px',
        '& thead': {
            position: 'relative',
            top: '-40px',
            display: 'table'

        },
        '& table': {
            position: 'relative',
            '& .MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover': {
                background: '#fff'
            },
            '& .MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected': {
                background: '#fff'
            },
            '& .MuiTableRow-root.MuiTableRow-hover:hover': {
                background: '#fff'
            }
        },
        '& table::after': {
            content: '""',
            border: '2px solid #e8e8e8',
            borderRadius: '10px',
            position: 'absolute',
            top: '23px',
            left: '-7px',
            width: '101%',
            height: '97%',
            zIndex: 'unset'
        },
        '& .MuiPagination-root': {
            marginTop: '5%',
            marginBottom: '30px',
            background: '#fff',
            boxShadow: '0 0 7px #e3d3d3',
            padding: '15px 20px',
            display: 'inline-block',
            marginLeft: '-3px'
        },
        '& input': {
            color: '#000',
            fontWeight: 500,
            padding: '20px',
            border: '2px solid #d5d5d5',
            background: '#fff',
            borderRadius: '5px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap' as 'nowrap',
            display: 'block',
            fontSize: '16px',
        },
    },

    edititemtableRedBox: {
        marginRight: '29px',

        '& table': {
            position: 'relative',
            '& .MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected': {
                background: '#fff'
            },
            '& .MuiTableRow-root.MuiTableRow-hover:hover': {
                background: '#fff'
            },
            '& .MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover': {
                background: '#fff'
            }
        },
        '& .MuiPagination-root': {
            boxShadow: '0 0 7px #e3d3d3',
            padding: '15px 20px',
            display: 'inline-block',
            marginTop: '5%',
            marginBottom: '30px',
            marginLeft: '-3px',
            background: '#fff'
        },
        '& thead': {
            position: 'relative',
            top: '-40px',
            display: 'table'
        },
        '& input': {
            fontSize: '16px',
            color: '#000',
            fontWeight: 500,
            padding: '20px',
            border: '2px solid #d5d5d5',
            background: '#fff',
            borderRadius: '5px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap' as 'nowrap',
            display: 'block'
        },
        '& table::after': {
            borderRadius: '10px',
            zIndex: 'unset',
            position: 'absolute',
            top: '23px',
            left: '-7px',
            width: '101%',
            height: '97%',
            content: '""',
            border: '2px solid transparent',
        }
    },

    tableBorderBox: {
        marginRight: '29px',

        '& table': {
            position: 'relative',
            '& .MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected': {
                background: '#fff'
            },
            '& .MuiTableRow-root.MuiTableRow-hover:hover': {
                background: '#fff'
            },
            '& .MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover': {
                background: '#fff'
            },
        },

        '& .MuiPagination-root': {
            marginTop: '5%',
            padding: '15px 20px',
            display: 'inline-block',
            marginBottom: '30px',
            marginLeft: '-3px',
            background: '#fff',
            boxShadow: '0 0 7px #e3d3d3'
        },
        '& div:nth-child(3) table::after': {
            zIndex: 'unset',
            position: 'absolute',
            content: '""',
            border: '2px solid #e8e8e8',
            borderRadius: '10px',
            top: '23px',
            left: '-7px',
            width: '101%',
            height: '97%',
        },
        '& thead': {
            position: 'relative',
            top: '-40px',
        },
        '& input': {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            padding: '20px',
            border: '2px solid #d5d5d5',
            background: '#fff',
            borderRadius: '5px',
            whiteSpace: 'nowrap' as 'nowrap',
            display: 'block',
            fontSize: '16px',
            color: '#000',
            fontWeight: 500,
        },
    },
    deleteButton: {
        border: '2px solid red !important',
        color: 'red !important',
        fontWeight: 600,
        marginRight: '20px',
        '&:hover': {
            background: '#fff'
        }
    },

    addSegmentBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '50px 0 30px 0',

        '& button': {
            border: '2px solid #98949f',
            borderRadius: '5px',
            padding: '10px',
            color: '#454051',
            textTransform: 'capitalize',
            fontWeight: 600,
            '&:hover': {
                background: '#fff'
            }
        },
        '& svg': {
            color: '#7b7b7d',
            marginRight: '10px',
        },

    },
    cancelSaveButton: {
        display: 'flex',
        justifyContent: 'space-between',
        '& button': {
            background: '#fff',
            color: '#000',
            padding: '14px 80px',
            fontSize: '16px',
            textTransform: 'inherit' as 'inherit',
            marginTop: '30px',
            border: '2px solid #98949f',
            "&:hover": {
                background: '#05052b',
                color: '#fff',
                border: 'none'
            },
        },
    },
    editunitContent: {
        textTransform: 'capitalize' as 'capitalize',
        width: '10% !important'
    },
    addDragSegmentBox: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '60px',
        '& p': {
            color: '#454051',
            textTransform: 'capitalize',
            fontWeight: 600,
            marginRight: '40px',
            marginTop: 0,
            marginBottom: 0,
        },
        '& input': {
            padding: '15px'
        },
        '& img': {
            width: '20px',
            height: '23px',
            marginLeft: '23px',
            color: '#d5d5d5'
        }
    },
    addNewSegmentBox: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
            color: '#454051',
            textTransform: 'capitalize',
            fontWeight: 600,
            marginRight: '40px',
            marginTop: 0,
            marginBottom: 0,
        },
        '& input': {
            padding: '15px'
        },
        '& img': {
            width: '20px',
            height: '23px',
            marginLeft: '23px',
            color: '#d5d5d5'
        }
    },

    quoteDragBox: {
        border: '2px solid #dabfa4',
        marginTop: '30px',
        padding: '20px 10px',
        borderRadius: '10px',
        boxShadow: '0 0 2px #dabfa4',
        textAlign: 'center' as 'center',
        '& p:first-child': {
            color: '#4e4e51'
        },
        '& p:last-child': {
            color: '#000',
            fontWeight: 500
        }
    },
    showTableBox: {
        visibility: 'visible' as 'visible',
        '& .MuiTableCell-root': {
            position: 'relative' as 'relative',
            zIndex: 1,
            padding: 0
        },
        '& table::after': {
            borderColor: '#e8e8e8',
        },
    },
    hideTableBox: {
        visibility: 'hidden' as 'hidden',
        position: 'relative' as 'relative',
        top: '-144px',
        zIndex: 1
    },
    editInput: {
        width: '40% !important',
        '& div': {
            width: '100%'
        },
    },
    editInputBox: {
        width: '40% !important',
        '& div': {
            width: '100%'
        },
        '& input': {
            border: '2px solid #000',
        }
    },
    editQuantityInput: {
        width: '10% !important',
    },
    editQuantityInputBox: {
        width: '10% !important',
        '& input': {
            border: '2px solid #000',
            textTransform: 'capitalize' as 'capitalize',
        }
    },
    editUnitPriceBox: {
        '& input': {
            border: '2px solid #000',
        }
    },
    errorBox: {
        color: 'red !important',
        fontSize: '14px',
        margin: '0 auto 0 17% !important'
    },
    categoryErrorBox: {
        marginBottom: '60px'
    },
    itemWiseTaxBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    editTaxContentBox: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
            margin: 0
        },
        '& .MuiSwitch-colorSecondary.Mui-checked': {
            color: '#fff',
        },
        '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
            background: '#000',
            opacity: 1
        }

    },
    editPriceContent: {
        width: '14%'
    },
    editSrNoInput: {
        width: '5%'
    },
    tableHeadBox: {
        display: 'grid',
    },
    editUnitPricewidthBox: {
        width: '14%'
    },
    invoiceTitleBox: {
        borderTopRightRadius: '12px',
        borderTopLeftRadius: '12px',
        minWidth: '115px',
        textAlign: 'center' as 'center',
        padding: '13px 0px',
        border: '0.5px solid #e4e3e3',
        backgroundColor: "white",
        '&.Mui-selected': {
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
            border: '0.5px solid white',
            background: '#ffffff',
            borderBottom: 'none'
        },
    },
    invoiceInnerBox2: {
        top: 0,
        width: '100%',
        overflow: 'visible',
        '& MuiBox-root-67': {
            padding: 0
        },
        '& span.MuiTabs-indicator': {
            display: 'none'
        },
        '& .MuiTabs-scroller.MuiTabs-fixed': {
            overflow: 'visible !important'
        },
        '& span': {
            textTransform: 'none',
        },
        '& div:nth-child(2)': {
            margin: '0 9px 0 16px'
        },
    },
    invoicecurveBox: {
        background: '#fcf7f2',
        width: '31px',
        bottom: 0,
        left: '-31px',
        height: '30px',
        position: 'absolute' as 'absolute',
    },
    invoicecurveInnerBox: {
        borderBottomRightRadius: '15px',
        borderTop: 'none',
        height: '30px',
        border: '1px solid #e0802e',
        borderLeft: 'none',
        width: '31px',
    },
    invoiceTabData: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "8ch"
    },

    invoicecurveRightBox: {
        right: '-30px',
        background: '#fcf7f2',
        position: 'absolute' as 'absolute',
        height: '33px',
        bottom: 0,
        zIndex: 99,
        width: '30px',
    },
    curveRightinvoiceInnerBox: {
        borderBottomLeftRadius: '15px',
        width: '30px',
        borderTop: 'none',
        borderRight: 'none',
        border: '1px solid #e0802e',
        height: '32px',
    },

})

export { EditInvoiceScanResult }
export default withStyles(editQuoteScanResultStyle)(EditInvoiceScanResult)
// Customizable Area End
