import React from "react";
// Customizable Area Start
import {
    Grid, Box, Typography,
    Breadcrumbs,
    Button,
  TextField, Divider, Chip, Select, OutlinedInput, MenuItem,
  Paper,ListItem,
  Modal, IconButton,
  InputAdornment,
  TextareaAutosize, 
  Checkbox,
  Dialog,
  DialogTitle,
  DialogActions,
  FormControl
} from "@material-ui/core";
import ArrowIcon from "@material-ui/icons/ArrowDropDown";
import {
upload_image,sendIcon,checkBox,download
} from "./assets";
import Header from "../../landingpage/src/Header.web";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { styled } from "@material-ui/styles";
import { configJSON } from "./CategoriessubcategoriesController";
import ViewReportDetailsController, {
    Props
} from "./ViewReportDetailsController.web";
// Customizable Area End

export default class ViewDiscrepancy extends ViewReportDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    viewReportDetailsBreadCrumb = () => {
        return (<>
            <Box >
                <Breadcrumbs separator={">"} aria-label="breadcrumb">
                    <UploadReportContainer color="inherit" data-testId="breadcrumb" onClick={this.handleProjNavigation} style={webStyle.reportUnderStyle}>{configJSON.viewBred}</UploadReportContainer>
                    <UploadReportContainer color="inherit" onClick={this.getNavigationViewreport} style={webStyle.reportUnderStyle}>{configJSON.reportOf}'{this.state.project_name}'</UploadReportContainer>
                    <UploadReportContainer color="inherit" style={webStyle.breadcrumb} onClick={this.goBack}>{this.state.reportName}</UploadReportContainer>
                    <Typography style={webStyle.breadcrumbStyle}>{configJSON.discrepancy}</Typography>
                </Breadcrumbs>
            </Box>
        </>
        )
    }
    tableList = () => {
        return (
            <Box className="invoiceData">
                <Typography className="heading">{configJSON.orderQty}</Typography>
                <Typography className="heading">{configJSON.unit}</Typography>
                <Typography className="heading">{configJSON.unitPrice}</Typography>
                <Typography className="heading">{configJSON.totalContent}</Typography>
            </Box>
        )
    }
    invoiceTable = () => {
        return (
            <>
                {this.tableList()}
                {this.state.viewListData.map((item)=> {
                    return (
                        <>
                            <Box className="quoteDetails">
                                <Typography className='invoiceItems'>{item.quote.ord_quantity}</Typography>
                                <Typography className='invoiceItems'>{item.quote.unit}</Typography>
                                <Typography className='invoiceItems'>{item.quote.unit_price}</Typography>
                                <Typography className={item.type === "overcharged" ? "invoiceItems overChangedItem" : "invoiceItems underChangedItem"}>{item.quote.ext_price}</Typography>
                            </Box>
                        </>
                    )
                })}

            </>
        )
    }
    quoteTable = () => {
        return (
            <>
               {this.tableList()}
                {this.state.viewListData.map((item)=> {
                    return (
                        <>
                            <Box className="invoiceDetails">
                                <Typography className='invoiceItems'>{item.invoice.ord_quantity}</Typography>
                                <Typography className='invoiceItems'>{item.invoice.unit}</Typography>
                                <Typography className='invoiceItems'>{item.invoice.unit_price}</Typography>
                                <Typography className={item.type === "overcharged" ? "invoiceItems overChangedItem" : "invoiceItems underChangedItem"}>{item.invoice.ext_price}</Typography>
                            </Box>
                        </>
                    )
                })}

            </>
        )
    }

  renderSendToVendor = () => {
    const selectedCount = this.state.selectedValues.length;
    return (
      <>
          <VendorModal open={this.state.openModal}>
              <Paper className="ventorContainer">
                <Box className="reasonModalHeader">
                  <Typography className="emailHeading">
                    {configJSON.emailReport}
                  </Typography>
                </Box>
                <Box style={{ padding: "0px 20px 20px 20px" }}>
                  <Box>
                    <label className="labelName">To</label>
                    <TextField
                      fullWidth
                      variant="outlined"
                      className="reportField"
                      style={webStyle.emailName}
                      data-testId="handleChangeEmail"
                      value={this.state.emailName}
                      onChange={this.handleChangeEmail}
                      placeholder={configJSON.emailPlaceholder}
                    />
                  </Box>
                  <Box>
                    <label className="labelName">{configJSON.subject}</label>
                    <TextField
                      fullWidth
                      variant="outlined"
                      style={webStyle.emailName}
                      data-testId="handleChangeSubject"
                      value={this.state.emailSubject}
                      onChange={this.handleChangeSubject}
                      placeholder={configJSON.emailSubject}
                    />
                  </Box>
                  <label className="labelName">{configJSON.message}</label>
                  <Box style={{ width: "560px" }}>
                    <TextareaAutosize
                     style={webStyle.message}
                      aria-label="minimum height"
                      minRows={4}
                      data-testId="handleChangeMessage"
                      value={this.state.emailMessage}
                      onChange={this.handleChangeMessage}
                      placeholder={configJSON.emailMessage}
                    />
                  </Box>
                  <label className="labelName">{configJSON.discreHeading}</label>
                  <Box>
                    <TextField
                      fullWidth
                      variant="outlined"
                      disabled
                      value={
                        selectedCount > 0 ? `${selectedCount} Selections` : ""
                      }
                      style={webStyle.checkStyle}
                      data-testId="handleMenuClick1"
                      onClick={this.handleMenuClick}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton>
                              {" "}
                              <ArrowIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {this.state.isOptionMenu && 
                    <Paper className="menuContainer">
                      <Box className="optionsList" data-testId="handleMenuClick" onClick={() => this.handleSelect("Option 1")}>
                        <Checkbox className="selectedCheck" icon={<img src={checkBox} />} checked={this.state.selectedValues.includes(
                            "Option 1"
                          )}/>
                        <Typography>{configJSON.overchargeRep}</Typography>
                      </Box>
                      <Box className="optionsSubList" onClick={() => this.handleSelect("Option 2")}>
                      <Checkbox className="selectedCheck"  icon={<img src={checkBox} />} checked={this.state.selectedValues.includes(
                            "Option 2"
                          )}/>
                        <Typography >{configJSON.includeUndercharge}</Typography>
                      </Box>
                      <Box onClick={() => this.handleSelect("Option 3")} className="optionsList">
                        <Checkbox className="selectedCheck"  icon={<img src={checkBox} />} checked={this.state.selectedValues.includes(
                            "Option 3"
                          )}/>
                        <Typography>{configJSON.quoteHighlight}</Typography>
                      </Box>
                      <Box onClick={() => this.handleSelect("Option 4")} className="optionsList">
                        <Checkbox className="selectedCheck"  icon={<img src={checkBox} />} checked={this.state.selectedValues.includes(
                            "Option 4"
                          )}/>
                        <Typography>{configJSON.invoiceHighlight}</Typography>
                      </Box>
                    </Paper>}
                  </Box>
                </Box>
                <Box style={{ textAlign: "center", marginBottom: "30px" }}>
                  <Button data-testId="emailClose" className="cancelBtn" onClick={this.handleModalClose}>
                    {configJSON.modelCancleButton}
                  </Button>
                  <Button className="sendBtn">{configJSON.send}</Button>
                </Box>
              </Paper>
          </VendorModal>
      </>
    )
  }
  renderDownloadSummaryModal = () => {
    return (
      <>
        <Box>
          <Dialogs
            open={this.state.dowmloadModal}
            data-testId="handleCloseDownloadModal"
            onClose={this.handleCloseDownloadModal}
          >
            <Box className="flexBody">
              <DialogTitle className="quote">
               {configJSON.downloadpage}
              </DialogTitle>
            </Box>
            <FormControl fullWidth>
              <Box>
                <Box className="report">{configJSON.discreHeading}</Box>
              </Box>
              <Box className="border">
                <Select
                  className="select"
                  placeholder="Select"
                  fullWidth
                  IconComponent={ExpandIcon}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "center"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "center"
                    },
                    PaperProps: {
                      style: {
                        boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
                        marginTop: "1rem",
                      },
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  <MenuListItems value={"Overcharge only report"}>
                    {configJSON.overcharge}
                  </MenuListItems>
                  <MenuListItems value={"Overcharge and undercharge report"}>
                    {configJSON.undercharge}
                  </MenuListItems>
                </Select>
              </Box>
            </FormControl>
            <DialogActions>
              <Button
                color="primary"
                className="cancelBtn"
                data-testId="handleCloseDownloadModal"

                onClick={this.handleCloseDownloadModal}
              >
                {configJSON.modelCancleButton}
              </Button>
              <Button
                color="primary"
                onClick={this.handlOpenPdf}
                className="downloadBtn"
                data-testId="handlOpenPdf"
              >
                {configJSON.pdfGenerate}
              </Button>
            </DialogActions>
          </Dialogs>
        </Box>
      </>
    );
  };

  renderGeneratePdf = () => {
    return (
      <>
        <Box >
          <Modal open={this.state.showPdf} onClose={this.handleClosePdf}>
            <>
              <Box style={webStyle.pdfContainer}>
                <Typography style={webStyle.pdfWrapper}>{configJSON.pdfName} </Typography>
                <Box>
                  <img src={download} style={webStyle.downloadPdf} />
                </Box>
              </Box>
              <Box>
                <Box style={webStyle.showPdf}>
                  <Paper className="pdfContainer" style={webStyle.pdfStyle}>
                    <Box style={webStyle.iframeStyle}>
                      <iframe style={webStyle.pdfViewer}
                        src={configJSON.pdfUrl}
                        className="pdfViewer"
                      />
                    </Box>
                  </Paper>
                </Box>
              </Box>
            </>
          </Modal>
        </Box>
      </>
    );
  };
    // Customizable Area End
    render() {
        // Customizable Area Start 
        const { navigation, handleSaveData } = this.props

        const { displayText, backgroundColor } = this.getDiscrepancyInfo();
        return (
            <>
                <Box>
                    <Header
                        navigation={navigation}
                        handleSaveData={handleSaveData}
                        history={this.props.history}
                    />
                    <Grid
                        container
                        style={webStyle.fontColor}
                    >
                        <Grid item xs={12} md={6} lg={6}>
                        {this.viewReportDetailsBreadCrumb()}
                        </Grid>
                    </Grid>
                    <InvoiceDetails>
                <Box className='discrepancyDetails'>
                    <Typography className="summaryHeaing">{configJSON.discrepancy}</Typography>
                    <Typography className='details'>{configJSON.viewHeading}</Typography>
                    <Grid container spacing={3}>
                        <Grid item lg={4} md={6} sm={6}>
                            <Box>
                                <label className='labelName'>{configJSON.modelProject}</label>
                                <TextField
                                    variant='outlined'
                                    fullWidth
                                    className="inputField"
                                    value={this.state.dummyData.project}
                                    InputProps={{ readOnly: true }}
                                />
                            </Box>
                        </Grid>
                        <Grid item lg={4} md={6} sm={6}>
                            <Box>
                                <label className='labelName'>{configJSON.modelReport}</label>
                                <TextField
                                    variant='outlined'
                                    fullWidth
                                    className="inputField"
                                    value={this.state.dummyData.report}
                                    InputProps={{ readOnly: true }}
                                />
                            </Box>
                        </Grid>
                        <Grid item lg={4} md={6} sm={6}>
                            <Box>
                                <label className='labelName'>{configJSON.vendorTitle}</label>
                                <TextField
                                    variant='outlined'
                                    fullWidth
                                    className="inputField"
                                    value={this.state.dummyData.vendor}
                                    InputProps={{ readOnly: true }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item lg={4} md={6}>
                            <Box className="discrepancySection">
                                <label className='labelName'>{configJSON.totalDiscrepancy}</label>
                                <TextField
                                    variant='outlined'
                                    fullWidth
                                    className="inputField"
                                    value={displayText}
                                    InputProps={{ readOnly: true }}
                                    style={{ backgroundColor }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                        <Box style={{margin: "40px"}} >
                            <Box className="quoteWrapper">
                                <Typography className="itemDetails">{configJSON.itemdetailsContent}</Typography>
                                <Box className="tableWrapper">
                                    <Typography className="discreHeading">{configJSON.discreHeading}</Typography>
                                    <Select
                                        className="selectWrapper"
                                        labelId="demo-simple-select-disabled-label"
                                        id="demo-simple-select-disabled"
                                        input={<OutlinedInput />}
                                        displayEmpty
                                        fullWidth
                                        placeholder="Please select vendor"
                                        value={this.state.selectedValue}
                                        onChange={this.handleChange} 
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }}
                                        IconComponent={ExpandIcon}
                                        data-testId={"projectTestID"}
                                    >
                                        <MenuItem value="both">Both</MenuItem>
                                        <MenuItem value="overcharged">{configJSON.overcharged}</MenuItem>
                                    </Select>
                                </Box>
                            </Box>
                            <Box className="mainContainer">
                                <Box className="nameHeading">
                                    <Box className="nameItemHeading">
                                        <Typography className="itemsHeading">{configJSON.itemsHeading}</Typography>
                                    </Box>
                                    {this.state.viewListData.map((item) => {
                                        return (
                                            <>
                                                <Box className="itemNameHead">
                                                    <Typography className="name">{item?.quote.product}</Typography>
                                                </Box>
                                            </>
                                        )
                                    })}

                                </Box>
                                <Box>
                                    <Typography className="quoteHeading">{configJSON.quoteText}</Typography>
                                    {this.invoiceTable()}
                                </Box>
                                <Box>
                                    <Box>
                                        <Typography className="quoteHeading">{configJSON.invoiceText}</Typography>
                                        {this.quoteTable()}
                                    </Box>
                                </Box>

                                <Box className="nameHeading">
                                    <Box className="discrepancyHeading">
                                        <Typography className="heading">{configJSON.discrepency}</Typography>
                                    </Box>
                                    {this.state.viewListData.map((item) => {
                                        return (
                                            <>
                                                <Box className="itemName">
                                                    <Box className="discrepencyDetails">
                                                        <Chip label={item.type} className={item.type === "overcharged" ? "overChanged" : "underChanged"} />
                                                        <Typography className='invoicePrice'>${item.total}</Typography>
                                                    </Box>
                                                </Box>
                                            </>
                                        )
                                    })}
                                </Box>

                </Box>
                <Box className="btnSection">
                    <Button variant="contained" className="downloadBtn" startIcon={<img src={upload_image} />}   data-testId="handleOpenDownloadModal" onClick={this.handleOpenDownloadModal}>
                        {configJSON.downloadSummary}
                    </Button> 
                    <Button data-testId="sendEmail" variant="contained" className="vendorButton" startIcon={<img src={sendIcon} />} onClick={this.handleModalOpen} >
                        {configJSON.sendVendor}
                    </Button>
                </Box>
                </Box>
                {this.renderSendToVendor()}
                {this.renderDownloadSummaryModal()}
                {this.renderGeneratePdf()}
            </InvoiceDetails>
                </Box>
            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
const ExpandIcon = styled(ExpandMoreIcon)({
    marginRight: "15px",
    color: "#05052B"
});

const InvoiceDetails = styled(Box)({
    background: "#F6F7FA",
    "& .mainContainer": {
        display: "grid",
        gridTemplateColumns: "1fr 2fr 2fr 1fr",
        gap: "10px",
        marginTop: "20px",
        overflowX: "auto" as "auto",
        width: "100%"
    },
    "& .itemHeading": {
        backgroundColor: '#FFF',
        fontWeight: 'bold',
        marginBottom: "10px",
        padding: "19px",
        paddingBottom: "10px"
    },
    "& .discrepancyHeading": {
        backgroundColor: '#FFF',
        fontWeight: 'bold',
        marginBottom: "10px",
        padding: "19px",
        paddingBottom: "10px",
        height: "70px",
        borderRadius: "4px"
    },
    "& .nameItemHeading": {
        backgroundColor: '#FFF',
        fontWeight: 'bold',
        marginBottom: "10px",
        padding: "19px",
        paddingBottom: "10px",
        height: "70px",
        borderRadius: "4px"
    },
    "& .itemName": {
        backgroundColor: '#FFF'
    },
    "& .itemNameHead": {
        backgroundColor: '#FFF',
        cursor: 'pointer',
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        borderBottom: "1px solid rgba(0, 0, 0, 0.05)"
    },
    "& .name": {
        padding: "15px",
        fontWeight: 400,
        fontFamily: "Poppins",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#05052B",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "180px"
    },
    "& .invoiceData": {
        display: "grid",
        gridTemplateColumns: "repeat(4, 2fr)",
        gap: "10px",
        backgroundColor: '#FFF',
        padding: "15px",
        marginBottom: "10px",
        textAlign: "center"
    },
    "& .inputField": {
        marginTop: "10px",
        "& .MuiOutlinedInput-input": {
            color: "black"
        },
        '& .MuiOutlinedInput-root': {
          pointerEvents: 'none'
        },
    },
    "& .nameHeading":{
        marginTop: "37px"
    },
    "& .invoiceDetails": {
        display: "grid",
        gridTemplateColumns: "repeat(4, 2fr)",
        backgroundColor: '#FFF',
        padding: "15px",
        fontWeight: 400,
        fontFamily: "Poppins",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#05052B",
        borderBottom: "1px solid rgba(0, 0, 0, 0.05)"
    },
    "& .quoteDetails": {
      display: "grid",
      gridTemplateColumns: "repeat(4, 2fr)",
      backgroundColor: '#FFF',
      padding: "15px",
      fontWeight: 400,
      fontFamily: "Poppins",
      fontSize: "14px",
      lineHeight: "21px",
      color: "#05052B",
      borderBottom: "1px solid rgba(0, 0, 0, 0.05)"
  },
    "& .invoiceItems": {
        fontWeight: 400,
        fontFamily: "Poppins",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#05052B"
    },
    "& .successAlert": {
      fontWeight: 500,
      fontSize: "16px",
      fontFamily: "Poppins",
      lineHeight: "24px",
      color: "#0F172A",
      background: "white"
    },
    "& .invoicePrice": {
        fontWeight: 400,
        fontFamily: "Poppins",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#05052B",
        paddingRight: "20px"
    },
    "& .discrepencyDetails": {
        display: "flex",
        gap: "10px",
        alignItems: "center",
        padding: "10.6px",
        borderBottom: "1px solid rgba(0, 0, 0, 0.05)"
    },
    "& .overChanged": {
        background: '#FF6666',
        color: 'white',
        width: "127px",
        height: "30px"
    },
    "& .underChanged": {
        background: '#FFD966',
        color: 'white',
        width: "127px",
        height: "30px"
    },
    "& .heading": {
        fontWeight: 500,
        fontFamily: "Poppins",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#05052B",
        width: "70px"
    },
    "& .itemsHeading": {
        fontWeight: 500,
        fontFamily: "Poppins",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#05052B",
        width: "100px"
    },
    "& .details": {
        fontWeight: 500,
        fontFamily: "Poppins",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#05052B",
        marginBottom: '15px'
    },
    "& .summaryHeaing": {
        fontWeight: 500,
        fontFamily: "Poppins",
        fontSize: "18px",
        lineHeight: "27px",
        color: "#05052B",
        padding: "25px 0 25px 0"
    },
    "& .discrepancyDetails": {
        background: "white",
        padding: "30px",
        borderRadius: "20px",
        marginTop: "20px"
    },
    "& .labelName": {
        fontWeight: 400,
        fontFamily: "Poppins",
        fontSize: "14px",
        lineHeight: "16px",
        color: "#05052B",
        marginBottom: "5px"
    },
    "& .discrepancySection":{
        marginTop: "20px"
    },
    "& .itemDetails": {
        fontWeight: 500,
        fontFamily: "Poppins",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#05052B",
        marginTop: "50px"
    },
    "& .vendorButton": {
        background: "black",
        color: "white",
        fontWeight: 400,
        fontFamily: "Poppins",
        fontSize: "14px",
        lineHeight: "21px",
        textTransform: "capitalize"
    },
    "& .downloadBtn": {
        background: "black",
        color: "white",
        fontWeight: 400,
        fontFamily: "Poppins",
        fontSize: "14px",
        lineHeight: "21px",
        textTransform: "capitalize",
        marginRight: '10px'
    },
    "& .btnSection": {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: '20px'
    },
    "& .quoteHeading": {
        fontWeight: 600,
        fontFamily: "Poppins",
        fontSize: "14px",
        lineHeight: "21px",
        textAlign: "center",
        marginBottom: "15px",
        color: "#05052B",
        borderRadius:"4px"
    },
    "& .quoteWrapper": {
        width: "100%", 
        display: "flex", 
        justifyContent: "space-between", 
        alignItems: "center"
    },
    "& .selectWrapper": {
        background: "white", 
        borderRadius: "15px", 
        marginTop: "10px"
    },
    "& .tableWrapper":{
        width: "30%",  
        marginTop: "20px",
        borderRadius: "10px"
    },
    "& .discreHeading":{
        marginRight: '10px' 
    },
    "& .overChangedItem": {
        color: "#FF6666"
     },
     "& .underChangedItem": {
        color: "#FFD966"
     },
})

const UploadReportContainer = styled(Box)({
    cursor: "pointer",
    "&:hover": {
        color: "#1d6dcb",
        textDecoration: "underline"
    }
});

const webStyle = {
    breadcrumbStyle: {
        color: "orange"
    },
    fontColor: {
        color: 'black',
        alignItems: "center"
    },
    breadcrumb: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "150px",
        fontWeight: 500,
        textDecoration: "underline",
        color: "#87888F"
    } as React.CSSProperties,
    reportUnderStyle: {
      fontWeight: 500,
      textDecoration: "underline",
      color: "#87888F"
  },
    emailName:{
        marginTop: "10px",
        marginBottom: "15px",
        fontWeight: 400,
        fontFamily: "Poppins",
        fontSize: "16px",
        lineHeight: "24px"
    },
    message:{
        width: "100%",
        fontWeight: 400,
        fontFamily: "Poppins",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#05052B",
        height: "80px",
        overflow: "auto",
        borderColor: "#BEBEBE",
        padding: "10px",
        marginTop: "10px",
        marginBottom: "20px",
        resize:'none' as 'none'
    },
    checkStyle:{
        fontWeight: 400,
        fontFamily: "Poppins",
        fontSize: "16px",
        marginTop: "10px",
        lineHeight: "24px"
    },
  pdfContainer: {
    background: '#05052B',
    padding: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
    backdropFilter: "blur(7px)"
  },
  pdfWrapper: {
    color: "white",
    fontWeight: 500,
    fontFamily: "Poppins",
    fontSize: "12px",
    marginLeft: "15px",
    lineHeight: "14px"
  },
  showPdf: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backdropFilter: "blur(7px)",
    overflowY: "auto",
    width: "100%",
    padding: "20px 0px 0px 20px"
  } as React.CSSProperties,

  pdfStyle: {
    maxWidth: "600px",
    width: "100%",
    height: "600px"
  },
  pdfViewer: {
    width: "600px",
    height: "600px",
    display: "block",
    marginBottom: "20px"
  },
  downloadPdf: {
    marginRight: "15px"
  },
  iframeStyle: {
    height: "600px"
  }
};


  const VendorModal = styled(Modal)({
    display: "flex",
    justifyContent: "center",
    alignItems:"center",
    overflowY: "scroll",
    backdropFilter: "blur(7px)",
    padding: "20px 0px 0px 20px",
    "& .ventorContainer": {
      display: "flex",
      flexDirection: "column",
      borderRadius: "10px",
      width: "100%",
      maxWidth: "600px",
      maxHeight:"621px",
      "& .MuiPopover-paper": {
        outline: "1px solid red"
      },
    },
    "& .reasonModalHeader": {
      width: "85%"
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#DF802D"
    },
    "& .MuiIconButton-colorSecondary": {
      color: "#DF802D"
    },
    "& .emailHeading": {
      fontWeight: 500,
      fontSize: 18,
      lineHeight: "27px",
      color: "#05052B",
      padding: 20
    },
    "& .labelName": {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: "16px",
      color: "#05052B"
    },
    "& .cancelBtn": {
      border: "1px solid #05052B",
      width: "125px",
      height: "42px",
      marginRight: "20px",
      textTransform: "capitalize"
    },
    "& .sendBtn": {
      background: "#05052B",
      width: "125px",
      color: "white",
      height: "42px",
      marginRight: "20px",
      textTransform: "capitalize"
    },
    "& .menuContainer":{
        borderRadius: "15px", 
        padding: "5px 15px 5px 5px", 
        width: "556px", 
        overflow: "auto", 
        position: "absolute", 
        zIndex: 1,
      },
      "& .optionsList":{
        display: "flex", 
        alignItems: "center"
      },
      "& .optionsSubList":{
        display: "flex", 
        alignItems: "center",
        paddingLeft: "30px"
      },
  });
  const ListItemSection = styled(ListItem)({
    fontWeight: 400,
    color: "#424C53",
    fontSize: "18px",
    fontFamily: "Poppins",
    width: "100%",
    "&.isSelected": {
      color: "#4FC3FD"
    },
    "&:hover": {
      backgroundColor: "transparent"
    },
    "@media(max-width:992px)": {
      fontWeight: 500,
      marginLeft: "10px"
    },
  });
  
  const ListItemSubsection = styled(ListItem)({
    marginLeft: "40px",
    color: "#424C53",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Poppins",
    "&:hover": {
      backgroundColor: "transparent",
    },
    "@media(max-width:992px)": {
      marginLeft: "10px"
    },
  });
  
  const Dialogs = styled(Dialog)({
    backdropFilter: "blur(7px)",
    "& .MuiDialog-paper": {
      maxWidth: "550px",
      width: "100%",
      color: "#fff",
      backgroundColor: "#fff",
      padding: "40px",
      scrollBehavior: "auto",
      borderRadius: "10px",
      height: "295px"
    },
    "& .flexBody": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    "& .quote": {
      color: "#05062a",
      padding: "0px",
      "& .MuiTypography-h6": {
        fontSize: "18px",
        fontWeight: 500,
        fontFamily: "Poppins",
        lineHeight: "27px"
      },
    },
    "& .report": {
      color: "#05062a",
      paddingTop: "20px",
      fontSize: "14px",
      fontWeight: 500,
      fontFamily: "Poppins",
      lineHeight: "27px"
    },
    "& .select": {
      width: "100%",
      marginTop: "0.5rem"
    },
    "& .MuiSelect-nativeInput": {
      width: "90%",
      color: "#05062a",
      opacity: "inherit",
      border: "none",
      bottom: "auto",
      paddingLeft: "15px",
      fontSize: "17px",
      fontWeight: 500,
      fontFamily: "Poppins",
      "@media (max-width: 600px)": {
        width: "75%"
      },
    },
    "& .MuiInput-underline": {
      borderBottom: "none",
      "&:hover:not(.Mui-disabled):before": {
        transform: "none",
        borderBottom: "none"
      },
      "&:before": {
        content: "none",
        borderBottom: "none",
        "&:hover": {
          borderBottom: "none"
        },
      },
      "&:after": {
        transform: "none",
        borderBottom: "none",
        "&:hover": {
          borderBottom: "none"
        },
      },
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent"
    },
    "& .MuiSvgIcon-root": {
      width: "2em",
      color: "#8c8484"
    },
    "& .MuiMenu-paper": {
      top: "236px !important"
    },
    "& .border": {
      height: "60px",
      border: "1.5px solid #d4c7c7",
      borderRadius: "5px",
      display: "flex"
    },
    "& .MuiDialogActions-spacing": {
      padding: "0px",
      paddingTop: "30px",
      "@media (max-width: 600px)": {
        flexDirection: "column"
      },
    },
    "& .cancelBtn": {
      width: "48%",
      border: "1.5px solid #05062a",
      borderRadius: "5px",
      color: "#05062a",
      fontSize: "16px",
      fontWeight: 500,
      fontFamily: "Poppins",
      textTransform: "capitalize",
      "@media (max-width: 600px)": {
        width: "100%",
      },
    },
    "& .downloadBtn": {
      width: "50%",
      border: "1px solid navy",
      borderRadius: "5px",
      backgroundColor: "#05062a",
      color: "white",
      fontSize: "16px",
      fontWeight: 500,
      fontFamily: "Poppins",
      textTransform: "capitalize",
      "@media (max-width: 600px)": {
        width: "100%",
        marginLeft: "0px",
        marginTop: "5px"
      },
    },
    "& .MuiMenu-list ": {
      backgroundColor: "red !important"
    },
  });
  const MenuListItems = styled(MenuItem)({
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Poppins",
    lineHeight: "27px",
    width: "100%",
    minWidth: "460px",
    "&:hover": {
      backgroundColor: "#dd7f38",
      color: "white"
    },
    "& .MuiPaper-root MuiMenu-paper MuiPopover-paper MuiPaper-elevation8 MuiPaper-rounded": {
      top: "550px"
    }
  })

// Customizable Area End