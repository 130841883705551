import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  AppBar,
  Toolbar,
  styled
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import MoreIcon from "@material-ui/icons/MoreVert";
import NewHeader from "./NewHeader.web";
import { bannerConstructionImg } from "./assets";
import { TermsAndCond } from "./TermsandconditionsController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const BannerImgBox = styled(Box)({
  width: '100%',
  margin: 'auto',
  maxWidth: 1290,
  display:"flex",
  alignItems: 'center',
  marginTop:"40px",
  backgroundImage: `url(${bannerConstructionImg})`,
  backgroundRepeat: 'no-repeat',
  padding: '100px 20px 100px 65px',
  borderRadius: 12,
  backgroundSize: 'cover',
  '@media(max-width: 1300px)': {
    width: '90%',
  },
  '@media(max-width: 960px)': {
    padding: '20px',
  },

  '& .inner-box': {
    maxWidth: 853,
  }
})


const BannerHeadingText = styled(Typography)({
fontFamily:"Poppins",
fontWeight:600,
fontSize:"24px",
lineHeight:"36px",
color:"#FFFFFF",
})

const BannerDescriptionText = styled(Typography)({
  fontFamily:"Poppins",
  fontWeight:400,
  fontSize:"18px",
  lineHeight:"32px",
  color:"#FFFFFF",
  letterSpacing:"1px",
  marginTop:"14px",
})

const TermsBox = styled(Box)({
  display:"flex",
  justifyContent:"center",
  marginBottom:"30px",
  marginTop:"20px"
})

const MainTermsBox = styled(Box)({
  display:"flex",
  flexDirection:"column",
  alignItems:"center",
  maxWidth:1290,
  width:"100%"
})

const HeadingAndDescriptionBox = styled(Box)({
marginTop:"20px",
display:"flex",
flexDirection:"column",
width:"82%"
})

const TermsHeadingText = styled(Typography)({
color:"#05052B",
fontFamily:"Poppins",
fontWeight:600,
fontSize:"16px",
lineHeight:"24px",
textAlign:"initial"
})

const TermsDescriptionText = styled(Typography)({
  color:"#05052B",
  fontFamily:"Poppins",
  fontWeight:400,
  fontSize:"16px",
  lineHeight:"24px",
  textAlign:"initial"
  })

  const ButtonBox = styled(Box)({
    display:"flex",
    justifyContent:"center",
    marginBottom:"40px !important"
  })

const GoBackBtn = styled(Button)({
  minWidth:"213px",
  border:"1px solid #05052B",
  color:"#05052B",
  textTransform:"none",
  cursor:"pointer",
  padding:"13px",
  marginBottom:"40px !important",
  marginTop:"40px !important"
})


// Customizable Area End

import TermsandconditionsController, {
  Props,
  configJSON,
} from "./TermsandconditionsController";

export default class Termsandconditions extends TermsandconditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
       <NewHeader navigation={this.props.navigation} id={""}/>
       <BannerImgBox>
       <Box className="inner-box">
            <BannerHeadingText>Our terms of services</BannerHeadingText>
            <BannerDescriptionText>
            Explore our commitment to transparency, fairness, and quality in our services. Please read the following terms carefully before engaging with My Invoice Check. By using our services, you agree to abide by the terms outlined herein.
            </BannerDescriptionText>
       </Box>
       </BannerImgBox>
       <TermsBox>
       <MainTermsBox>
      {this.state.termsAndConditions.sort((a:any,b:any)=>a.id-b.id).map((terms:TermsAndCond)=>{
        return <HeadingAndDescriptionBox key={`terms__and_cond_${terms.id}`}>
          <TermsHeadingText>
           {terms.id + "."} {terms.title}
          </TermsHeadingText>
          <TermsDescriptionText>
            {terms.description}
          </TermsDescriptionText>
        </HeadingAndDescriptionBox>
      })}
       </MainTermsBox>
       </TermsBox>
       <ButtonBox>
        <GoBackBtn onClick={()=>this.props.navigation.goBack()}>Go back</GoBackBtn>
       </ButtonBox>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
